import "@aws-amplify/ui-react/styles.css";
import "./loginMethods.module.scss";
import styles from './loginMethods.module.scss';
import { 
  useAuthenticator, 
} from "@aws-amplify/ui-react";
import { Amplify, Auth } from "aws-amplify";
import awsExports from "../../aws-exports";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { I18n } from "aws-amplify";
import dict from "../../i18n.js";
import Button from "components/Button/button";
import MainStyle from "components/UI/main.module.scss";
import { signInWithAzure } from "Services/authenticationServices";

// Set language and vocabularies
I18n.putVocabularies(dict);
I18n.setLanguage("pt-br");

Amplify.configure(awsExports);

function LoginMethods() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate(); 
  let from = location.state?.from?.pathname || "/dashboard";

  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, from, navigate]);



  // Function to handle basic login navigation
  const handleBasicLogin = () => {
    navigate('/login/basic');
  };

  // Azure SSO Sign-In through federatedSignIn standard method (using aws-exports.js)
  const signInWithAzureStandardMethod = async () => {
    try {
      await Auth.federatedSignIn({ provider: "AzureAD" });
    } catch (error) {
      console.error("Error signing in with Azure: ", error);
    }
  };
 
  
  return ( 
    <div className={styles.buttonWrapper}>
       <h3 className={styles.title}>Métodos de autenticação</h3>
      <input
        type="button"
        className={styles.azuressobutton}
        value="&nbsp;&nbsp;&nbsp;Entrar com sua conta Microsoft"
        onClick={signInWithAzure}
        style={{
          backgroundImage: "url('https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg')",
          backgroundSize: '8%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '5% center',
          paddingLeft: '40px'
        }}
      />
      <Button className={styles.basicLogin + " " + "primaryOrange"} onClick={handleBasicLogin}>Usuário e senha</Button>
    </div> 
  );
  
}

export default LoginMethods; 
