import { verifySession } from "Services/authenticationServices";
import { formatDate } from "Services/globalServices";

const stage = process.env.REACT_APP_STAGE;
const baseReportURL =
  `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/orders/submissions/archive`;

export async function getAllArchivedReports(setList, setMemList, setLoading) {
  const reports = await getArchivedReports();
  setList(reports);
  setMemList(reports);
  setLoading(false);
}

export async function getArchivedReports() {
  let jwtToken = await verifySession();
  let reportsData;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };

  const formatStatus = (status) => {
    if (status == "closed") {
      return "encerrada";
    } else if (status == "ready") {
      return "pronta";
    }
  };

  await fetch(baseReportURL, parameters)
    .then((response) => {
      return response.json();
    })
    .then((body) => {
      if (body.status.statusCode == 0) {
        if (body.data.length > 0) {
          reportsData = body.data;
          reportsData.map((report, index) => {
            reportsData[index].initialDate = formatDate(
              report.meta.opening,
              "DD/MM/YYYY"
            );
            reportsData[index].finalDate = formatDate(
              report.meta.closing,
              "DD/MM/YYYY"
            );
            reportsData[index].statusLabel = formatStatus(report.itemStatus);
          });
          console.log(body);
        }
      }
    });

  return reportsData;
}
