import Alert from "components/Alert/Alert";
import Button from "components/Button/button";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import Layout from "components/Layout/layout";
import PageTitle from "components/PageTitle/pageTitle";
import Pagination from "components/Pagination/pagination";
import TableControl from "components/TableControl/tableControl";
import {
  Archive,
  ArrowsDownUp,
  DotsThree,
  FastForward,
  Pencil,
  PlusCircle,
  X,
} from "phosphor-react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import ReactLoading from "react-loading";
import Loading from "components/Loading/loading";
import { useNavigate } from "react-router-dom";
import { addNewAlert } from "Services/alertsManagement";

import {
  archiveAssessmentById,
  getAllAssessmentByStatus,
  updateAssessmentStatusById,
} from "../assessmentListsServices";
import styles from "./preparationAssessmentList.module.scss";
import MainStyle from 'components/UI/main.module.scss';

const PreparationAssessmentList = () => {
  const navigate = useNavigate();
  const [memResultList, setMemResultList] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tablePagination, setTablePagination] = useState([]);
  const [slice, setSlice] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showMoreActions, setShowMoreActions] = useState({});
  const [alerts, setAlerts] = useState([]);
  const [itemIdToEdit, setItemIdToEdit] = useState("");
  const [listIsSorted, setListIsSorted] = useState(false);
  const [statusInteractionLoading, setStatusInteractionLoading] = useState(false);

  const [advanceReadyConfirmationModal, setAdvanceReadyConfirmationModal] = useState(false);

  const [ascendingOrDescending, setAscendingOrDescending] = useState(null);
  const [ascendingOrDescendingTitle, setAscendingOrDescendingTitle] = useState(null);

  const { user } = useAuthenticator((context) => [context.user]);

  const capabilities = localStorage.getItem("capabilities#" + user.username);

  if(capabilities.includes("avaliações")) {
    console.log("Tem permissão")
  }else{
    console.log("Não tem permissão")
    console.log(capabilities.includes("avaliações"))
  }

  useEffect(() => {
     
    if(capabilities.includes("avaliações")) {
    if (resultList.length === 0) {
      getAllAssessmentByStatus(
        "preparation",
        setResultList,
        setMemResultList,
        setLoading
      );
    }
    }else{
      setLoading(false);
    }
  }, []);

  const advanceReadyAssessment = () => {
    let _showMoreActions = { ...showMoreActions };
    _showMoreActions = {};
    setShowMoreActions(_showMoreActions);
    setLoading(true);

    let body = {
      way: true,
    };

    updateAssessmentStatusById(itemIdToEdit.id, body).then((response) => {
      if (response.status === 200) {
        setAdvanceReadyConfirmationModal(false);
        let message = "Avaliação avançada com sucesso!";
        let updateAlertList = addNewAlert([], { message, type: "success" });
        setAlerts(updateAlertList);
        getAllAssessmentByStatus(
          "preparation",
          setResultList,
          setMemResultList,
          setLoading
        );
        setLoading(false);
      } else {
        setAdvanceReadyConfirmationModal(false);
        let message = response.body.message;
        let updateAlertList = addNewAlert([], { message, type: "danger" });
        setAlerts(updateAlertList);
        setLoading(false);
      }
    });
  };

  const sortObjByKey = (key, order = null) => {
    let _resultList = [...resultList];

    function compare(a, b) {
      function parseDate(dateString) {
        const [day, month, year] = dateString.split('/').map(Number);
        return new Date(year, month - 1, day);
      }

      function getDateValue(obj, key) {
        return key.includes('.')
          ? key.split('.').reduce((acc, cur) => acc && acc[cur], obj)
          : obj[key];
      }

      const dateA = getDateValue(a, key);
      const dateB = getDateValue(b, key);
      const isDateKey = key === 'initialDate' || key === 'finalDate';

      if (isDateKey) {
        const parsedDateA = parseDate(dateA);
        const parsedDateB = parseDate(dateB);

        if (order === 'ascending') return parsedDateA - parsedDateB;
        else if (order === 'descending') return parsedDateB - parsedDateA;
        else return parsedDateA - parsedDateB;
      } else {
        const type =
          typeof dateA === 'string' || typeof dateB === 'string' ? 'string' : 'number';
        let _resultList;

        if (type === 'string' && ascendingOrDescendingTitle === 'A') {
          setAscendingOrDescendingTitle('B');
          _resultList = dateA.localeCompare(dateB);
        } else {
          setAscendingOrDescendingTitle('A');
          _resultList = dateB.localeCompare(dateA);
        }
        return _resultList;
      }
    }

    if (memResultList.length === 0 && listIsSorted === false) {
      setMemResultList(resultList);
      _resultList = _resultList.sort(compare);
      setListIsSorted(true);
    } else {
      if (listIsSorted === false && memResultList.length > 0) {
        _resultList = _resultList.sort(compare);
        setListIsSorted(true);
      } else {
        _resultList = _resultList.sort(compare);
        setListIsSorted(false);
      }
    }

    setResultList(_resultList);
    return _resultList;
  };

  const renderContent = () => {
    switch (loading) {
      case true:
        return (
          <div className={styles.loading}>
            <div className="large">Carregando informações</div>
            <center>
              <ReactLoading
                type="bubbles"
                color="#0064a2"
                height={50}
                width={175}
              />
            </center>
          </div>
          // <Loading />
        );

        case false:

          if(capabilities.includes("avaliações") == false) {
            return (
              <div className={styles.error}>
                <center>
                  <Alert
                    type={"danger"}
                    message={
                      "Você não possui permissão para acessar essa página. Por favor, entre em contato com o administrador do sistema."
                    }
                  />
                </center>
              </div>
            );
            }
          else{
            return (
              <div>
            <TableControl
              resultList={resultList}
              setResultList={setResultList}
              originalListTable={memResultList}
              setTablePagination={setTablePagination}
              setSlice={setSlice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              showByPageDefault={10}
              searchPlaceholder={"Buscar Item"}
              hideBulkActions={true}
              hideFilter={true}
            />
            <Pagination
              tablePagination={tablePagination}
              slice={slice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            {alerts.map((i, k) => (
              <Alert key={k} message={i.message} type={i.type} />
            ))}
            <table className={styles.table}>
              <thead>
                <tr style={{ backgroundColor: "white" }}>
                  <th
                    style={{
                      width: "40vw",
                      textAlign: "left",
                      paddingLeft: "30px",
                    }}
                  >
                    Título
                    <ArrowsDownUp
                      onClick={() => {
                        sortObjByKey("title");
                      }}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "20%" }}>
                    Data da avaliação
                    <ArrowsDownUp
                      onClick={() => {
                        if (ascendingOrDescending === 'ascending') setAscendingOrDescending('descending');
                        else setAscendingOrDescending('ascending');
                        sortObjByKey("initialDate", ascendingOrDescending === 'descending' ? 'ascending' : 'descending');
                      }}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "20%" }}>
                    Final da Coleta
                    <ArrowsDownUp
                      onClick={() => {
                        if (ascendingOrDescending === 'ascending') setAscendingOrDescending('descending');
                        else setAscendingOrDescending('ascending');
                        sortObjByKey("finalDate", ascendingOrDescending === 'descending' ? 'ascending' : 'descending');
                      }}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "20%" }}>
                    Autor
                    <ArrowsDownUp
                      onClick={() => sortObjByKey("author.first_name")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "15%" }}>Ações</th>
                </tr>
              </thead>
              <tbody>
                {slice.map((item, i) => {
                  let id = item.id;
                  return (
                    <tr key={i} id={"actions" + i} className={styles.tableRow}>
                      <td
                        style={{maxWidth: '250px'}}
                        className={
                          styles.assessmentRow +
                          " " +
                          styles.assessmentTitle +
                          " " +
                          styles.rowTitle + 
                          " " +
                          MainStyle.text_truncate                          
                        }
                      >
                        {item.title}
                      </td>
                      <td className={styles.assessmentRow}>
                        {item.initialDate}
                      </td>
                      <td className={styles.assessmentRow}>{item.finalDate}</td>
                      <td className={styles.assessmentRow}>
                        {item.author.first_name + " " + item.author.last_name}
                      </td>
                      <td className={styles.actionsContainer}>
                        <Pencil
                          className={styles.editAssessmentButton}
                          onClick={() => window.open("/order/" + item.id, '_blank').focus()}
                          size={28}
                        />
                        <div className={styles.helpPopupEdit}>
                          Editar Avaliação
                        </div>
                        <ConfirmationModal
                          id={"advanceReadyAssessment"}
                          iconComponent={
                            <>
                              <FastForward
                                size={26}
                                className={styles.AdvanceReadyButton}
                              />
                              <div className={styles.helpPopupAdvanceReady}>
                                Avançar (Pronta)
                              </div>
                            </>
                          }
                          title={`Deseja realmente mudar o status da avaliação "${itemIdToEdit.title}" para pronta?`}
                          onClick={() => {
                            setItemIdToEdit({ id: item.id, title: item.title });
                            setAdvanceReadyConfirmationModal(true);
                          }}
                          open={advanceReadyConfirmationModal}
                          onOk={() => {
                            advanceReadyAssessment(showMoreActions[id]);
                          }}
                          onCancel={() => {
                            setAdvanceReadyConfirmationModal(false);
                            let _showMoreActions = { ...showMoreActions };
                            _showMoreActions = {};
                            setShowMoreActions(_showMoreActions);
                          }}
                          maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
                        />
                        <DotsThree
                          size={28}
                          className={styles.moreActionsButton}
                          onClick={() => {
                            let _showMoreActions = { ...showMoreActions };
                            _showMoreActions = {};
                            if (!showMoreActions[id]) {
                              _showMoreActions[id] = true;
                            }
                            setShowMoreActions(_showMoreActions);
                          }}
                        />
                        <div className={styles.helpPopupAction}>Mais ações</div>
                        {showMoreActions[id] === true ? (
                          <MoreActionsAssessmentList
                            index={i}
                            id={item.id}
                            setShowMoreActions={setShowMoreActions}
                            showMoreActions={showMoreActions}
                            alerts={alerts}
                            setAlerts={setAlerts}
                            setResultList={setResultList}
                            setMemResultList={setMemResultList}
                            setLoading={setLoading}
                          />
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              tablePagination={tablePagination}
              slice={slice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
            );
          } 

      
      default:
        return (
          <div className={styles.error}>
            <center><Alert type={"danger"} message={"Um problema ocorreu ao carregar os dados. Por favor, tente novamente mais tarde."} /></center>
          </div>
        );
    }
  };

  return (
    <Layout>
      <div>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.createAssessmentButton + " " + "primaryOrange"}
            onClick={() => navigate("/order/new")}
          >
            <PlusCircle size={22} className={styles.addIcon} />
            <span style={{ paddingBottom: "3px" }}>Criar Avaliação</span>
          </Button>
          <Button
            title={"Avaliações Arquivadas"}
            className={styles.archiveButton}
            onClick={() => navigate("/archivedOrders")}
          >
            <Archive size={28} className={styles.archiveIcon} />
          </Button>
        </div>
        <div className={styles.pageHeader}>
          <PageTitle center={true}>Avaliações em Elaboração</PageTitle>
        </div>
        {renderContent()}
      </div>
    </Layout>
  );
};

export default PreparationAssessmentList;

export const MoreActionsAssessmentList = ({
  index,
  id,
  setShowMoreActions,
  showMoreActions,
  alerts,
  setAlerts,
  setResultList,
  setMemResultList,
  setLoading,
}) => {
  const [archiveConfirmationModal, setArchiveConfirmationModal] =
    useState(false);
  const navigate = useNavigate();

  const [activePopUp, setActivePopUp] = useState(true);

  const wrapperRef = useRef(null);

  //This const return if a element is alredy in view
  const [refInView, inView] = useInView();

  const archiveAssessment = () => {
    let _showMoreActions = { ...showMoreActions };
    _showMoreActions = {};
    setShowMoreActions(_showMoreActions);

    archiveAssessmentById(id).then((response) => {
      if (response.status == 200) {
        setArchiveConfirmationModal(false);
        let message = "Avaliação arquivada com sucesso!";
        let updateAlertList = addNewAlert([], { message, type: "success" });
        setAlerts(updateAlertList);
        getAllAssessmentByStatus(
          "preparation",
          setResultList,
          setMemResultList,
          setLoading
        );
      } else {
        setArchiveConfirmationModal(false);
        let message = response.body.message;
        let updateAlertList = addNewAlert([], { message, type: "danger" });
        setAlerts(updateAlertList);
      }
    });
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && inView) {
          let _showMoreActions = { ...showMoreActions };
          _showMoreActions = {};
          setShowMoreActions(_showMoreActions);
        }
      }
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
    }, []);
  }

  useOutsideAlerter(wrapperRef);

  return (
    <div
      ref={wrapperRef}
      key={index}
      id={"actions" + index}
      className={styles.moreActionsPopup}
      style={{ display: activePopUp ? 'block' : 'none' }}
    >
      <div ref={refInView} className={styles.popupHeader}>
        Mais ações
        <X size={16} className={styles.closeMoreActions}
          onClick={() => setActivePopUp(false)}
        />
      </div>
      <div
        className={styles.popupOption}
        onClick={() => window.open("/" + id + "/preview", '_blank').focus()}
      >
        Visualizar
      </div>
      <ConfirmationModal
        id={"archiveAssessment"}
        iconComponent={<div className={styles.popupOption}>Arquivar</div>}
        title={"Deseja realmente arquivar esta avaliação?"}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.45)' }}
        onClick={() => setArchiveConfirmationModal(true)}
        open={archiveConfirmationModal}
        onOk={() => archiveAssessment()}
        onCancel={() => {
          setArchiveConfirmationModal(false);
          let _showMoreActions = { ...showMoreActions };
          _showMoreActions = {};
          setShowMoreActions(_showMoreActions);
        }}
      />
    </div>
  );
};
