import styles from "./radio.module.scss";

const Radio = ({ onClick, id, key, children, className }) => {
  return (
    <div className={styles.radioFlex}>
      <label>
        <input type="radio" className={className} name="a" onClick={onClick} id={id} key={key} />
        {children}
      </label>
    </div>
  );
};

export default Radio;
