// import field.module.css in page where the component will added
import "./field.scss";

import { Input } from "antd";

export const InputPassword = ({ placeholder }) => {
  return <Input.Password placeholder={placeholder} />;
};

export const InputGeneral = ({
  className,
  type,
  placeholder,
  defaultValue,
  iconComponent,
  children,
  onChange,
  name,
  id,
  value,
  style,
  list,
  maxLength
}) => {
  return (
    <Input
      className={className}
      style={style}
      type={type}
      placeholder={placeholder}
      prefix={iconComponent}
      onChange={onChange}
      defaultValue={defaultValue}
      name={name}
      id={id}
      value={value}
      list={list}
      maxLength={maxLength}
    >
      {children}
    </Input>
  );
};

export const disabledInput = ({
  className,
  type,
  placeholder,
  defaultValue,
  iconComponent,
  children,
  onChange,
  name,
  id,
  value,
  style,
  list
}) => {
  return (
    <Input
      className={className}
      style={style}
      type={type}
      placeholder={placeholder}
      prefix={iconComponent}
      onChange={onChange}
      defaultValue={defaultValue}
      name={name}
      id={id}
      value={value}
      list={list}
      disabled
    >
      {children}
    </Input>
  );
};

const Field = ({
  className,
  type,
  style,
  placeholder,
  defaultValue,
  iconComponent,
  children,
  onChange,
  name,
  id,
  value,
  list,
  disabled,
  maxLength
}) => {
  return (
    <>
      {type === "password" ? (
        <InputPassword placeholder={placeholder}></InputPassword>
      ) : (
        disabled == true ?
        (<Input
          className={className}
          type={type}
          style={style}
          placeholder={placeholder}
          iconComponent={iconComponent}
          onChange={onChange}
          defaultValue={defaultValue}
          name={name}
          id={id}
          value={value}
          list={list} 
          disabled
          maxLength={maxLength}
        >
        {children}
      </Input>):
        (<InputGeneral
          className={className}
          type={type}
          style={style}
          placeholder={placeholder}
          prefix={iconComponent}
          onChange={onChange}
          defaultValue={defaultValue}
          name={name}
          id={id}
          value={value}
          list={list} 
          maxLength={maxLength}
        >
        {children}
      </InputGeneral>)
      )}
    </>
  );
};

export default Field;
