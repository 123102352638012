import { Modal } from "antd";
import Alert from "components/Alert/Alert";
import Button from "components/Button/button";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import { getCollectionById } from "components/EditCollection/collectionServices";
import { UnarchiveIcon } from "components/Icon/Icon";
import Layout from "components/Layout/layout";
import PageTitle from "components/PageTitle/pageTitle";
import Pagination from "components/Pagination/pagination";
import TableControl from "components/TableControl/tableControl";
import {
  ArrowCircleLeft,
  ArrowsDownUp,
  DotsThree,
  Trash,
  X,
} from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import ReactLoading from "react-loading";
import Loading from "components/Loading/loading";
import { useNavigate } from "react-router-dom";
import { addNewAlert } from "Services/alertsManagement";

import styles from "./archivedCollectionsList.module.scss";
import {
  deleteCollectionById,
  getAllArchivedCollections,
  unarchiveCollectionById,
} from "./archivedCollectionsServices";
import { useAuthenticator } from "@aws-amplify/ui-react";

const ArchivedCollectionsList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [resultList, setResultList] = useState([]);
  const [memResultList, setMemResultList] = useState([]);
  const [tablePagination, setTablePagination] = useState([]);
  const [slice, setSlice] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showMoreActions, setShowMoreActions] = useState({});
  const [alerts, setAlerts] = useState([]);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [collectionTitle, setCollectionTitle] = useState();
  const [collectionData, setCollectionData] = useState();
  const [listIsSorted, setListIsSorted] = useState(false);

  useEffect(() => {
    if (resultList.length == 0) {
      getAllArchivedCollections(setResultList, setMemResultList, setLoading);
    }
  }, []);


  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const deleteCollection = (id) => {
    let updateAlertList = "";
    let message = "";
    setDeleteConfirmationModal(false);

    deleteCollectionById(id).then((response) => {
      let messageResponse = response.message;

      if (messageResponse.includes("deleted successfully")) {
        message = "Padrão deletado com sucesso!";
        updateAlertList = addNewAlert([], { message, type: "success" });
        getAllArchivedCollections(setResultList, setMemResultList, setLoading);
      } else {
        message = "Ocorreu um erro ao tentar deletar o padrão.";
        updateAlertList = addNewAlert([], {
          message,
          type: "danger",
        });
      }
      setAlerts(updateAlertList);
    });
  };

  const unarchiveCollection = (id) => {
    let updateAlertList = "";
    let message = "";
    unarchiveCollectionById(id).then((response) => {
      let messageResponse = response.message;
      if (messageResponse.includes("desarquivada com sucesso")) {
        message = "Padrão desarquivado com sucesso!";
        updateAlertList = addNewAlert([], { message, type: "success" });
        getAllArchivedCollections(setResultList, setMemResultList, setLoading);
      } else {
        message = "Ocorreu um erro ao desarquivar o padrão.";
        updateAlertList = addNewAlert([], {
          message,
          type: "danger",
        });
      }
      setAlerts(updateAlertList);
    });
  };

  //Sort list by specific key or restore original response
  function sortObjByKey(key) {
    let _resultList = [...resultList];
    function compare(a, b) {
      a = a[key];
      b = b[key];
      var type =
        typeof a === "string" || typeof b === "string" ? "string" : "number";
      var result;
      if (type === "string") result = a.localeCompare(b);
      else result = a - b;
      return result;
    }

    //Check if list was ever sorted during the session
    if (memResultList.length == 0 && listIsSorted == false) {
      //Saves original user API response to memmory
      setMemResultList(resultList);

      //sort user list
      _resultList = _resultList.sort(compare);
      setListIsSorted(true);
    }

    //In case list was already sorted before
    else {
      //In case list isn't currently sorted
      if (listIsSorted == false && memResultList.length > 0) {
        _resultList = _resultList.sort(compare);
        setListIsSorted(true);
      }
      //In case list is currently sorted
      else {
        _resultList = memResultList;
        setListIsSorted(false);
      }
    }

    setResultList(_resultList);
    return _resultList;
  }

  const { user } = useAuthenticator((context) => [context.user]);
  const id = user.username;
  const userRole = localStorage.getItem("role#" + id);

  return (
    <Layout>
      <div>
        <Button
          className={styles.backButton + " " + "secondaryOrange"}
          onClick={() => navigate("/collections")}
        >
          <ArrowCircleLeft size={22} className={styles.backIcon} />
          <span className={styles.backLabel}>Voltar</span>
        </Button>
        <div className={styles.pageHeader}>
          <PageTitle center={true}>Padrões Arquivados</PageTitle>
        </div>
        {loading == true ? (
          <div className={styles.loading}>
            <div className="large">Carregando informações</div>
            <center>
              <ReactLoading
                type="bubbles"
                color="#0064a2"
                height={50}
                width={175}
              />
            </center>
          </div>
          // <Loading />
        ) : (
          <div>
            <TableControl
              resultList={resultList}
              setResultList={setResultList}
              originalListTable={memResultList}
              setTablePagination={setTablePagination}
              setSlice={setSlice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              showByPageDefault={10}
              searchPlaceholder={"Buscar Padrão"}
              hideBulkActions={true}
              hideFilter={true}
            ></TableControl>
            <Pagination
              tablePagination={tablePagination}
              slice={slice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></Pagination>
            {alerts.map((i, k) => {
              return <Alert message={i.message} type={i.type} />;
            })}
            <table className={styles.table}>
              <thead>
                <tr style={{ backgroundColor: "white" }}>
                  {" "}
                  <th style={{ width: "50vw" }} className={styles.tableTitle}>
                    Título
                    <ArrowsDownUp
                      onClick={() => sortObjByKey("title")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "25%" }}>
                    Data
                    <ArrowsDownUp
                      onClick={() => sortObjByKey("createdAt")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "25%" }}>Ações</th>
                </tr>
              </thead>
              <tbody>
                {slice.map((item, i) => {
                  let id = item.id;
                  return (
                    <tr key={i} id={"actions" + i} className={styles.tableRow}>
                      <td
                        className={
                          styles.collectionRow + " " + styles.collectionTitle
                        }
                      >
                        {item.title}
                      </td>
                      <td
                        className={
                          styles.collectionRow + " " + styles.alignCenter
                        }
                      >
                        {item.date}
                      </td>
                      <td className={styles.actionsContainer}>
                        <UnarchiveIcon
                          size={26}
                          className={styles.unarchiveCollectionButton}
                          onClick={() => unarchiveCollection(id)}
                        ></UnarchiveIcon>
                        <div className={styles.helpPopupUnarchive}>
                          Desarquivar Padrão
                        </div>
                        {userRole === "Administrador" &&
                          <ConfirmationModal
                            id={"deleteCollection"}
                            iconComponent={
                              <>
                                <Trash
                                  size={26}
                                  className={styles.deleteCollectionButton}
                                />
                                <div className={styles.helpPopupDelete}>
                                  Deletar Padrão
                                </div>
                              </>
                            }
                            title={"Deseja deletar permanentemente este padrão?"}
                            maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
                            onClick={() => setDeleteConfirmationModal(true)}
                            open={deleteConfirmationModal}
                            onOk={() => deleteCollection(id)}
                            onCancel={() => setDeleteConfirmationModal(false)}
                          />
                        }

                        <DotsThree
                          size={28}
                          title={"Mais ações"}
                          className={styles.moreActionsButton}
                          onClick={() => {
                            let _showMoreActions = { ...showMoreActions };
                            _showMoreActions = {};
                            if (!showMoreActions[id]) {
                              _showMoreActions[id] = true;
                            }
                            setShowMoreActions(_showMoreActions);
                          }}
                        />
                        <div className={styles.helpPopupAction}>Mais ações</div>
                        {showMoreActions[id] == true ? (
                          <MoreActionsArchived
                            index={i}
                            id={item.id}
                            setShowMoreActions={setShowMoreActions}
                            showMoreActions={showMoreActions}
                            setIsModalOpen={setIsModalOpen}
                            setCollectionTitle={setCollectionTitle}
                            setCollectionData={setCollectionData}
                          ></MoreActionsArchived>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <Modal
          title={collectionTitle}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[]}
          centered
        ></Modal>
      </div>
    </Layout>
  );
};

export default ArchivedCollectionsList;

export const MoreActionsArchived = ({
  index,
  id,
  setShowMoreActions,
  showMoreActions,
  setIsModalOpen,
  setCollectionTitle,
  setCollectionData,
}) => {
  const wrapperRef = useRef(null);

  //This const return if a element is alredy in view
  const [refInView, inView] = useInView();

  const [activePopUp, setActivePopUp] = useState(true);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const collectionModal = async () => {
    let _showMoreActions = { ...showMoreActions };
    _showMoreActions = {};
    setShowMoreActions(_showMoreActions);

    const collectionData = await getCollectionById(id);
    setCollectionTitle(collectionData.title);
    setCollectionData(collectionData);
    showModal();
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && inView) {
          let _showMoreActions = { ...showMoreActions };
          _showMoreActions = {};
          setShowMoreActions(_showMoreActions);
        }
      }
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
    }, [ref, inView]);
  }

  useOutsideAlerter(wrapperRef);
  return (
    <div
      ref={wrapperRef}
      key={index}
      id={"actions" + index}
      className={styles.moreActionsPopup}
      style={{ display: activePopUp ? 'block' : 'none' }}
    >
      <div ref={refInView} className={styles.popupHeader} id={"action-title"}>
        Mais ações
        <X size={16} className={styles.closeMoreActions}
          onClick={() => setActivePopUp(false)} />
      </div>
      <div className={styles.popupOption} onClick={() => collectionModal()}>
        Ver Padrão
      </div>
    </div>
  );
};
