import { verifySession } from "Services/authenticationServices";
import { formatDate } from "Services/globalServices";

const stage = process.env.REACT_APP_STAGE;
const baseAssessmentURL =
  `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/orders/archive/`;

export async function getAllArchivedAssessment(
  setList,
  setMemList,
  setLoading
) {
  const assessment = await getArchivedAssessment();
  setList(assessment);
  setMemList(assessment);
  setLoading(false);
}

export async function getArchivedAssessment() {
  let jwtToken = await verifySession();
  let assessmentData;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };

  await fetch(baseAssessmentURL, parameters)
    .then((response) => {
      return response.json();
    })
    .then((body) => {
      if(body.status.statusCode == 0) {
        assessmentData = body.data;
        assessmentData.map((assessment, index) => {
          assessmentData[index].initialDate = formatDate(
            assessment.meta.opening,
            "DD/MM/YYYY"
          );
          assessmentData[index].finalDate = formatDate(
            assessment.meta.closing,
            "DD/MM/YYYY"
          );
          console.log(assessmentData);
        });
      }    
    });

  return assessmentData;
}

export async function unarchiveAssessmentById(id) {
  let apiResponse;
  let getJwt = await verifySession();

  const unarchiveAssessmentURL = baseAssessmentURL + id;

  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
    };

    await fetch(unarchiveAssessmentURL, parameters)
      .then((response) => response.json())
      .then((data) => {
        apiResponse = data;
      });
  };

  await fetchData(getJwt);
  return apiResponse;
}

export async function deleteAssessmentById(id) {
  let apiResponse;
  let getJwt = await verifySession();

  const unarchiveAssessmentURL = baseAssessmentURL + id;

  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
    };

    await fetch(unarchiveAssessmentURL, parameters)
      .then((response) => response.json())
      .then((data) => {
        apiResponse = data;
      });
  };

  await fetchData(getJwt);
  return apiResponse;
}
