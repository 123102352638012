import Alert from "components/Alert/Alert";
import { updateAssessmentStatusById } from "components/AssessmentLists/assessmentListsServices";
import { getAssetsItems } from "components/AssetsLists/assetsListsServices";
import Button from "components/Button/button";
import CloseButton from "components/CloseButton/closeButton";
import { getCollections } from "components/CollectionsList/collectionsService";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import DropdownListField from "components/DropdownListField/dropdownListFIeld";
import Field from "components/Field/field";
import FormContainer from "components/FormContainer/formContainer";
import { getParameterItems } from "components/GeneralLists/generalListsServices";
import Layout from "components/Layout/layout";
import ManageQuestionnaire from "components/ManageQuestionnaire/manageQuestionnaire";
import PageTitle from "components/PageTitle/pageTitle";
import { SelectColorField } from "components/SelectColor/selectColor";
import { SelectImageField } from "components/UploadImageField/uploadImageField";
import { getUsers } from "components/UsersList/usersServices";
import _, { isArray } from "lodash";
import {
  CaretLeft,
  CaretRight,
  FastForward,
  Pencil,
  Rewind,
  Eye,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { addNewAlert } from "Services/alertsManagement";
import TextArea from "antd/es/input/TextArea";
import Checkbox from "components/Checkbox/checkbox";
import brazilIcon from "../../assets/icons/brazil.svg";
import spainIcon from "../../assets/icons/spain.svg";
import usIcon from "../../assets/icons/us.svg";
import {
  createAssessment,
  getAssessmentById,
  getSubmissionsCount,
  updateAssessmentById,
} from "./assessmentService";
import styles from "./editAssessment.module.scss";
import { PlusCircle } from "phosphor-react";
import Loading from "components/Loading/loading";
import MainStyle from "components/UI/main.module.scss";

//#region Utils
function StartDateToDb (date) { // Set Datetime to beginning of the day
  if (!(date instanceof Date)) {
    throw new Error("Input must be a Date object");
  }
  date.setHours(0,0,0,0);
  return date.toISOString().toString().substring(0, 16);
}
function $(domElement) {
  return(document.getElementById(domElement));

}
//#endregion
//#region Limits
  const Header_MaxChar = 100;
//#endregion

const EditAssessment = () => {
  let { assessmentToEdit } = useParams();
  const pageTitle = assessmentToEdit == "new" ? "Criar Avaliação" : "Editar Avaliação";
  //console.log(assessmentToEdit);
  const navigate = useNavigate();
  const [currentButton, setCurrentButton] = useState("generalRecord");
  const [loading, setLoading] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [fieldList, setFieldList] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [disableSave, setDisableSave] = useState(true);
  const [disableEdit, setDisableEdit] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");
  const [answersCount, setAnswersCount] = useState("");
  const [assessmentOriginal, setAssessmentOriginal] = useState({});
  const [blocks, setBlocks] = useState([]);
  const [logo1, setLogo1] = useState({ title: null, content: null });
  const [logo2, setLogo2] = useState({ title: null, content: null });
  const [logo3, setLogo3] = useState({ title: null, content: null });
  const [patterns, setPatterns] = useState([]);
  const [closingConfirmationModal, setClosingConfirmationModal] = useState();
  const [backAssessmentConfirmationModal, setBackAssessmentConfirmationModal] = useState(false);
  const [isBackPopupOpenned, setIsBackPopupOpenned] = useState(false);
  const [disableInitialDate, setDisableInitialDate] = useState(true);
  const [closingDate, setClosingDate] = useState("");
  const [disableSavePopup, setDisableSavePopup] = useState(true);
  const [advanceReadyConfirmationModal, setAdvanceReadyConfirmationModal] = useState(false);
  const [advanceCloseConfirmationModal, setAdvanceCloseConfirmationModal] = useState(false);
  const [pt, setPt] = useState(true);
  const [es, setEs] = useState(false);
  const [en, setEn] = useState(false);
  const [explanation, setExplanation] = useState(false);
  const [caption, setCaption] = useState(false);
  const [assetList, setAssetList] = useState([]);
  const [orderDefaultUserAccess, setOrderDefaultUserAccess] = useState([]);
  const [assessment, setAssessment] = useState({
    title: null,
    orderClass: null,
    meta: {
      school: null,
      area: null,
      client: null,
      program: null,
      opening: null,
      closing: null,
    },
    languages: { pt: true, en: false, es: false },
    itemStatus: null,
    questionnaire: [],
    orderSquad: [],
    outputAccess: [],
    header: {
      headerLine1: {
        pt: "Avaliação de Qualidade",
        es: "Evaluación de Calidad",
        en: "Quality Assessment",
      },
      headerLine2: { pt: "Programa", es: "Programa", en: "Program" },
      headerLine3: { pt: null, es: null, en: null },
      headerLine4: { pt: null, es: null, en: null },
      logos: [],
    },
  });


  // Get the server call and store the data in the use state
  const [Dataset_Image, setDataset_Image] = useState([]);
  const [IsReadyData, setIsReadyData] = useState(false);


    //#region Server Calls
    async function getPatternsList() {
      let ready = false;
      let dataPatterns = await getCollections();
      if (dataPatterns && Array.isArray(dataPatterns)) {
        let _patternsList = [];
        dataPatterns.map((pattern) => {
          _patternsList.push({ value: pattern.id, label: pattern.title });
        });        
        setPatterns(_patternsList);
        ready = true;
        return (ready);
      }
    }
    async function getAreaList() {
      let ready = false;
      let dataArea = await getParameterItems("area");
      if (dataArea && Array.isArray(dataArea)) { 
        let _areaList = [];
        dataArea.map((area) => {
          let areaSk = area.sk.split("#")[1];
    
          let areaItem = { value: areaSk, label: area.title, hierarchy: area.sk };
    
          // check if area has accessGranted property
          if (area.accessGranted) {
            areaItem.accessGranted = area.accessGranted;
          }
          _areaList.push(areaItem);
        });
        setAreaList(_areaList);
        ready = true;
        return (ready);
      }
    };
    async function getAssetsList () {
      try {
        let ready = false;
        let dataAsset = await getAssetsItems("image");
        if (dataAsset && Array.isArray(dataAsset)) {
          let _assetList = [];
          //  Create a arr of obj in the images to pass to the state when it return the server call
          let _Dataset_Image = [];
          dataAsset.forEach((asset) => {
            if (asset.title.includes("ppt_")) {
              _assetList.push({ value: asset.id, label: asset.title });
            }
            else {
              //  Push the images that does not include the ppt
              _Dataset_Image.push(asset);
            }
          });
          setAssetList(_assetList);
          // Set the state of the dataset to push to components
          setDataset_Image(_Dataset_Image);
          ready = true;
          return (ready);
  
        }    
      } catch (error) {
        console.error("Error fetching asset items:", error);
      }
    };

    async function getFieldList() {
      let ready = false;
      let dataField = await getParameterItems("field");
      if (dataField && Array.isArray(dataField)) {
        let _fieldList = [];
        dataField.map((field) => {
          console.log(field);
          let fieldSk = field.sk.split("#")[0];
          _fieldList.push({ value: fieldSk, label: field.title });
        });
        setFieldList(_fieldList);
        ready = true;
        return (ready);
      }
    };
  
    async function getUsersList() {
      let ready = false;
      let dataUsers = await getUsers();
      if (dataUsers && Array.isArray(dataUsers)) {
        let _usersList = [];
        // Prevent try access null data
        if (dataUsers) {
          dataUsers.map((user) => {
            const userLabel = user.first_name + " (" + user.email + ")";
            _usersList.push({
              value: user.id,
              label: userLabel,
              photo: user.photo,
              name: user.first_name + " " + user.last_name,
            });
          });
          setUsersList(_usersList);
          ready = true;
          return (ready);
        }
        //console.log("Users are loaded!!!");
      }
    };
    //#endregion

  async function GetServerCall() {
    setIsReadyData(false);
    if(await getPatternsList() == true && await getAreaList() == true  &&  await getAssetsList() == true && await getFieldList() == true && await getUsersList() == true) {
      setIsReadyData(true);
    }
  }
  
  /*
  const getPatternsList = async () => {
    let dataPatterns = await getCollections();
    if (dataPatterns && Array.isArray(dataPatterns)) {
      let _patternsList = [];
      dataPatterns.map((pattern) => {
        _patternsList.push({ value: pattern.id, label: pattern.title });
      });
      setPatterns(_patternsList);
    }
  };

  const getAreaList = async () => {
    let dataArea = await getParameterItems("area");
    if (dataArea && Array.isArray(dataArea)) { 
      let _areaList = [];
      dataArea.map((area) => {
        let areaSk = area.sk.split("#")[1];
  
        let areaItem = { value: areaSk, label: area.title, hierarchy: area.sk };
  
        // check if area has accessGranted property
        if (area.accessGranted) {
          areaItem.accessGranted = area.accessGranted;
        }
        _areaList.push(areaItem);
      });
      setAreaList(_areaList);
    }
  };

  const getAssetsList = async () => {
    try {
      let dataAsset = await getAssetsItems("image");
      if (dataAsset && Array.isArray(dataAsset)) {
        let _assetList = [];
        //  Create a arr of obj in the images to pass to the state when it return the server call
        let _Dataset_Image = [];
        dataAsset.forEach((asset) => {
          if (asset.title.includes("ppt_")) {
            _assetList.push({ value: asset.id, label: asset.title });
          }
          else {
            //  Push the images that does not include the ppt
            _Dataset_Image.push(asset);
          }
        });
        setAssetList(_assetList);
        // Set the state of the dataset to push to components
        setDataset_Image(_Dataset_Image);

      }    
    } catch (error) {
      console.error("Error fetching asset items:", error);
    }
  };

  const getFieldList = async () => {
    let dataField = await getParameterItems("field");
    if (dataField && Array.isArray(dataField)) {
      let _fieldList = [];
      dataField.map((field) => {
        console.log(field);
        let fieldSk = field.sk.split("#")[0];
        _fieldList.push({ value: fieldSk, label: field.title });
      });
      setFieldList(_fieldList);
    }
  };

  const getUsersList = async () => {
    let dataUsers = await getUsers();
    if (dataUsers && Array.isArray(dataUsers)) {
      let _usersList = [];
      // Prevent try access null data
      if (dataUsers) {
        dataUsers.map((user) => {
          const userLabel = user.first_name + " (" + user.email + ")";
          _usersList.push({
            value: user.id,
            label: userLabel,
            photo: user.photo,
            name: user.first_name + " " + user.last_name,
          });
        });
        setUsersList(_usersList);
      }
      //console.log("Users are loaded!!!");
    }
  };
  */

  const fetchData = () => {
    const getAssessmentData = async () => {
      const assessmentData = await getAssessmentById(assessmentToEdit);

      if (assessmentData.error) {
        navigate("/notFound");
      }

      if (!assessmentData.error) {
        let _assessment = { ...assessment };

        let _assessmentOriginal = { ...assessmentOriginal };

        await getSubmissionsCount(
          assessmentToEdit,
          setAnswersCount,
          assessmentData.itemStatus,
          setDisableEdit
        );
        _assessment.title = assessmentData.title;
        _assessment.id = assessmentData.id;
        _assessment.itemStatus = assessmentData.itemStatus;
        _assessment.taxonomy = assessmentData.taxonomy;
        _assessment.itemStatus = assessmentData.itemStatus;
        setOrderStatus(assessmentData.itemStatus);
        _assessment.author = assessmentData.author;
        _assessment.orderClass = assessmentData.orderClass;
        _assessment.languages = assessmentData.languages;
        //check if _assessment.languages is not a string
        if (typeof _assessment.languages != "string") {
          setPt(_assessment.languages.pt);
          setEn(_assessment.languages.en);
          setEs(_assessment.languages.es);
        }
        // assure compatibility in case language comes following the old model (when it was only one language)
        if (typeof _assessment.languages === "string") {
          if (_assessment.languages == "pt") {
            setPt(true);
            setEn(false);
            setEs(false);
          }
          if (_assessment.languages == "en") {
            setPt(false);
            setEn(true);
            setEs(false);
          }
          if (_assessment.languages == "es") {
            setPt(false);
            setEn(false);
            setEs(true);
          }
        }

        _assessment.orderSquad = assessmentData.orderSquad;
        _assessment.outputAccess = assessmentData.outputAccess;
        _assessment.meta.school = assessmentData.meta.school;
        _assessment.meta.program = assessmentData.meta.program;
        _assessment.meta.area = assessmentData.meta.area;
        _assessment.meta.client = assessmentData.meta.client;
        _assessment.meta.opening = assessmentData.meta.opening;
        _assessment.meta.closing = assessmentData.meta.closing;
        _assessment.header.headerLine1 = assessmentData.header.headerLine1;
        _assessment.header.headerLine2 = assessmentData.header.headerLine2;
        _assessment.header.headerLine3 = assessmentData.header.headerLine3;
        _assessment.header.headerLine4 = assessmentData.header.headerLine4;
        if (!!assessmentData.header.headerLine5) {
          _assessment.header.headerLine5 = assessmentData.header.headerLine5;
          setExplanation(true);
        }
        if (!!assessmentData.header.surveyCaption) {
          _assessment.header.surveyCaption =
            assessmentData.header.surveyCaption;
          setCaption(true);
        }
        if (!!assessmentData.header.logos) {
          _assessment.header.logos = assessmentData.header.logos;
          !!assessmentData.header.logos.logo1 &&
            setLogo1(assessmentData.header.logos.logo1);
          !!assessmentData.header.logos.logo2 &&
            setLogo2(assessmentData.header.logos.logo2);
          !!assessmentData.header.logos.logo3 &&
            setLogo3(assessmentData.header.logos.logo3);
        }
        _assessment.questionnaire = assessmentData.questionnaire;

        setBlocks(assessmentData.questionnaire);
        _assessmentOriginal = _.cloneDeep(_assessment);
        setAssessment(_assessment);
        console.log(_assessment);
        setAssessmentOriginal(_assessmentOriginal);
        setLoading(false);
      }
    };

    const newAssessment = () => {
      setDisableSave(false);
      setLoading(false);
    };
    assessmentToEdit != "new" ? getAssessmentData() : newAssessment();
  };

  const hasChanged = async () => {
    let isEqual = _.isEqual(assessment, assessmentOriginal);
    isEqual ? setDisableSave(true) : setDisableSave(false);
  };

  useEffect(() => {
    //getPatternsList();
    //getAreaList();
    //getFieldList();
    //getUsersList();
    //getAssetsList();
    //GetServerCall();
    //fetchData();
    const loadData = async () => {
      await GetServerCall(); // Wait for the server call to finish
      await fetchData();
    };

    loadData();

  }, []);

  useEffect(() => {
    if (assessmentToEdit != "new") {
      hasChanged();
    }
  }, [assessment]);

  //hook to update assessment every time updates blocks
  useEffect(() => {
    let _assessment = { ...assessment };
    _assessment.questionnaire = blocks;
    setAssessment(_assessment);
  }, [blocks]);

  //hook to update assessment every time updates logo1
  useEffect(() => {
    let _assessment = { ...assessment };
    if (logo1.id) {
      if (!!_assessment.header.logos) {
        _assessment.header.logos = {
          ..._assessment.header.logos,
          logo1: logo1.id,
        };
      } else {
        _assessment.header = {
          ..._assessment.header,
          logos: { logo1: logo1.id },
        };
      }
    } else {
      if (_assessment.header.logos) {
        delete _assessment.header.logos.logo1;
        if (Object.keys(_assessment.header.logos).length === 0) {
          delete _assessment.header.logos;
        }
      }
    }
    setAssessment(_assessment);
  }, [logo1]);

  // Hook to update assessment every time updates logo2
  useEffect(() => {
    let _assessment = { ...assessment };
    if (logo2.id) {
      if (!!_assessment.header.logos) {
        _assessment.header.logos = {
          ..._assessment.header.logos,
          logo2: logo2.id,
        };
      } else {
        _assessment.header = {
          ..._assessment.header,
          logos: { logo2: logo2.id },
        };
      }
    } else {
      if (_assessment.header.logos && _assessment.header.logos.logo2) {
        delete _assessment.header.logos.logo2;
        if (Object.keys(_assessment.header.logos).length === 0) {
          delete _assessment.header.logos;
        }
      }
    }

    setAssessment(_assessment);
  }, [logo2]);

  // Hook to update assessment every time updates logo3
  useEffect(() => {
    let _assessment = { ...assessment };
    if (logo3.id) {
      if (!!_assessment.header.logos) {
        _assessment.header.logos = {
          ..._assessment.header.logos,
          logo3: logo3.id,
        };
      } else {
        _assessment.header = {
          ..._assessment.header,
          logos: { logo3: logo3.id },
        };
      }
    } else {
      if (_assessment.header.logos && _assessment.header.logos.logo3) {
        delete _assessment.header.logos.logo3;
        if (Object.keys(_assessment.header.logos).length === 0) {
          delete _assessment.header.logos;
        }
      }
    }

    setAssessment(_assessment);
  }, [logo3]);

  //function to switch tab content
  const handleContent = () => {
    switch (currentButton) {
      case "generalRecord":
        return (
          <GeneralRecord
            assessment={assessment}
            setAssessment={setAssessment}
            usersList={usersList}
            areaList={areaList}
            setPt={setPt}
            setEn={setEn}
            setEs={setEs}
            pt={pt}
            en={en}
            es={es}
            assetList={assetList}
            orderDefaultUserAccess={orderDefaultUserAccess}
            setOrderDefaultUserAccess={setOrderDefaultUserAccess}
          ></GeneralRecord>
        );
      case "header":
        return (
          <Header
            assessment={assessment}
            setAssessment={setAssessment}
            logo1={logo1}
            setLogo1={setLogo1}
            logo2={logo2}
            setLogo2={setLogo2}
            logo3={logo3}
            setLogo3={setLogo3}
            disableEdit={disableEdit}
            explanation={explanation}
            setExplanation={setExplanation}
            caption={caption}
            setCaption={setCaption}
            pt={pt}
            setPt={setPt}
            en={en}
            setEn={setEn}
            es={es}
            setEs={setEs}
            Dataset_Image={Dataset_Image}
          ></Header>
        );
      case "questionnaire":
        return (
          <ManageQuestionnaire
            blocks={blocks}
            setBlocks={setBlocks}
            patterns={patterns}
            usersList={usersList}
            areaList={areaList}
            fieldList={fieldList}
            languages={{ pt: pt, en: en, es: es }}
          ></ManageQuestionnaire>
        );
    }
  };

  const assessmentValidation = () => {
    let isValid = true;
    let langSelected = Object.keys(assessment.languages).find(
      (lang) => assessment.languages[lang]
    );
    console.log(assessment);

    console.log("assessment.meta.school:", assessment.meta.school);
    console.log("assessment.meta.area:", assessment.meta.area);
    console.log("assessment.meta.client:", assessment.meta.client);
    console.log("assessment.meta.opening:", assessment.meta.opening);
    console.log("assessment.meta.closing:", assessment.meta.closing);
    console.log("assessment.orderSquad.length:", assessment.orderSquad.length);
    console.log("langSelected:", langSelected);
    console.log(
      "assessment.header.headerLines:",
      assessment.header.headerLine1 +
        " || " +
        assessment.header.headerLine2 +
        " || " +
        assessment.header.headerLine3 +
        " || " +
        assessment.header.headerLine4
    );

    const isQuestionnaireInvalid = () => {
      let isInvalid = false;

      const isTeacherInvalid = (headerLines) => {
        let isInvalid = false;
        if (headerLines) {
          headerLines.map((line) => {
            if (line.type === "teacher") {
              if (line.values.designation == null || line.values.id == null) {
                isInvalid = true;
                console.log("teacher is invalid");
              }
            }
          });
        }
        console.log("Validação do Questionário!!! ", isInvalid);
        //console.log(isInvalid)
        return isInvalid;
      };

      // const isLabeledTextInvalid = (headerLines) => {

      //   let isInvalid = false;
      //   if (headerLines) {
      //     headerLines.map((line) => {
      //       if(line.type === "labeledText"){
      //         if (line.values.label == null || line.values.text == null) {
      //           isInvalid = true;
      //           console.log("labeled Text is invalid")
      //         }
      //       }
      //     });
      //   }
      //   console.log(isInvalid)
      //   return isInvalid;
      // };

      if (assessment.questionnaire) {
        assessment.questionnaire.map((quest) => {
          console.log(isTeacherInvalid(quest.blockHeader.headerLines));
          if (
            quest.field == undefined ||
            quest.field == "" ||
            quest.collection == undefined ||
            quest.collection == "" ||
            ("title" in quest.blockHeader &&
              (quest.blockHeader.title == "" ||
                quest.blockHeader.title == null)) ||
            isTeacherInvalid(quest.blockHeader.headerLines)
            // isTeacherInvalid(quest.blockHeader.headerLines) ||
            // isLabeledTextInvalid(quest.blockHeader.headerLines)
          ) {
            isInvalid = true;
          }
        });
      }
      console.log(isInvalid);
      return isInvalid;
    };

    if (
      assessment.meta.school == undefined ||
      assessment.meta.school == "" ||
      assessment.meta.area == undefined ||
      assessment.meta.area == "" ||
      assessment.meta.program == undefined ||
      assessment.meta.program == "" ||
      assessment.meta.client == undefined ||
      assessment.meta.client == "" ||
      assessment.meta.opening == undefined ||
      assessment.meta.opening == "" ||
      assessment.meta.closing == undefined ||
      assessment.meta.closing == "" ||
      assessment.orderSquad.length == 0 ||
      langSelected == undefined ||
      isQuestionnaireInvalid()
    ) {
      return (isValid = false);
    }
    console.log(isValid);
    return isValid;
  };

  const handleClass = () => {
    const buttonDisabled = disableSave ? styles.buttonDisabled : null;
    const className =
      styles.saveButton + " " + "primaryOrange" + " " + buttonDisabled;
    return className;
  };

  const handleClassPopup = () => {
    const buttonDisabled = disableSavePopup ? styles.buttonDisabled : null;
    const className =
      styles.tabsButtonFooter + " " + "primaryOrange" + " " + buttonDisabled;
    return className;
  };

  const createNewAssessment = async (assessmentBody, updateAlertList) => {
    createAssessment(assessmentBody).then((newAssessment) => {
      console.log(newAssessment);
      if (newAssessment.status == 200) {
        const id = newAssessment.body.orderId;
        setCurrentButton("generalRecord");
        let message =
          "Avaliação " + assessmentBody.title + " criada com sucesso!";
        let updateAlertList = addNewAlert([], { message, type: "success" });
        setAlerts(updateAlertList);
        navigate("/order/" + id);
        window.location.reload();
      } else {
        let message = newAssessment.body.error;
        updateAlertList = addNewAlert([], {
          message,
          type: "danger",
        });
      }
      //fetchData();
      setLoading(false);
      setAlerts(updateAlertList);
    });
  };

  const buildAssessmentBody = (method) => {
    let assessmentBody = { ...assessment };

    //let colorId = assessment.color.id;

    //if (colorId.includes("Color")) {
    //  colorId = colorId.split("Color#")[1];
    //}

    //assessmentBody.color = colorId;

    if (
      !!assessmentBody.header.logos &&
      !assessmentBody.header.logos.logo1 &&
      !assessmentBody.header.logos.logo2 &&
      !assessmentBody.header.logos.logo3
    ) {
      delete assessmentBody.header.logos;
    }

    if (method == "POST") {
      return assessmentBody;
    } else if (method == "PATCH") {
      assessmentBody.questionnaire.map((quest, index) => {
        if (!!assessmentBody.questionnaire[index].collection.collectionId) {
          assessmentBody.questionnaire[index].collection =
            quest.collection.collectionId;
        }
      });
      delete assessmentBody.id;

      if (!!assessmentBody.header.logos) {
        if (!!assessmentBody.header.logos.logo1) {
          if (assessmentBody.header.logos.logo1.includes("Image#")) {
            assessmentBody.header.logos.logo1 =
              assessmentBody.header.logos.logo1.split("Image#")[1];
          }
        }

        if (!!assessmentBody.header.logos.logo2) {
          if (assessmentBody.header.logos.logo2.includes("Image#")) {
            assessmentBody.header.logos.logo2 =
              assessmentBody.header.logos.logo2.split("Image#")[1];
          }
        }

        if (!!assessmentBody.header.logos.logo3) {
          if (assessmentBody.header.logos.logo3.includes("Image#")) {
            assessmentBody.header.logos.logo3 =
              assessmentBody.header.logos.logo3.split("Image#")[1];
          }
        }
        console.log("assessmentBodyAfter", assessmentBody);
      }
      return assessmentBody;
    }
  };

  const updateAssessment = async (assessmentBody, id, updateAlertList) => {
    updateAssessmentById(assessmentBody, id).then((responseAssessment) => {
      if (responseAssessment.status == 200) {
        setDisableSave(true);
        let message =
          "Avaliação '" + assessmentBody.title + "' atualizada com sucesso!";
        updateAlertList = addNewAlert([], { message, type: "success" });
      } else {
        let message = responseAssessment.body.message;
        updateAlertList = addNewAlert([], {
          message,
          type: "danger",
        });
      }
      setAlerts(updateAlertList);
      fetchData();
    });
  };

  const handleSaveOrder = async () => {
    const isValid = await assessmentValidation();
    let updateAlertList = "";
    //console.log("isValid", isValid);
    if (isValid == true) {
      setLoading(true);
      let assessmentBody;

      if (assessmentToEdit == "new") {
        assessmentBody = buildAssessmentBody("POST");
        createNewAssessment(assessmentBody, updateAlertList);
      } else {
        assessmentBody = buildAssessmentBody("PATCH");
        updateAssessment(assessmentBody, assessmentToEdit, updateAlertList);
      }
    } else {
      let message =
        "Preencha todos os campos obrigátorios para atualizar o padrão!";
      updateAlertList = addNewAlert([], { message, type: "danger" });
      setAlerts(updateAlertList);
    }
  };

  const handleClassTab = (button) => {
    let buttonClasses;
    let isDisabled = disableEdit ? styles.disabledButton : null;
    buttonClasses =
      currentButton == button
        ? styles.button + " " + styles.activeButton
        : styles.button + " " + styles.inactiveButton + " " + isDisabled;
    return buttonClasses;
  };

  const handleStatusLabel = () => {
    console.log("orderStatus", orderStatus);
    switch (orderStatus) {
      case "preparation":
        return <span className={styles.statusValueLabel}>em elaboração</span>;
      case "ready":
        return <span className={styles.statusValueLabel}>pronta</span>;
      case "closed":
        return <span className={styles.statusValueLabel}>encerrada</span>;
    }
  };

  const handleBackToPreparationStatus = () => {
    setLoading(true);
    let body = {
      way: false,
    };

    updateAssessmentStatusById(assessment.id, body).then((response) => {
      if (response.status == 200) {
        setBackAssessmentConfirmationModal(false);
        let message = "Status da avaliação alterado com sucesso!";
        let updateAlertList = addNewAlert([], { message, type: "success" });
        setAlerts(updateAlertList);
        fetchData();
      } else {
        setBackAssessmentConfirmationModal(false);
        let message = response.body.message;
        let updateAlertList = addNewAlert([], { message, type: "danger" });
        setAlerts(updateAlertList);
      }
      setLoading(false);
    });
  };

  const handleBackToReadyStatus = () => {
    setIsBackPopupOpenned(false);
    setLoading(true);

    let bodyUpdateDate = buildAssessmentBody("PATCH");

    updateAssessmentById(bodyUpdateDate, assessment.id).then(
      (responseAssessment) => {
        if (responseAssessment.status == 200) {
          let body = {
            way: false,
          };
          updateAssessmentStatusById(assessment.id, body).then((response) => {
            if (response.status == 200) {
              let message = "Status da avaliação alterado com sucesso!";
              let updateAlertList = addNewAlert([], {
                message,
                type: "success",
              });
              setAlerts(updateAlertList);
              fetchData();
            } else {
              let message = response.body.message;
              let updateAlertList = addNewAlert([], {
                message,
                type: "danger",
              });
              setAlerts(updateAlertList);
            }
            setLoading(false);
          });
        } else {
          let message = responseAssessment.body.message;
          let updateAlertList = addNewAlert([], {
            message,
            type: "danger",
          });
          setAlerts(updateAlertList);
          setLoading(false);
        }
      }
    );
  };

  function controlPopUp() {
    if (isBackPopupOpenned == true) {
      setIsBackPopupOpenned(false);
    } else {
      setIsBackPopupOpenned(true);
    }
  }

  const advanceReadyAssessment = () => {
    setLoading(true);

    let body = {
      way: true,
    };

    updateAssessmentStatusById(assessment.id, body).then((response) => {
      if (response.status == 200) {
        setAdvanceReadyConfirmationModal(false);
        let message = "Avaliação avançada com sucesso!";
        let updateAlertList = addNewAlert([], { message, type: "success" });
        setAlerts(updateAlertList);
        fetchData();
      } else {
        setAdvanceReadyConfirmationModal(false);
        let message = response.body.message;
        let updateAlertList = addNewAlert([], { message, type: "danger" });
        setAlerts(updateAlertList);
        setLoading(false);
      }
    });
  };

  const advanceCloseAssessment = () => {
    setLoading(true);
    let body = {
      way: true,
    };

    updateAssessmentStatusById(assessment.id, body).then((response) => {
      if (response.status == 200) {
        setAdvanceCloseConfirmationModal(false);
        setLoading(false);
        let message = "Avaliação avançada com sucesso!";
        let updateAlertList = addNewAlert([], { message, type: "success" });
        setAlerts(updateAlertList);
        fetchData();
      } else {
        setAdvanceCloseConfirmationModal(false);
        let message = response.body.message;
        let updateAlertList = addNewAlert([], { message, type: "danger" });
        setAlerts(updateAlertList);
        setLoading(false);
      }
    });
  };

  return (
    <Layout>
      <div className={styles.pageHeader}>
        <PageTitle>{pageTitle}</PageTitle>
        <div className={styles.closeButton}>
          <ConfirmationModal
            onClick={() => setClosingConfirmationModal(true)}
            title={"Deseja sair?"}
            open={closingConfirmationModal}
            onOk={() => navigate('/dashboard')}
            onCancel={() => setClosingConfirmationModal(false)}
            iconComponent={<CloseButton />}
          />
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          className={styles.createQuestionButton + " " + "primaryOrange"}
          onClick={() => {
            navigate("/order/new");
            window.location.reload();
          }}
        >
          <PlusCircle size={22} className={styles.addIcon} />
          <span style={{ paddingBottom: "3px" }}>Criar Avaliação</span>
        </Button>
      </div>
      {loading == true ? (
        <div className={styles.loading}>
          <div className="large">Carregando informações</div>
          <center>
            <ReactLoading
              type="bubbles"
              color="#0064a2"
              height={50}
              width={175}
            />
          </center>
        </div>
      ) : (
        // <Loading />
        <>
          <div className={styles.buttonContainer}>
            <Button
              type="text"
              onClick={() => {
                setCurrentButton("generalRecord");
              }}
              className={
                currentButton == "generalRecord"
                  ? styles.button + " " + styles.activeButton
                  : styles.button + " " + styles.inactiveButton
              }
              id="generalRecord"
            >
              Cadastro Geral
            </Button>
            <Button
              type="text"
              onClick={() => {
                setCurrentButton("header");
              }}
              className={handleClassTab("header")}
              id="header"
              isDisabled={disableEdit}
            >
              Cabeçalho
            </Button>
            <Button
              type="text"
              onClick={() => {
                setCurrentButton("questionnaire");
              }}
              className={handleClassTab("questionnaire")}
              id="questionnaire"
              isDisabled={disableEdit}
            >
              Questionário
            </Button>
          </div>
          <div>
            <div className={styles.statusContainer}>
              {orderStatus == "ready" && answersCount == 0 ? (
                <ConfirmationModal
                  id={"backAssessment"}
                  iconComponent={
                    <>
                      <Rewind
                        size={22}
                        className={styles.backAssessmentButton}
                        onClick={() => {}}
                      />
                      <div className={styles.helpPopupBackAssessment}>
                        Voltar Status (Elaboração)
                      </div>
                    </>
                  }
                  title={`Deseja realmente voltar o status da avaliação "${assessment.title}" para em elaboração?`}
                  onClick={() => {
                    setBackAssessmentConfirmationModal(true);
                  }}
                  open={backAssessmentConfirmationModal}
                  onOk={() => handleBackToPreparationStatus()}
                  onCancel={() => {
                    setBackAssessmentConfirmationModal(false);
                  }}
                />
              ) : null}
              {orderStatus == "closed" ? (
                <>
                  <Rewind
                    size={22}
                    className={styles.backAssessmentButton}
                    onClick={() => {
                      setIsBackPopupOpenned(true);
                    }}
                  />
                  <div
                    className={
                      styles.helpPopupBackAssessment +
                      " " +
                      styles.helpPopupBackReady
                    }
                  >
                    Voltar Status (Pronta)
                  </div>
                </>
              ) : null}
              {assessmentToEdit != "new" && (
                <span className={styles.statusLabel}>status: </span>
              )}
              {handleStatusLabel()}
              {console.log("answersCount", answersCount)}
              {console.log("OrderId", assessment.id)}
              {orderStatus == "preparation" ? (
                <ConfirmationModal
                  id={"advanceReadyAssessment"}
                  iconComponent={
                    <>
                      <FastForward
                        size={22}
                        className={styles.advanceButton}
                        onClick={() => {}}
                      />
                      <div className={styles.helpPopupAdvance}>
                        Avançar Status (Pronta)
                      </div>
                    </>
                  }
                  title={`Deseja realmente mudar o status da avaliação "${assessment.title}" para pronta?`}
                  onClick={() => {
                    setAdvanceReadyConfirmationModal(true);
                  }}
                  open={advanceReadyConfirmationModal}
                  onOk={() => {
                    advanceReadyAssessment();
                  }}
                  onCancel={() => {
                    setAdvanceReadyConfirmationModal(false);
                  }}
                />
              ) : null}
              {orderStatus == "ready" ? (
                <ConfirmationModal
                  id={"advanceCloseAssessment"}
                  iconComponent={
                    <>
                      <FastForward
                        size={22}
                        className={styles.advanceButton}
                        onClick={() => {}}
                      />
                      <div className={styles.helpPopupAdvance}>
                        Avançar Status (Encerrada)
                      </div>
                    </>
                  }
                  title={`Deseja realmente mudar o status da avaliação "${assessment.title}" para encerrada?`}
                  onClick={() => {
                    setAdvanceCloseConfirmationModal(true);
                  }}
                  open={advanceCloseConfirmationModal}
                  onOk={() => {
                    advanceCloseAssessment();
                  }}
                  onCancel={() => {
                    setAdvanceCloseConfirmationModal(false);
                  }}
                />
              ) : null}
            </div>
            {!!alerts &&
              alerts.map((i, k) => {
                return (
                  <Alert
                    message={i.message}
                    type={i.type}
                    style={{ width: "90%" }}
                  />
                );
              })}
            <FormContainer className={"FormContainer " + styles.container}>
              {handleContent()}
            </FormContainer>
          </div>
          <div className={styles.buttonsContainer}>
            <div id={"saveButton"}>
              <Button
                id={"saveButton"}
                isDisabled={disableSave}
                className={handleClass()}
                onClick={() => {
                  handleSaveOrder();
                  console.log(assessment);
                }}
              >
                Salvar
              </Button>
            </div>
            {answersCount == 0 ? (
              currentButton == "generalRecord" ? (
                <div styles={{ display: "none" }} />
              ) : (
                <div className={styles.skipButton}>
                  <CaretLeft
                    size={44}
                    onClick={() => {
                      if (currentButton == "header") {
                        setCurrentButton("generalRecord");
                      }
                      if (currentButton == "questionnaire") {
                        setCurrentButton("header");
                      }
                    }}
                  />{" "}
                </div>
              )
            ) : (
              <div styles={{ display: "none" }} />
            )}
            {answersCount == 0 ? (
              currentButton == "questionnaire" ? (
                <div styles={{ display: "none" }} />
              ) : (
                <div className={styles.skipButton}>
                  <CaretRight
                    size={44}
                    onClick={() => {
                      if (currentButton == "generalRecord") {
                        setCurrentButton("header");
                      }
                      if (currentButton == "header") {
                        setCurrentButton("questionnaire");
                      }
                    }}
                  />
                </div>
              )
            ) : (
              <div styles={{ display: "none" }} />
            )}
            <div style={{ marginLeft: "auto" }} id={"cancelButton"}>
              <Button
                id={"cancelButton"}
                className={styles.cancelButton + " " + "secondaryOrange"}
                onClick={() => {
                  setClosingConfirmationModal(true);
                }}
              >
                Cancelar
              </Button>
            </div>
          </div>
        </>
      )}
      {isBackPopupOpenned == true ? (
        <>
          <div className={styles.newItemPopUp}>
            <div className={styles.header}>
              Para voltar o status da avaliação é necessário alterar a data do
              programa:
            </div>
            <div className={styles.popupRow}>
              <div className={styles.initialDatePopup}>
                <div>
                  <span>
                    Data do Programa<span style={{ color: "red" }}>*</span>
                  </span>
                  <Field
                    defaultValue={assessment.meta.opening}
                    type={"datetime-local"}
                    id={"initialDate"}
                    disabled={disableInitialDate}
                    onChange={(event) => {
                      var initialDate = new Date(event.target.value);
                      initialDate.setHours(0, 0, 0, 0);
                      var finalDate = new Date();
                      finalDate.setDate(initialDate.getDate() + 1);                      
                      finalDate = StartDateToDb(finalDate);

                      let _assessment = { ...assessment };
                      _assessment.meta.closing = finalDate;
                      setClosingDate(finalDate);
                      _assessment.meta.opening = event.target.value;

                      setAssessment(_assessment);
                    }}
                  ></Field>
                </div>
                <Pencil
                  size={28}
                  className={styles.editIcon}
                  onClick={() => setDisableInitialDate(false)}
                ></Pencil>
              </div>

              <div>
                <span>
                  Fim da Coleta<span style={{ color: "red" }}>*</span>
                </span>
                <Field
                  defaultValue={closingDate}
                  value={closingDate}
                  type={"datetime-local"}
                  id={"finalDate"}
                  onChange={(event) => {
                    setClosingDate(event.target.value);
                    let _assessment = { ...assessment };
                    let dt = StartDateToDb(new Date(event.target.value));
                    _assessment.meta.closing = dt;
                    setAssessment(_assessment);
                    setDisableSavePopup(false);
                  }}
                ></Field>
              </div>
            </div>
            <div className={styles.popupFooter}>
              <Button
                isDisabled={disableSavePopup}
                className={handleClassPopup()}
                onClick={() => {
                  handleBackToReadyStatus();
                }}
              >
                Salvar
              </Button>
              <Button
                className={styles.tabsButtonFooter + " " + "secondaryOrange"}
                onClick={() => {
                  setIsBackPopupOpenned(false);
                }}
              >
                Cancelar
              </Button>
            </div>
          </div>
          <div className={styles.overlay} onClick={() => controlPopUp()}></div>
        </>
      ) : (
        <div className={styles.overlayOff}></div>
      )}
    </Layout>
  );
};

export default EditAssessment;

export const GeneralRecord = ({
  assessment,
  setAssessment,
  usersList,
  areaList,
  setPt,
  setEn,
  setEs,
  pt,
  en,
  es,
  assetList,
  orderDefaultUserAccess,
  setOrderDefaultUserAccess,
}) => {
  const [disableFinalDate, setDisableFinalDate] = useState(true);
  const [closingDate, setClosingDate] = useState("");
  const [clientList, setClientList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [programList, setProgramList] = useState([]);

  const handleSchoolChange = (selectedOption) => {
    let _assessment = { ...assessment };
    _assessment.meta.school = selectedOption.value;
    _assessment.meta.area = null;
    _assessment.meta.client = null;
    _assessment.meta.program = null;

    let findSchool = schoolList.find(
      (school) => school.value === selectedOption.value
    );

    setOrderDefaultUserAccess(
      checkUserAccessBasedOnParameters(findSchool, "school")
    );
    setAssessment(_assessment);
  };

  const sortedSchoolList = schoolList.sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  const handleAreaChange = (selectedOption) => {
    let _assessment = { ...assessment };
    _assessment.meta.area = selectedOption.value;
    _assessment.meta.client = null;
    _assessment.meta.program = null;

    let findArea = areaList.find((area) => area.value === selectedOption.value);

    setOrderDefaultUserAccess(
      checkUserAccessBasedOnParameters(findArea, "area")
    );
    setAssessment(_assessment);
  };

  const sortedAreaList = filterAreas(assessment.meta.school).sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  const handleClientChange = (selectedOption) => {
    let _assessment = { ...assessment };
    _assessment.meta.client = selectedOption.value;
    _assessment.meta.program = null;

    let findClient = clientList.find(
      (client) => client.value === selectedOption.value
    );

    setOrderDefaultUserAccess(
      checkUserAccessBasedOnParameters(findClient, "client")
    );
    setAssessment(_assessment);
  };

  const sortedClientList = clientList.sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  const handleProgramChange = (selectedOption) => {
    let _assessment = { ...assessment };
    let findProgram = programList.find(
      (program) => program.value === selectedOption.value
    );
    _assessment.meta.program = selectedOption.value;
    _assessment.header.program = findProgram.label;

    setOrderDefaultUserAccess(
      checkUserAccessBasedOnParameters(findProgram, "program")
    );
    setAssessment(_assessment);
  };
  const sortedProgramList = filteredPrograms(
    assessment.meta.school,
    assessment.meta.area
  ).sort((a, b) => a.label.localeCompare(b.label));

  const getClientList = async () => {
    let dataClient = await getParameterItems("company");
    if (dataClient && Array.isArray(dataClient)) {
      let _clientList = [];
      dataClient.map((client) => {
        let clientitem = { value: client.sk, label: client.title };
  
        if ("accessGranted" in client && client.accessGranted) {
          clientitem.accessGranted = client.accessGranted;
        }
  
        _clientList.push(clientitem);
      });
      setClientList(_clientList);
  
      return _clientList;
    }
  };

  const getSchoolList = async () => {
    let dataSchool = await getParameterItems("school");
    if(dataSchool  && Array.isArray(dataSchool)) {
      let _schoolList = [];
      dataSchool.map((school) => {
        let schoolItem = { value: school.sk, label: school.title };
  
        if ("accessGranted" in school && school.accessGranted) {
          schoolItem.accessGranted = school.accessGranted;
        }
  
        _schoolList.push(schoolItem);
      });
  
      setSchoolList(_schoolList);
  
      return _schoolList;
    }  
  };

  const getProgramList = async () => {
    let dataProgram = await getParameterItems("program");
    if(dataProgram && Array.isArray(dataProgram)) {
      let _programList = [];
      dataProgram.map((program) => {
        let programSk = program.sk.split("#")[2];
  
        let programItem = {
          value: programSk,
          label: program.title,
          hierarchy: program.sk,
        };
  
        if ("accessGranted" in program && program.accessGranted) {
          programItem.accessGranted = program.accessGranted;
        }
  
        _programList.push(programItem);
      });
      setProgramList(_programList);
  
      return _programList;
    }
  };

  const getColorList = async () => {
    let dataColor = await getAssetsItems("color");
    if (dataColor && Array.isArray(dataColor)) {
      let _colorList = [];
      dataColor.map((color) => {
        _colorList.push({
          value: color.content,
          label: color.title,
          id: color.id,
        });
      });
      setColorList(_colorList);
    }
  };

  const checkUserAccessBasedOnParameters = (newParameter, parameterType) => {
    let userAccess = [];
    // check if the data exists and if is an array of objects or something that is a array
    if (newParameter && Array.isArray(newParameter.accessGranted)) { 
      if (newParameter.accessGranted) {
        newParameter.accessGranted.map((user) => {
          //get user.userId and find it in usersList to get the label
          let findUser = usersList.find((userList) => {
            //console.log(userList);
            return userList.value == user.userId;
          });

          // before adding user to userAccess, checks if it is already there
          let userExists = userAccess.find((userWithAccess) => {
            return userWithAccess.value == user.userId;
          });
          console.log(userExists);
          console.log(user);
          if (!userExists) {
            userAccess.push({
              value: user.userId,
              name: findUser.name,
              photo: findUser.photo,
              label: findUser.label,
            });
          }
        });
      }

      if (
        parameterType != "school" &&
        schoolList.length > 0 &&
        assessment.meta.school != null
      ) {
      let School = assessment.meta.school;

      // find School in school list comparing the value of School with school.value
      let findSchool = schoolList.find((school) => {
        return school.value == School;
      });

      if (findSchool.accessGranted) {
        findSchool.accessGranted.map((user) => {
          //get user.userId and find it in usersList to get the label
          let findUser = usersList.find((userList) => {
            console.log("checking if user exists");
            console.log("user", user);
            console.log("userList", userList);
            return userList.value == user.userId;
          });

          // before adding user to userAccess, checks if it is already there
          let userExists = userAccess.find((userWithAccess) => {
            return userWithAccess.value == user.userId;
          });

          if (!userExists) {
            userAccess.push({
              value: user.userId,
              name: findUser.name,
              photo: findUser.photo,
              label: findUser.label,
            });
          }
        });
      }
      }

      if (
        parameterType != "area" &&
        areaList.length > 0 &&
        assessment.meta.area != null
      ) {
      let Area = assessment.meta.area;
      console.log("Area", Area);
      // find Area in area list comparing the value of Area with area.value
      let findArea = areaList.find((area) => {
        return area.value == Area;
      });
      console.log(findArea);
      if ("accessGranted" in findArea) {
        findArea.accessGranted.map((user) => {
          //get user.userId and find it in usersList to get the label
          let findUser = usersList.find((userList) => {
            console.log("checking if user exists");
            console.log("user", user);
            console.log("userList", userList);
            return userList.value == user.userId;
          });

          // before adding user to userAccess, checks if it is already there
          let userExists = userAccess.find((userWithAccess) => {
            return userWithAccess.value == user.userId;
          });

          if (!userExists) {
            userAccess.push({
              value: user.userId,
              name: findUser.name,
              photo: findUser.photo,
              label: findUser.label,
            });
          }
        });
      }
      }

      if (
        parameterType != "client" &&
        clientList.length > 0 &&
        assessment.meta.client != null
      ) {
      let Client = assessment.meta.client;

      // find Client in client list comparing the value of Client with client.value
      let findClient = clientList.find((client) => {
        return client.value == Client;
      });

      console.log("Client", findClient);

      if (findClient.accessGranted) {
        findClient.accessGranted.map((user) => {
          //get user.userId and find it in usersList to get the label
          let findUser = usersList.find((userList) => {
            return userList.value == user.userId;
          });

          // before adding user to userAccess, checks if it is already there
          let userExists = userAccess.find((userWithAccess) => {
            return userWithAccess.value == user.userId;
          });

          if (!userExists) {
            userAccess.push({
              value: user.userId,
              name: findUser.name,
              photo: findUser.photo,
              label: findUser.label,
            });
          }
        });
      }
      }

      if (
        parameterType != "program" &&
        programList.length > 0 &&
        assessment.meta.program != null
      ) {
      let Program = assessment.meta.program;

      // find Program in program list comparing the value of Program with program.value
      let findProgram = programList.find((program) => {
        return program.value == Program;
      });

      if (findProgram.accessGranted) {
        findProgram.accessGranted.map((user) => {
          //get user.userId and find it in usersList to get the label
          let findUser = usersList.find((userList) => {
            console.log("checking if user exists");
            console.log("user", user);
            console.log("userList", userList);
            return userList.value == user.userId;
          });

          // before adding user to userAccess, checks if it is already there
          let userExists = userAccess.find((userWithAccess) => {
            return userWithAccess.value == user.userId;
          });

          if (!userExists) {
            userAccess.push({
              value: user.userId,
              name: findUser.name,
              photo: findUser.photo,
              label: findUser.label,
            });
          }
        });
      }
      }
    }
    return userAccess;
  };

  useEffect(() => {
    getColorList();
    getClientList();

    getSchoolList().then((result) => {
      console.log("schoolList", result);

      let findSchool = result.find((school) => {
        return school.value == assessment.meta.school;
      });

      console.log("findSchool", findSchool);
      if (findSchool != null) {
        //console.log("checking user access when calling getSchoolList", assessment.meta.school)
        setOrderDefaultUserAccess(
          checkUserAccessBasedOnParameters(findSchool, "school")
        );
      }
    });

    getProgramList().then((result) => {
      console.log("programList", result);

      let findProgram = result.find((program) => {
        return program.value == assessment.meta.program;
      });

      console.log("findProgram", findProgram);
      if (findProgram != null) {
        //console.log("checking user access when calling getProgramList", assessment.meta.program)
        setOrderDefaultUserAccess(
          checkUserAccessBasedOnParameters(findProgram, "program")
        );
      }
    });

    setClosingDate(assessment.meta.closing);
  }, []);

  const languageList = [
    { value: "pt", label: "Português" },
    { value: "en", label: "Inglês" },
    { value: "es", label: "Espanhol" },
  ];

  const selectUsersOrderSquad = (selectedUsers) => {
    let users = selectedUsers.map((user, index) => {
      return user.value;
    });

    let _assessment = { ...assessment };
    _assessment.orderSquad = users;
    setAssessment(_assessment);
  };

  const selectUsersOutputAccess = (selectedUsers) => {
    let users = selectedUsers.map((user, index) => {
      return user.value;
    });

    console.log(users);

    let _assessment = { ...assessment };
    _assessment.outputAccess = users;
    setAssessment(_assessment);
  };

  const customStyles = {
    control: (styles) => ({ ...styles, minHeight: "32px" }),
    input: (styles) => ({ ...styles, height: "30px" }),
    indicatorsContainer: (styles) => ({ ...styles, height: "30px" }),
    valueContainer: (styles) => ({
      ...styles,
      minHeight: "30px",
      padding: "0 10px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
  };

  const setDefaultLanguage = () => {
    let languages = [];
    if (assessment.languages.pt == true) {
      languages.push({ value: "pt", label: "Português" });
    } else if (assessment.languages.en == true) {
      languages.push({ value: "en", label: "Inglês" });
    } else if (assessment.languages.es == "es") {
      languages.push({ value: "es", label: "Espanhol" });
    }

    return languages;
  };

  const setDefaultOrderSquad = () => {
    let orderSquadList = [];
    assessment.orderSquad.map((userOrderSquad) => {
      usersList.find((user) => {
        if (user.value == userOrderSquad) {
          orderSquadList.push({ value: user.value, label: user.label });
        }
      });
    });

    return orderSquadList;
  };

  const setDefaulOutputAccess = () => {
    let outputAccessList = [];
    assessment.outputAccess.map((userOutputAccess) => {
      usersList.find((user) => {
        if (user.value == userOutputAccess) {
          outputAccessList.push({ value: user.value, label: user.label });
        }
      });
    });

    return outputAccessList;
  };

  function usersNotInOrderDefaultUserAccess() {
    let usersNotInOrderDefaultUserAccess = usersList.filter((user) => {
      return !orderDefaultUserAccess.some(
        (userAccess) => userAccess.value == user.value
      );
    });

    return usersNotInOrderDefaultUserAccess;
  }

  function filterAreas(school) {
    let filteredAreas = [];

    areaList.map((area) => {
      console.log(area);
      let areaSchool = area.hierarchy.split("#")[0];
      console.log(`areaSchool: ${areaSchool} school: ${school}`);
      if (areaSchool == school) {
        filteredAreas.push(area);
      }
    });

    return filteredAreas;
  }

  function filteredPrograms(school, area) {
    let filteredPrograms = [];

    programList.map((program) => {
      //console.log(program);
      let programSchool = program.hierarchy.split("#")[0];
      let programArea = program.hierarchy.split("#")[1];
      //console.log(`programArea: ${programArea} area: ${area}`);
      if (programSchool == school && programArea == area) {
        filteredPrograms.push(program);
      }
    });

    return filteredPrograms;
  }

  return (
    <div className={styles.recordContainer}>
      <div className={styles.recordTitle}>
        <span>
          Título<span style={{ color: "red" }}>*</span>
        </span>
        <Field
          defaultValue={assessment.title}
          placeholder={"Digite o título desejado..."}
          id={"title"}
          onChange={(event) => {
            let _assessment = { ...assessment };
            _assessment.title = event.target.value;
            setAssessment(_assessment);
          }}
        ></Field>
      </div>
      <div className={styles.school}>
        <span>
          Escola<span style={{ color: "red" }}>*</span>
        </span>
        <Select
          value={sortedSchoolList.find(
            (school) => school.value === assessment.meta.school
          )}
          options={sortedSchoolList}
          placeholder="Selecione..."
          className={styles.searchField}
          onChange={handleSchoolChange}
          styles={customStyles}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
        />
      </div>
      <div className={styles.area}>
        {assessment.meta.school != null && (
          <div>
            <span>
              Área<span style={{ color: "red" }}>*</span>
            </span>
            <Select
              value={sortedAreaList.find(
                (area) => area.value === assessment.meta.area
              )}
              options={sortedAreaList}
              placeholder="Selecione..."
              className={styles.searchField}
              onChange={handleAreaChange}
              styles={customStyles}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </div>
        )}
      </div>
      <div className={styles.client}>
        {assessment.meta.area != null && (
          <div>
            <span>
              Cliente<span style={{ color: "red" }}>*</span>
            </span>
            <Select
              value={sortedClientList.find(
                (client) => client.value === assessment.meta.client
              )}
              options={sortedClientList}
              placeholder="Selecione..."
              className={styles.searchField}
              onChange={handleClientChange}
              styles={customStyles}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </div>
        )}
      </div>
      <div className={styles.program}>
        {assessment.meta.client != null && (
          <div>
            <span>
              Programa<span style={{ color: "red" }}>*</span>
            </span>
            <Select
              value={sortedProgramList.find(
                (program) => program.value === assessment.meta.program
              )}
              options={sortedProgramList}
              placeholder="Selecione..."
              className={styles.searchField}
              onChange={handleProgramChange}
              styles={customStyles}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              title={assessment.meta.program}
            />
          </div>
        )}
      </div>
      <div className={styles.orderClass}>
        <span>Turma</span>
        <Field
          defaultValue={assessment.orderClass}
          id={"class"}
          onChange={(event) => {
            let _assessment = { ...assessment };
            _assessment.orderClass = event.target.value;
            setAssessment(_assessment);
          }}
        ></Field>
      </div>
      <div className={styles.pptFooter}>
        <span>Power Point</span>
        <div className={styles.pptFooterChooseImageField}>
          <DropdownListField
            defaultValue={assessment.powerPointFooter}
            placeholder={"Selecione..."}
            id={"powerPointFooter"}
            listOfItems={assetList}
            style={{ width: "100%" }}
            onChange={(event) => {
              let _assessment = { ...assessment };
              let findAsset = assetList.find((asset) => {
                return asset.value == event;
              });

              console.log("findAsset", findAsset);
              _assessment.powerPoint = findAsset;
              setAssessment(_assessment);
            }}
          ></DropdownListField>
        </div>
      </div>
      {/* color will not be available */}
      {/* 
            <div className={styles.color}> 
              <span>
                Cor<span style={{ color: "red" }}>*</span>
              </span>
              <SelectColorField
                colorList={colorList}
                item={assessment}
                setItem={setAssessment}
              ></SelectColorField>
            </div>
        */}
      <div className={styles.language}>
        <span>
          Idioma<span style={{ color: "red" }}>*</span>
        </span>
        <div style={{ marginTop: "6px" }}>
          <div className={styles.languageButton}>
            {pt == true ? (
              <img
                src={brazilIcon}
                onClick={() => {
                  if (es === true || en === true) {
                    setPt(false);
                    let _assessment = { ...assessment };
                    let languages = _assessment.languages;

                    // check if languages is a dictionary
                    if (typeof _assessment.languages === "string") {
                      languages = { pt: false, en: false, es: false };
                    } else {
                      languages.pt = false;
                    }
                    console.log("languages", languages);
                    _assessment.languages = languages;
                    setAssessment(_assessment);
                  }
                }}
              />
            ) : (
              <img
                src={brazilIcon}
                className={styles.disableFlag}
                onClick={() => {
                  setPt(true);
                  let _assessment = { ...assessment };
                  let languages = _assessment.languages;

                  // check if languages is a dictionary
                  if (typeof _assessment.languages === "string") {
                    languages = { pt: true, en: false, es: false };
                  } else {
                    languages.pt = true;
                  }
                  console.log("languages", languages);
                  _assessment.languages = languages;
                  setAssessment(_assessment);
                }}
              />
            )}
          </div>

          <div className={styles.languageButton}>
            {es == true ? (
              <img
                src={spainIcon}
                onClick={() => {
                  if (pt === true || en === true) {
                    setEs(false);
                    let _assessment = { ...assessment };
                    let languages = _assessment.languages;

                    // check if languages is a dictionary
                    if (typeof _assessment.languages === "string") {
                      languages = { pt: false, en: false, es: false };
                    } else {
                      languages.es = false;
                    }
                    console.log("languages", languages);
                    _assessment.languages = languages;
                    setAssessment(_assessment);
                  }
                }}
              />
            ) : (
              <img
                src={spainIcon}
                className={styles.disableFlag}
                onClick={() => {
                  setEs(true);
                  let _assessment = { ...assessment };
                  let languages = _assessment.languages;

                  // check if languages is a dictionary
                  if (typeof _assessment.languages === "string") {
                    languages = { pt: false, en: false, es: true };
                  } else {
                    languages.es = true;
                  }
                  console.log("languages", languages);
                  _assessment.languages = languages;
                  setAssessment(_assessment);
                }}
              />
            )}
          </div>

          <div className={styles.languageButton}>
            {en == true ? (
              <img
                src={usIcon}
                onClick={() => {
                  if (es === true || pt === true) {
                    setEn(false);
                    let _assessment = { ...assessment };
                    let languages = _assessment.languages;

                    // check if languages is a dictionary
                    if (typeof _assessment.languages === "string") {
                      languages = { pt: false, en: false, es: false };
                    } else {
                      languages.en = false;
                    }
                    console.log("languages", languages);
                    _assessment.languages = languages;
                    setAssessment(_assessment);
                  }
                }}
              />
            ) : (
              <img
                src={usIcon}
                className={styles.disableFlag}
                onClick={() => {
                  setEn(true);
                  let _assessment = { ...assessment };
                  let languages = _assessment.languages;

                  // check if languages is a dictionary
                  if (typeof _assessment.languages === "string") {
                    languages = { pt: false, en: true, es: false };
                  } else {
                    languages.en = true;
                  }
                  console.log("languages", languages);
                  _assessment.languages = languages;
                  setAssessment(_assessment);
                }}
              />
            )}
          </div>
        </div>
      </div>
      {assessment.itemStatus != "closed" ? (
        <>
          <div className={styles.initialDate}>
            <span>
              Data do Programa<span style={{ color: "red" }}>*</span>
            </span>
            <Field
              defaultValue={assessment.meta.opening}
              type={"datetime-local"}
              id={"initialDate"}
              onChange={(event) => {
                var initialDate = new Date(event.target.value);
                initialDate.setHours(0, 0, 0, 0);                              
                var finalDate = new Date();
                finalDate.setDate(initialDate.getDate() + 1);                
                finalDate = StartDateToDb(finalDate);
                let _assessment = { ...assessment };
                _assessment.meta.closing = finalDate;
                setClosingDate(finalDate);
                _assessment.meta.opening = event.target.value;
                setAssessment(_assessment);
              }}
            ></Field>
          </div>
          <div className={styles.finalDate}>
            <div>
              <span>
                Fim da Coleta<span style={{ color: "red" }}>*</span>
              </span>
              <Field
                defaultValue={closingDate}
                value={closingDate}
                disabled={disableFinalDate}
                type={"datetime-local"}
                id={"finalDate"}
                onChange={(event) => {
                  setClosingDate(event.target.value);
                  let _assessment = { ...assessment };
                  _assessment.meta.closing = event.target.value;
                  setAssessment(_assessment);
                }}
              ></Field>
            </div>
            <Pencil
              size={28}
              className={styles.editIcon}
              onClick={() => setDisableFinalDate(false)}
            ></Pencil>
          </div>
        </>
      ) : (
        <>
          <div className={styles.initialDate}>
            <span>
              Data do Programa<span style={{ color: "red" }}>*</span>
            </span>
            <Field
              disabled={true}
              defaultValue={assessment.meta.opening}
              type={"datetime-local"}
              id={"initialDate"}
              onChange={(event) => {                
                var initialDate = new Date(event.target.value);
                initialDate.setHours(0, 0, 0, 0);
                var finalDate = new Date();
                finalDate.setDate(initialDate.getDate() + 1);                
                finalDate = StartDateToDb(finalDate);
                let _assessment = { ...assessment };
                _assessment.meta.closing = finalDate;
                setClosingDate(finalDate);
                _assessment.meta.opening = event.target.value;
                setAssessment(_assessment);
              }}
            ></Field>
          </div>
          <div className={styles.finalDate}>
            <div>
              <span>
                Fim da Coleta<span style={{ color: "red" }}>*</span>
              </span>
              <Field
                defaultValue={closingDate}
                value={closingDate}
                disabled={true}
                type={"datetime-local"}
                id={"finalDate"}
                onChange={(event) => {
                  setClosingDate(event.target.value);
                  let _assessment = { ...assessment };
                  _assessment.meta.closing = event.target.value;
                  setAssessment(_assessment);
                }}
              ></Field>
            </div>
          </div>
        </>
      )}

      <div className={styles.responsiblesUsers}>
        <span>
          Responsáveis<span style={{ color: "red" }}>*</span>
        </span>
        <Select
          value={setDefaultOrderSquad()}
          options={usersList}
          isMulti
          placeholder="Selecione os usuários"
          className={styles.selectUsers}
          onChange={(event) => selectUsersOrderSquad(event)}
          styles={customStyles}
        />
      </div>
      <div className={styles.usersWithAccess}>
        <span>Usuários com acesso ao relatório</span>
        <div className={styles.usersWithAccessList}>
          {(orderDefaultUserAccess.length > 0) ? (orderDefaultUserAccess.map((user) => (
            <span>
              {user.label}
              {orderDefaultUserAccess.indexOf(user) ==
              orderDefaultUserAccess.length - 1
                ? ""
                : ", "}
            </span>
          ))) : null}
        </div>
        <Select
          value={setDefaulOutputAccess()}
          options={usersNotInOrderDefaultUserAccess()}
          isMulti
          placeholder="Adicione mais usuários"
          className={styles.selectUsers}
          onChange={(event) => selectUsersOutputAccess(event)}
          styles={customStyles}
        />
      </div>
    </div>
  );
};

export const Header = ({
  assessment,
  setAssessment,
  logo1,
  setLogo1,
  logo2,
  setLogo2,
  logo3,
  setLogo3,
  explanation,
  setExplanation,
  caption,
  setCaption,
  pt,
  setPt,
  en,
  setEn,
  es,
  setEs,
  Dataset_Image,
}) => {
  const [imageSelected, setImageSelected] = useState([
    { title: null, content: null },
  ]);
  const explanationDefaultContent = {
    pt: "A sua avaliação é muito importante para melhorarmos continuamente os programas que oferecemos. Agradecemos o seu esforço. Por favor, avalie cada um dos seguintes aspectos e faça seus comentários/sugestões no espaço apropriado.",
    en: "Your feedback is very important for us to continuously improve the programs we offer. We appreciate your effort. Please evaluate each of the following aspects and provide your comments/suggestions in the appropriate space.",
    es: "Su evaluación es muy importante para que podamos mejorar continuamente los programas que ofrecemos. Agradecemos su esfuerzo. Por favor, evalúe cada uno de los siguientes aspectos y haga sus comentarios/sugerencias en el espacio correspondiente.",
  };

  const captionDefaultContent = {
    pt: "Estrelas:\n1 = Baixo/Negativo/Discordo/Insatisfatório\n5 = Alto/Positivo/Concordo/Excelente",
    en: "Stars:\n1 = Low/Negative/Disagree/Unsatisfactory\n5 = High/Positive/Agree/Excellent",
    es: "Estrellas:\n1 = Bajo/Negativo/No estoy de acuerdo/Insatisfactorio\n5 = Alto/Positivo/Estoy de acuerdo/Excelente",
  };

  return (
    <div>
      <div className={MainStyle.row}>
        <div className={MainStyle.col_12}>
          <div className={[MainStyle.text_start, MainStyle.my_2].join(" ")}>
            <span className={[MainStyle.fs_8, MainStyle.fw_lighter].join(" ")}>
             Limite máximo de 100 caracteres por cabeçalho.
            </span>
          </div>
        </div>
      </div>
      <div className={styles.headerRow}>
        <span>Linha de Cabeçalho 1</span>
        <div>
          {pt === true && (
            <div className={styles.multiLanguageField}>
              <img src={brazilIcon} />
              <Field
                defaultValue={() =>
                  assessment.header.headerLine1.pt == null || undefined
                    ? "Avaliação de Qualidade"
                    : assessment.header.headerLine1.pt
                }
                placeholder={"Digite o conteúdo..."}
                className={styles.multiLanguageTextField}
                id={"title"}
                onChange={(event) => {
                  let _assessment = { ...assessment };
                  _assessment.header.headerLine1.pt = event.target.value;
                  setAssessment(_assessment);
                }}
                maxLength={Header_MaxChar}
              ></Field>
            </div>
          )}

          {es === true && (
            <div className={styles.multiLanguageField}>
              <img src={spainIcon} onClick={() => setEn(true)} />
              <Field
                defaultValue={() =>
                  assessment.header.headerLine1.es == null || undefined
                    ? "Evaluación de Calidad"
                    : assessment.header.headerLine1.es
                }
                placeholder={"Escriba el contenido..."}
                id={"title"}
                className={styles.multiLanguageTextField}
                onChange={(event) => {
                  let _assessment = { ...assessment };
                  _assessment.header.headerLine1.es = event.target.value;
                  setAssessment(_assessment);
                }}
                maxLength={Header_MaxChar}
              ></Field>
            </div>
          )}

          {en === true && (
            <div className={styles.multiLanguageField}>
              <img src={usIcon} onClick={() => setEn(true)} />
              <Field
                defaultValue={() =>
                  assessment.header.headerLine1.en == null || undefined
                    ? "Quality Assessment"
                    : assessment.header.headerLine1.en
                }
                placeholder={"Type the content..."}
                className={styles.multiLanguageTextField}
                id={"title"}
                onChange={(event) => {
                  let _assessment = { ...assessment };
                  _assessment.header.headerLine1.en = event.target.value;
                  setAssessment(_assessment);
                }}
                maxLength={Header_MaxChar}
              ></Field>
            </div>
          )}
        </div>
      </div>
      <div className={styles.headerRow}>
        <span>Linha de Cabeçalho 2</span>
        <div>
          {pt === true && (
            <div className={styles.multiLanguageField}>
              <img src={brazilIcon} onClick={() => setEn(true)} />
              <Field
                defaultValue={() =>
                  assessment.header.headerLine2.pt == null || undefined
                    ? "Programa"
                    : assessment.header.headerLine2.pt
                }
                placeholder={"Digite o conteúdo..."}
                className={styles.multiLanguageTextField}
                id={"program"}
                onChange={(event) => {
                  let _assessment = { ...assessment };
                  _assessment.header.headerLine2.pt = event.target.value;
                  setAssessment(_assessment);
                }}
                maxLength={Header_MaxChar}
              ></Field>
            </div>
          )}

          {es === true && (
            <div className={styles.multiLanguageField}>
              <img src={spainIcon} onClick={() => setEn(true)} />
              <Field
                defaultValue={() =>
                  assessment.header.headerLine2.es == null || undefined
                    ? "Programa"
                    : assessment.header.headerLine2.es
                }
                placeholder={"Escriba el contenido..."}
                className={styles.multiLanguageTextField}
                id={"program"}
                onChange={(event) => {
                  let _assessment = { ...assessment };
                  _assessment.header.headerLine2.es = event.target.value;
                  setAssessment(_assessment);
                }}
                maxLength={Header_MaxChar}
              ></Field>
            </div>
          )}

          {en === true && (
            <div className={styles.multiLanguageField}>
              <img src={usIcon} onClick={() => setEn(true)} />
              <Field
                defaultValue={() =>
                  assessment.header.headerLine2.en == null || undefined
                    ? "Program"
                    : assessment.header.headerLine2.en
                }
                placeholder={"Type the content..."}
                className={styles.multiLanguageTextField}
                id={"program"}
                onChange={(event) => {
                  let _assessment = { ...assessment };
                  _assessment.header.headerLine2.en = event.target.value;
                  setAssessment(_assessment);
                }}
                maxLength={Header_MaxChar}
              ></Field>
            </div>
          )}
        </div>
      </div>
      <div className={styles.headerRow}>
        <span>Linha de Cabeçalho 3</span>
        <div>
          {pt === true && (
            <div className={styles.multiLanguageField}>
              <img src={brazilIcon} onClick={() => setEn(true)} />
              <Field
                defaultValue={assessment.header.headerLine3.pt}
                placeholder={"Digite o conteúdo..."}
                className={styles.multiLanguageTextField}
                id={"programName"}
                onChange={(event) => {
                  let _assessment = { ...assessment };
                  _assessment.header.headerLine3.pt = event.target.value;
                  setAssessment(_assessment);
                }}
                maxLength={Header_MaxChar}
              ></Field>
            </div>
          )}

          {es === true && (
            <div className={styles.multiLanguageField}>
              <img src={spainIcon} onClick={() => setEn(true)} />
              <Field
                defaultValue={assessment.header.headerLine3.es}
                placeholder={"Escriba el contenido..."}
                className={styles.multiLanguageTextField}
                id={"programName"}
                onChange={(event) => {
                  let _assessment = { ...assessment };
                  _assessment.header.headerLine3.es = event.target.value;
                  setAssessment(_assessment);
                }}
                maxLength={Header_MaxChar}
              ></Field>
            </div>
          )}

          {en === true && (
            <div className={styles.multiLanguageField}>
              <img src={usIcon} onClick={() => setEn(true)} />
              <Field
                defaultValue={assessment.header.headerLine3.en}
                placeholder={"Type the content..."}
                className={styles.multiLanguageTextField}
                id={"programName"}
                onChange={(event) => {
                  let _assessment = { ...assessment };
                  _assessment.header.headerLine3.en = event.target.value;
                  setAssessment(_assessment);
                }}
                maxLength={Header_MaxChar}
              ></Field>
            </div>
          )}
        </div>
      </div>
      <div className={styles.headerRow}>
        <span>Linha de Cabeçalho 4</span>
        <div>
          {pt === true && (
            <div className={styles.multiLanguageField}>
              <img src={brazilIcon} onClick={() => setEn(true)} />
              <Field
                defaultValue={assessment.header.headerLine4.pt}
                placeholder={"Digite o conteúdo..."}
                className={styles.multiLanguageTextField}
                id={"programName"}
                onChange={(event) => {
                  let _assessment = { ...assessment };
                  _assessment.header.headerLine4.pt = event.target.value;
                  setAssessment(_assessment);
                }}
                maxLength={Header_MaxChar}
              ></Field>
            </div>
          )}

          {es === true && (
            <div className={styles.multiLanguageField}>
              <img src={spainIcon} onClick={() => setEn(true)} />
              <Field
                defaultValue={assessment.header.headerLine4.es}
                placeholder={"Escriba el contenido..."}
                className={styles.multiLanguageTextField}
                id={"programName"}
                onChange={(event) => {
                  let _assessment = { ...assessment };
                  _assessment.header.headerLine4.es = event.target.value;
                  setAssessment(_assessment);
                }}
                maxLength={Header_MaxChar}
              ></Field>
            </div>
          )}

          {en === true && (
            <div className={styles.multiLanguageField}>
              <img src={usIcon} onClick={() => setEn(true)} />
              <Field
                defaultValue={assessment.header.headerLine4.en}
                placeholder={"Type the content..."}
                className={styles.multiLanguageTextField}
                id={"localDate"}
                onChange={(event) => {
                  let _assessment = { ...assessment };
                  _assessment.header.headerLine4.en = event.target.value;
                  setAssessment(_assessment);
                }}
                maxLength={Header_MaxChar}
              ></Field>
            </div>
          )}
        </div>
      </div>
      <div className={styles.moreHeader}>
        <div className={styles.logoContainer}>
          <div>
            <span>Logo 1</span>
            <SelectImageField
              id={"logo1"}
              image={logo1}
              setImage={setLogo1}
              imageSelected={imageSelected}
              setImageSelected={setImageSelected}
              Dataset_Image={Dataset_Image}
            ></SelectImageField>
          </div>
          <div>
            <span>Logo 2</span>
            <SelectImageField
              id={"logo2"}
              image={logo2}
              setImage={setLogo2}
              imageSelected={imageSelected}
              setImageSelected={setImageSelected}
              onChange={setImageSelected}
              Dataset_Image={Dataset_Image}
            ></SelectImageField>
          </div>
          <div>
            <span>Logo 3</span>
            <SelectImageField
              id={"logo3"}
              image={logo3}
              setImage={setLogo3}
              imageSelected={imageSelected}
              setImageSelected={setImageSelected}
              onChange={setImageSelected}
              Dataset_Image={Dataset_Image}
            ></SelectImageField>
          </div>
        </div>
        <div className={[styles.obsRow, MainStyle.ms_5].join(" ")}>
          Observações:
          <div className={styles.obs}>
            <span>
              Explicação {explanation}
              <Checkbox
                checked={explanation}
                onClick={() => {
                  if (explanation === false) {
                    setExplanation(true);
                    assessment.header.headerLine5 = {
                      pt: null,
                      es: null,
                      en: null,
                    };
                    if (pt == true) {
                      assessment.header.headerLine5.pt =
                        explanationDefaultContent.pt;
                    }
                    if (es == true) {
                      assessment.header.headerLine5.es =
                        explanationDefaultContent.es;
                    }
                    if (en == true) {
                      assessment.header.headerLine5.en =
                        explanationDefaultContent.en;
                    }
                  } else {
                    setExplanation(false);
                    delete assessment.header.headerLine5;
                  }
                }}
              />
            </span>
            {explanation === true && (
              <div>
                {pt === true && (
                  <div className={styles.multiLanguageField}>
                    <img src={brazilIcon} onClick={() => setEn(true)} />
                    <TextArea
                      defaultValue={() =>
                        assessment.header.headerLine5.pt == null || undefined
                          ? explanationDefaultContent.pt
                          : assessment.header.headerLine5.pt
                      }
                      placeholder={"Digite o conteúdo..."}
                      className={styles.multiLanguageTextField}
                      id={"localDate"}
                      onChange={(event) => {
                        let _assessment = { ...assessment };
                        _assessment.header.headerLine5.pt = event.target.value;
                        setAssessment(_assessment);
                      }}
                      maxLength={500}
                    />
                  </div>
                )}

                {es === true && (
                  <div className={styles.multiLanguageField}>
                    <img src={spainIcon} onClick={() => setEn(true)} />
                    <TextArea
                      defaultValue={() =>
                        assessment.header.headerLine5.es == null || undefined
                          ? explanationDefaultContent.es
                          : assessment.header.headerLine5.es
                      }
                      placeholder={"Escriba el contenido..."}
                      className={styles.multiLanguageTextField}
                      id={"localDate"}
                      onChange={(event) => {
                        let _assessment = { ...assessment };
                        _assessment.header.headerLine5.es = event.target.value;
                        setAssessment(_assessment);
                      }}
                      maxLength={500}
                    />
                  </div>
                )}

                {en === true && (
                  <div className={styles.multiLanguageField}>
                    <img src={usIcon} onClick={() => setEn(true)} />
                    <TextArea
                      defaultValue={() =>
                        assessment.header.headerLine5.en == null || undefined
                          ? explanationDefaultContent.en
                          : assessment.header.headerLine5.en
                      }
                      className={styles.multiLanguageTextField}
                      placeholder={"Type the content..."}
                      id={"localDate"}
                      onChange={(event) => {
                        let _assessment = { ...assessment };
                        _assessment.header.headerLine5.en = event.target.value;
                        setAssessment(_assessment);
                      }}
                      maxLength={500}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={styles.obs}>
            <span>
              Legenda
              <Checkbox
                checked={caption}
                onClick={() => {
                  if (caption === false) {
                    setCaption(true);
                    assessment.header.surveyCaption = {
                      pt: null,
                      es: null,
                      en: null,
                    };
                    if (pt == true) {
                      assessment.header.surveyCaption.pt =
                        captionDefaultContent.pt;
                    }
                    if (es == true) {
                      assessment.header.surveyCaption.es =
                        captionDefaultContent.es;
                    }
                    if (en == true) {
                      assessment.header.surveyCaption.en =
                        captionDefaultContent.en;
                    }
                  } else {
                    setCaption(false);
                    delete assessment.header.surveyCaption;
                  }
                }}
              />
            </span>
            {caption === true && (
              <div>
                {pt === true && (
                  <div className={styles.multiLanguageField}>
                    <img src={brazilIcon} onClick={() => setEn(true)} />
                    <TextArea
                      defaultValue={
                        assessment.header.surveyCaption.pt == null || undefined
                          ? captionDefaultContent.pt
                          : assessment.header.surveyCaption.pt
                      }
                      placeholder={"Digite o conteúdo..."}
                      className={styles.multiLanguageTextField}
                      id={"localDate"}
                      onChange={(event) => {
                        let _assessment = { ...assessment };
                        _assessment.header.surveyCaption.pt =
                          event.target.value;
                        setAssessment(_assessment);
                      }}
                      maxLength={500}
                    />
                  </div>
                )}

                {es === true && (
                  <div className={styles.multiLanguageField}>
                    <img src={spainIcon} onClick={() => setEn(true)} />
                    <TextArea
                      defaultValue={
                        assessment.header.surveyCaption.es == null || undefined
                          ? captionDefaultContent.es
                          : assessment.header.surveyCaption.es
                      }
                      placeholder={"Escriba el contenido..."}
                      className={styles.multiLanguageTextField}
                      id={"localDate"}
                      onChange={(event) => {
                        let _assessment = { ...assessment };
                        _assessment.header.surveyCaption.es =
                          event.target.value;
                        setAssessment(_assessment);
                      }}
                      maxLength={500}
                    />
                  </div>
                )}

                {en === true && (
                  <div className={styles.multiLanguageField}>
                    <img src={usIcon} onClick={() => setEn(true)} />
                    <TextArea
                      defaultValue={
                        assessment.header.surveyCaption.en == null || undefined
                          ? captionDefaultContent.en
                          : assessment.header.surveyCaption.en
                      }
                      className={styles.multiLanguageTextField}
                      placeholder={"Type the content..."}
                      id={"localDate"}
                      onChange={(event) => {
                        let _assessment = { ...assessment };
                        _assessment.header.surveyCaption.en =
                          event.target.value;
                        setAssessment(_assessment);
                      }}
                      maxLength={500}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
