import Layout from "components/Layout/layout";
import React, { useState, useEffect } from "react";
import Button from "components/Button/button";
import mockList from "./users.mock.json";
import styles from "./usersList.module.scss";
import TableControl from "components/TableControl/tableControl";
import Pagination from "components/Pagination/pagination";
import ReactLoading from "react-loading";
import { PlusCircle, Pencil, Trash, ArrowsDownUp, Password } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import { deleteUser, deleteUsers, newPassword } from "./usersServices";
import Checkbox from "components/Checkbox/checkbox";
import { getAllUsers } from "./usersServices";
import Alert from "components/Alert/Alert";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import PageTitle from "components/PageTitle/pageTitle";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Loading from "components/Loading/loading";

const UsersList = () => {
  const [memResultList, setMemResultList] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [tablePagination, setTablePagination] = useState([]);
  const [slice, setSlice] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [selectedBulkAction, setSelectedBulkAction] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(true);

  const [listIsSorted, setListIsSorted] = useState(false);

  const [alerts, setAlerts] = useState([]);

  
  const [ascendingOrDescendingTitle, setAscendingOrDescendingTitle] = useState(null);
  const [isAscending, setIsAscending] = useState(true);
  const navigate = useNavigate();

  const actionsList = [{ value: "excluir", label: "excluir" }];

  useEffect(() => {
    const fetchData = async () => {
      if (resultList.length === 0) {
        try {
          const allUsers = await getAllUsers(setResultList, setLoading);
          console.log(allUsers); // Log the resolved value directly
          setMemResultList(allUsers);
        } catch (error) {
          // Handle error as needed
          console.log(error);
        }
      }
    };

    if(capabilities.includes("cadastros")){ 
      fetchData();
    }else{
      setLoading(false);
    }
  }, [resultList]);

  function handleSelectUser(id) {
    let _selectedCheckboxes = selectedCheckboxes;
    if (_selectedCheckboxes[id] == true) {
      _selectedCheckboxes[id] = false;
    } else {
      _selectedCheckboxes[id] = true;
    }
    setSelectedCheckboxes(_selectedCheckboxes);
    console.log(_selectedCheckboxes);
  }

  function handleSelectAllUsers() {
    let _selectedCheckboxes = selectedCheckboxes;
    if (selectAll == false) {
      resultList.map((i, k) => {
        _selectedCheckboxes[i.id] = true;
      });
      console.log(_selectedCheckboxes);
      setSelectAll(true);
      setSelectedCheckboxes(_selectedCheckboxes);
    } else {
      resultList.map((i, k) => {
        _selectedCheckboxes[i.id] = false;
      });
      console.log(_selectedCheckboxes);
      setSelectAll(false);
      setSelectedCheckboxes(_selectedCheckboxes);
    }
  }

  const handleRenewPassword = async (id, email, name, lastName) => {
      try {
          let response = await newPassword(id, email);
          
          if (response.statusCode === 200) {
            let message = `Senha enviada com sucesso para ${name} ${lastName}!`
              addAlert(message, "success");
          } else {
              addAlert("Problema ao enviar nova senha", "danger");
          }

          setClosingConfirmationModal(false);
      } catch (error) {
          console.error("Error resetting password:", error);
          addAlert("Problema ao enviar nova senha", "danger");
      }
  };

  function handleDeleteUser(id) {
    let _resultList = [...resultList];
    let objectToDelete;
    _resultList.map((i, k) => {
      if (i.id == id) {
        objectToDelete = k;
        deleteUser(id);
        addAlert(i.first_name + " foi deletado com sucesso!", "success");
      }
    });

    let _memResultList = [...memResultList];
    let objectToDeleteInMemList;
    _memResultList.map((i, k) => {
      if (i.id == id) {
        objectToDeleteInMemList = k;
      }
    });

    _resultList.splice(objectToDelete, 1);
    _memResultList.splice(objectToDeleteInMemList, 1);
    setResultList(_resultList);
    setMemResultList(_memResultList);
    return _resultList;
  }

    function sortByParam() {
    let sortedList = [...resultList];
  
    sortedList.sort((a, b) => {
      const nameA = a.first_name ? a.first_name : "";
      const nameB = b.first_name ? b.first_name : "";
      if (isAscending) {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });
  
    setIsAscending(!isAscending);
  
    setResultList(sortedList);
    return sortedList;
  }

  function addAlert(message, type) {
    let _alerts = [...alerts];
    _alerts.push({
      message: message,
      type: type,
    });
    console.log(message);
    setAlerts(_alerts);
  }

  function performSelectedBulkAction() {
    let usersSelected = [];
    resultList.map((i) => {
      if (selectedCheckboxes[i.id] == true) {
        usersSelected.push(i.id);
      }
    });
    if (selectedBulkAction == "excluir") {
      console.log("excluindo...");
      console.log(usersSelected);
      deleteUsers(usersSelected);

      let _resultList = [...resultList];
      let _memResultList = [...memResultList];

      _resultList.map((i, k) => {
        if (usersSelected.includes(i.id)) {
          _resultList.splice(k, 1);
          console.log(i.first_name + " deleted");
        }
      });

      addAlert("Usuários deletados com sucesso!", "success");

      _memResultList.map((i, k) => {
        if (usersSelected.includes(i.id)) {
          _memResultList.splice(k, 1);
        }
      });

      setResultList(_resultList);
      setMemResultList(_memResultList);
    }
  }

  const { user } = useAuthenticator((context) => [context.user]);

  const capabilities = localStorage.getItem("capabilities#" + user.username);

  const role = localStorage.getItem("role#" + user.username);

  const [closingConfirmationModal, setClosingConfirmationModal] =
    useState(false);

  const [isRenewPasswordModalOpen, setIsRenewPasswordModalOpen] = useState(false); 
    

  const [userToDelete, setUserToDelete] = useState({
    id: null,
    first_name: null,
    last_name: null,
    email: null,
    role: null,
  });

  const [userToRenewPassword, setUserToRenewPassword] = useState({
    id: null,
    first_name: null,
    last_name: null,
    email: null,
    role: null,
  });

  let content;

  switch (true) {
    case loading:
      content = (
        <div className={styles.loading}>
          <div className="large">Carregando informações</div>
          <center>
            <ReactLoading
              type="bubbles"
              color="#0064a2"
              height={50}
              width={175}
            />
          </center>
        </div>
        // <Loading />
      );
      break;
    case capabilities.includes("cadastros"):
      content = (
        <div>
          <TableControl
            setSelectedBulkAction={setSelectedBulkAction}
            performBulkAction={performSelectedBulkAction}
            resultList={resultList}
            setResultList={setResultList}
            originalListTable={memResultList}
            actionsList={actionsList}
            setTablePagination={setTablePagination}
            setSlice={setSlice}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            showByPageDefault={10}
            searchPlaceholder={"Buscar Usuário"}
            filterableParameters={[
              {
                parameter: "role",
                displayName: "Função",
                dataType: "string",
              },
            ]}
            title={"Deseja excluir os usuários selecionados?"}
          ></TableControl>
          <Pagination
            tablePagination={tablePagination}
            slice={slice}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          ></Pagination>
          <div>
            <table className={styles.table}>
              <thead>
                <tr style={{ backgroundColor: "white" }}>
                  <th style={{ width: "40vw" }}>
                    <input
                      type="checkbox"
                      onChange={() => handleSelectAllUsers()}
                    ></input>
                  </th>
                  <th style={{ width: "15%" }}>
                    Nome
                    <ArrowsDownUp
                      onClick={() => sortByParam("first_name")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "15%" }}>
                    Sobrenome
                    <ArrowsDownUp
                      onClick={() => sortByParam("last_name")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "15%" }}>Foto</th>
                  <th style={{ width: "25%" }}>
                    Email
                    <ArrowsDownUp
                      onClick={() => sortByParam("email")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "15%" }}>
                    Função
                    <ArrowsDownUp
                      onClick={() => sortByParam("role")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "20%" }}>Ações</th>
                </tr>
              </thead>
              <tbody>
                {slice.map((item, i) => {
                  let id = item.id;
                  return (
                    <tr key={i}>
                      <td>
                        {selectedCheckboxes[id] == true ? (
                          <input
                            type="checkbox"
                            onChange={() => {
                              handleSelectUser(id);
                            }}
                            checked
                          ></input>
                        ) : (
                          <input
                            type="checkbox"
                            onChange={() => {
                              handleSelectUser(id);
                            }}
                          ></input>
                        )}
                      </td>
                      <td>{item.first_name}</td>
                      <td>{item.last_name}</td>
                      <td>
                        <img
                          className={styles.userPhoto}
                          src={item.photo}
                          alt={`${item.first_name} ${item.last_name}`}
                        />
                      </td>
                      <td>{item.email}</td>
                      <td>{item.role}</td>
                      <td>
                        <Pencil
                          className={styles.editUserButton}
                          onClick={() =>
                            window.open("/user/" + item.id, "_blank").focus()
                          }
                          size={32}
                        />
                        <ConfirmationModal
                          onClick={() => {
                            setIsRenewPasswordModalOpen(true);
                            setUserToRenewPassword(item);
                          }}
                          title={
                            "Deseja enviar uma nova senha para " +
                            userToRenewPassword.first_name +
                            " " +
                            userToRenewPassword.last_name +
                            " ?"
                          }
                          open={isRenewPasswordModalOpen}
                          onOk={() => {
                            //console.log(userToDelete.id);
                            handleRenewPassword(
                              userToRenewPassword.id,
                              userToRenewPassword.email,
                              userToRenewPassword.first_name,
                              userToRenewPassword.last_name
                            );

                            setIsRenewPasswordModalOpen(false);
                          }}
                          onCancel={() => setIsRenewPasswordModalOpen(false)}
                          iconComponent={
                            <Password
                              className={styles.newPassword}
                              size={32}
                            />
                          }
                          maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
                        />
                        {role == "Administrador" && (
                          <ConfirmationModal
                            onClick={() => {
                              setClosingConfirmationModal(true);
                              setUserToDelete(item);
                            }}
                            title={
                              "Deseja deletar o usuário de " +
                              userToDelete.first_name +
                              " " +
                              userToDelete.last_name +
                              " ?"
                            }
                            open={closingConfirmationModal}
                            onOk={() => {
                              console.log(userToDelete.id);
                              handleDeleteUser(userToDelete.id);
                              setClosingConfirmationModal(false);
                            }}
                            onCancel={() => setClosingConfirmationModal(false)}
                            iconComponent={
                              <Trash
                                className={styles.deleteUserButton}
                                size={32}
                              />
                            }
                            maskStyle={{
                              backgroundColor: "rgba(0, 0, 0, 0.05)",
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              tablePagination={tablePagination}
              slice={slice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></Pagination>
          </div>
        </div>
      );
      break;
    case capabilities.includes("cadastros") == false:
      content = (
        <div className={styles.error}>
          <center><Alert type={"danger"} message={"Você não tem permissão para acessar esta página"} /></center>
        </div>
      );
      break;
    default:
      content = <div>Houve um erro ao carregar a página</div>;
  }

  return (
    <Layout>
      <Button
        onClick={() => navigate("/user/new")}
        className={styles.createUserButton + " " + "primaryOrange"}
      >
        <PlusCircle size={20} style={{ top: 4.5, position: "relative" }} />
        <span style={{ marginLeft: 5 }}>Criar usuário</span>
      </Button>
      <div className={styles.pageHeader}>
        <PageTitle center={true}>Cadastro de Usuários</PageTitle>
      </div>
      <div style={{ marginTop: 20 }}>
        {alerts.map((i, k) => {
          return <Alert type={i.type} message={i.message} />;
        })}
      </div>
      {content}
    </Layout>
  );
};


export default UsersList;
