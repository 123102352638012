import "@aws-amplify/ui-react/styles.css";
import "./loginArea.scss";
import {
  Authenticator,
  useAuthenticator,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { Amplify, Auth } from "aws-amplify";
import awsExports from "../../aws-exports";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { I18n } from "aws-amplify";
import dict from "../../i18n.js";
import { useState } from "react";
import { signInWithAzure } from "Services/authenticationServices";

// Set language and vocabularies
I18n.putVocabularies(dict);
I18n.setLanguage("pt-br");

Amplify.configure(awsExports);

function LoginArea({ signOut, user }) {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/dashboard";

  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }

    // Email validation logic
    const submitButton = document.querySelector(".amplify-button[type='submit']");
    const handleClick = (event) => {
      const emailInput = document.querySelector("input[name='username']");
      const emailValue = emailInput?.value;
      const blockedDomains = ['@ise.org.br', '@ceulaw.com'];

      // Check if email contains any blocked domain
      if (blockedDomains.some(domain => emailValue.includes(domain))) {
        event.preventDefault(); // Prevent the form from submitting
        signInWithAzure()
      }
    };

    // Add event listener to the submit button
    if (submitButton) {
      submitButton.addEventListener("click", handleClick);
    }

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      if (submitButton) {
        submitButton.removeEventListener("click", handleClick);
      }
    };
  }, [route, from, navigate]);

  // Function to handle "X" button click
  const handleBack = () => {
    navigate('/login');
  };
  return (
    <div> 
     <button 
        className="close-button" 
        onClick={handleBack}
        aria-label="Close"
      >
        &times;
      </button>
    <Authenticator />
    </div>
  );
  
}

export default LoginArea; 
