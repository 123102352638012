import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CalendarX } from 'phosphor-react';
import FormContainer from "components/FormContainer/formContainer";
import styles from "./answerPreview.module.scss";
import logo1 from "../../assets/logo1.png";
import logo2 from "../../assets/logo2.png";
import Question from "components/Question/question";
import Button from "components/Button/button";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import pdfMake from "pdfmake";
import html2canvas from "html2canvas";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { DotsThreeOutline, Question as Info, CaretDoubleUp } from "phosphor-react";
import FormContainerPdf from "components/FormContainerPdf/formContainerPdf";
import DropdownList from "components/DropdownListField/dropdownListFIeld";
import { defaultAvatar } from "components/UserSettings/defaultAvatar";
import { type } from "@testing-library/user-event/dist/type";
import NotFound from "components/NotFound/notFound";
/**
 * Header component displaying rating information.
 */
const RatingInformationHeader = ({ lang, orderColor }) => {
  const headerText = {
    pt: {
      stars: "Estrelas:",
      lowHigh: "1 = Baixo/Negativo/Discordo/Insatisfatório<br />5 = Alto/Positivo/Concordo/Excelente",
    },
    en: {
      stars: "Stars:",
      lowHigh: "1 = Low/Negative/Disagree/Unsatisfactory<br />5 = High/Positive/Agree/Excellent",
    },
    es: {
      stars: "Estrellas:",
      lowHigh: "1 = Bajo/Negativo/Discrepar/Insatisfactorio<br />5 = Alto/Positivo/Concordar/Excelente",
    },
  };

  const texts = headerText[lang] || headerText.en; // Default to English if language is not provided

  return (
    <div className={styles.sessionHeaderRight}>
      <div className={styles.divisorCardSubtitle} style={{ backgroundColor: orderColor }}></div>
      <div>
        <span className={styles.bold}>{texts.stars}</span>
        <br />
        <span
          className={styles.subtitle}
          dangerouslySetInnerHTML={{ __html: texts.lowHigh }}
        ></span>
      </div>
    </div>
  );
};


/**
 * Component displaying teacher information.
 * @param {object} props - Props containing teacher, blockInfo, index and lang.
 */
const TeacherInfo = ({ teacher, blockInfo, index, lang, orderColor }) => {
  // Define translations for the "Professor" label
  const translations = {
    pt: "Professor:",
    en: "Teacher:",
    es: "Profesor:",
  };

  const professorLabel = translations[lang] || translations.en; // Default to English if language is not provided

  return (
    <div style={{ display: "flex", margin: "1.25rem 0" }}>
      <div className={styles.headerLeftInfo}>
        {index === 0 &&
          blockInfo.blockHeader.labeledText.map((line, lineIndex) => {
            return (
              <div key={lineIndex}>
                <span className={styles.bold}>{line.label}</span>
                <span>{line.text}</span> <br />
              </div>
            );
          })}
        <span className={styles.bold}>{professorLabel}</span>
        <span>
          {teacher.first_name} {teacher.last_name}
        </span>
      </div>
      <div className={styles.divTeacherPhoto}>
        <img
          className={styles.teacherPhoto}
          src={teacher.photo}
          alt={teacher.first_name}
        />
      </div>
    </div>
  );
};


/**
 * Loading component displaying a loading animation.
 */
const Loading = () => {
  return (
    <div style={{ marginTop: "20%", position: "relative" }}>
      <center>
        <div className={styles.loading}>
          <center>
            <ReactLoading
              type="bubbles"
              color="#0064a2"
              height={50}
              width={175}
            />
          </center>
        </div>
      </center>
    </div>
  )
}

const ButtonLoader = () => {
  return (
    <div style={{ position: "relative" }}>
      <center>
        <ReactLoading
          type="bubbles"
          color="#0064a2"
          height={30}
          width={155}
        />
      </center>
    </div>
  )
}
/**
 * Component displaying a message when the order is not available.
 * @param {object} props - Props containing the language settings.
 */
const OrderNotAvailable = ({ lang }) => {
  console.log(lang);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <CalendarX size={32} weight="fill" style={{ marginBottom: '16px' }} /> {/* Display the Calendar-X icon at the top */}
    </div>
  );
};

/**
 * Main Answer component.
 */
const Answer = () => {
  const [loading, setLoading] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false)
  const [answer, setAnswer] = useState(null);
  const [isOrderAvailable, setIsOrderAvailable] = useState(null);
  const [order, setOrder] = useState(null); // State to store the order data
  let { orderId } = useParams();
  const [language, setLanguage] = useState("pt")
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [isPopupOpenned, setIsPopupOpenned] = useState(false);

  const [isPopupInfoOpenned, setIsPopupInfoOpenned] = useState(false);

  const [y, setY] = useState(window.scrollY);
  const [totalY, setTotalY] = useState();
  const [scrollBar, setScrollBar] = useState();

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 30) {
        setIsVisible(false);
        setIsPopupInfoOpenned(false);
      } else {
        setIsVisible(true);
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    const element = document.getElementsByTagName("body")[0];
    setTotalY(element.clientHeight);
    setScrollBar(window.innerHeight);
  }, [y]);

  const scroll = `${(((y + scrollBar) + y) / totalY) * 100}%`;

  const navigate = useNavigate()

  const { signOut } = useAuthenticator((context) => [context.signOut]);

  function controlPopUp() {
    if (isPopupOpenned === true) {
      setIsPopupOpenned(false);
    } else {
      setIsPopupOpenned(true);
    }
  }

  function logOut() {
    signOut();
    localStorage.clear();
    navigate("/login");
  }

  const url = window.location.href;

  async function copyToClip() {
    await navigator.clipboard.writeText(url);
    setIsPopupOpenned(false);
  }

  const languageList = [
    { value: "pt", label: "Português" },
    { value: "en", label: "English" },
    { value: "es", label: "Español" },
  ];
  const setLanguageList = () => {
    const { pt, en, es } = order.languages;

    if (typeof order.languages !== 'object') {
      return languageList;
    }

    if (pt && en && es) {
      return languageList;
    } else if (pt && en) {
      return [languageList[0], languageList[1]];
    } else if (pt && es) {
      return [languageList[0], languageList[2]];
    } else if (en && es) {
      return [languageList[1], languageList[2]];
    } else if (pt) {
      return [languageList[0]];
    } else if (en) {
      return [languageList[1]];
    } else if (es) {
      return [languageList[2]];
    }

    return languageList;
  };

  const setDefaultLanguage = () => {
    if (order.languages.pt === true) {
      return "pt";
    } else if (order.languages.en === true) {
      return "en";
    } else if (order.languages.es === true) {
      return "es";
    }
  };

  // Função para atualizar a largura da barra de progresso com base no scroll
  function atualizarBarraDeProgresso() {
    var winScroll = document.documentElement.scrollTop || document.body.scrollTop;
    var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    document.getElementById('barraProgresso').style.width = `${scrolled}%`;
  }
  // Função para ajustar o padding-top do conteúdo
  function ajustarPaddingTopContent() {
    var alturaHeader = document.querySelector('header').offsetHeight;
    var alturaBarraProgresso = document.getElementById('barraProgresso').offsetHeight;
    var alturaTotalHeader = alturaHeader + alturaBarraProgresso;
    document.querySelector('.content').style.paddingTop = `${alturaTotalHeader}px`;
  }

  setTimeout(() => { // Adiciona evento de scroll para atualizar a barra de progresso
    window.addEventListener('scroll', atualizarBarraDeProgresso);
    // Adiciona evento de load e resize para ajustar o padding-top do conteúdo
    window.addEventListener('load', ajustarPaddingTopContent);
    window.addEventListener('resize', ajustarPaddingTopContent);
  }, 300);

  //Esta função é responsável por gerar o pdf da simulação baixável para o usuário
  const downloadPdf = () => {
    //DIV que será printada
    const pdfTable = document.getElementById("downloadPDF");

    //troca a propriedade display da DIV de none para ficar visível
    document.getElementById("downloadPDF").style.display = "inline-table";

    //Configuração do canvas e definições do PDF
    html2canvas(pdfTable).then(function (canvas) {
      //Definições do Canvas
      const imgObj = {
        image: canvas.toDataURL(),
        fit: [780, 800],

        style: {
          alignment: "center",
        },
      };

      //Definições do PDF a ser gerado
      const documentDefinition = {
        content: [imgObj],
        defaultStyle: {
          font: "NimbusSans",
        },
        pageSize: "A4",
        pageOrientation: "portrait",
        pageMargins: [0, 10, 0, 0],
      };

      //Criação do PDF
      const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

      //Download do PDF
      pdfDocGenerator.download("Avaliação.pdf");

      //Retorna propriedade display da DIV para none
      document.getElementById("downloadPDF").style.display = "none";
    });

    setIsPopupOpenned(false);
  };

  useEffect(() => {
    // The Orders API URI for getting specific order information
    const stage = process.env.REACT_APP_STAGE;
    const uri = `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/orders/`;
    const authToken = "SVNFX1N1cnZleTphcGk0cHVibDFjUjNzMHVyY2Vz";

    // Makes GET request to fetch order details with Authorization header
    fetch(`${uri}${orderId}`, {
      headers: {
        Authorization: `${authToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setOrder(data);
        let answersData = { answers: [] }
        data.questionnaire.map((block, index) => {
          let questions = []
          block.collection.questions.map((question) => {
            questions.push({ questionId: question.id, questionType: question.questionType, answer: null, required: question.required })
          })
          answersData.answers.push({ blockId: block.blockId, collection: block.collection.collectionId, answer: questions })

        });
        setAnswer(answersData);
        let openingDatetime = new Date(data.meta.opening);
        let closureDatetime = new Date(data.meta.closing);
        let orderAvailable;
        if (new Date() > openingDatetime && new Date() < closureDatetime && data.itemStatus == "ready") {
          orderAvailable = true;
        } else {
          orderAvailable = false;
        }
        setIsOrderAvailable(true);
      })
      .catch((error) => console.error("Error fetching order details:", error));
  }, []);

  if (!order) {
    return <div><Loading /></div>;
  }

  if (!order.id) {
    return <NotFound />;
  }

  function checkIfAllRequiredAreAnswered() {
    // Initialize an array to store required questions that haven't been answered
    const requiredQuestions = [];

    // Iterate through the questionnaire
    order.questionnaire.forEach((block) => {
      block.collection.questions.forEach((question) => {
        // Check if an answer for this question is required but hasn't been provided
        const foundAnswer = answer.answers.find(
          (ans) =>
            ans.blockId === block.blockId &&
            ans.answer &&
            ans.answer.find(
              (ansDetail) =>
                ansDetail.questionId === question.id &&
                ansDetail.answer === null &&
                ansDetail.required === true
            )
        );

        // If a required question without an answer is found, add it to the array
        if (foundAnswer) {
          requiredQuestions.push(foundAnswer);
        }
      });
    });

    // Check if there are required questions that haven't been answered
    if (requiredQuestions.length > 0) {
      // Get the language-specific alert message for unanswered required questions
      let alertMessage;
      if (language === "pt") {
        alertMessage = "Por favor, responda todas as perguntas obrigatórias.";
      } else if (language === "es") {
        alertMessage = "Por favor, responda todas las preguntas obligatorias.";
      } else {
        // Default to English if the language is not provided or recognized
        alertMessage = "Please answer all required questions.";
      }

      // Show the alert message to the user
      alert(alertMessage);

      // Return false to indicate that not all required questions have been answered
      return false;
    }

    // Return true if all required questions have been answered
    return true;
  }



  return (
    isOrderAvailable === true || isOrderAvailable !== true ?
      <div className={`${styles.surveyBody} ${styles.content}`} >
        {console.log(order)}
        {console.log(language)}
        {order.header.logos ? (
          <div className={styles.logoContainer} style={{ visibility: isVisible ? 'visible' : 'hidden' }}>
            {order.header.logos.logo1 && (order.header.logos.logo2 || order.header.logos.logo3) && order.header.logos.logo1.content && (
            <img
              className={styles.logoSize}
              src={order.header.logos.logo1.content}
              alt="Logo1"
            />
          )}
          {order.header.logos.logo1 && !order.header.logos.logo2 && !order.header.logos.logo3 && (
            <img
              className={styles.OnelogoSize}
              src={order.header.logos.logo1.content}
              alt="Logo1"
            />
          )}
            {order.header.logos.logo2 && order.header.logos.logo2.content && (
              <img className={styles.logoSize} src={order.header.logos.logo2.content} alt="Logo2" />
            )}
            {order.header.logos.logo3 && order.header.logos.logo3.content && (
              <img className={styles.logoSize} src={order.header.logos.logo3.content} alt="Logo3" />
            )}
          </div>
        ) : (<div className={styles.logoContainer} />)}
        {typeof order.languages !== 'string' && ((order.languages.pt === true && order.languages.en === true) || (order.languages.pt === true && order.languages.es === true) || (order.languages.en === true && order.languages.es === true) || (order.languages.pt === true && order.languages.en === true && order.languages.es === true)) &&
          <div className={styles.selectLanguageContainer} >
            <DropdownList
              defaultValue={setDefaultLanguage()}
              style={{ minWidth: "10rem" }}
              placeholder={"Selecione..."}
              id={"language"}
              listOfItems={setLanguageList()}
              onChange={(event) => {
                let languages;

                if (event === "pt") {
                  languages = "pt";
                } else if (event === "en") {
                  languages = "en";
                } else if (event === "es") {
                  languages = "es";
                }
                let selectedLanguage = order.languages[event] === true
                selectedLanguage = languages;
                setLanguage(selectedLanguage);
              }}
            />
          </div>
        }

        <div className={styles.logoContainerFixed}>
          {order.header.logos && (
            <>
              {order.header.logos.logo1 && (order.header.logos.logo2 || order.header.logos.logo3) && order.header.logos.logo1.content && (
            <img
              className={styles.logoSize}
              src={order.header.logos.logo1.content}
              alt="Logo1"
            />
          )}
              
          {order.header.logos.logo1 && !order.header.logos.logo2 && !order.header.logos.logo3 && (
            <img
              className={styles.OnelogoSizeFixed}
              src={order.header.logos.logo1.content}
              alt="Logo1"
            />
          )}
              {order.header.logos.logo2 && order.header.logos.logo2.content && (
                <img className={styles.logoSizeFixed} src={order.header.logos.logo2.content} alt="Logo2" />
              )}
              {order.header.logos.logo3 && order.header.logos.logo3.content && (
                <img className={styles.logoSizeFixed} src={order.header.logos.logo3.content} alt="Logo3" />
              )}
            </>
          )}
          {order.header.surveyCaption && (
            <div className={styles.caption} style={{ visibility: isVisible || isPopupInfoOpenned === true ? 'hidden' : 'visible' }}>
              <Info size={32} onClick={() => setIsPopupInfoOpenned(true)} />
            </div>
          )}

          {isPopupInfoOpenned === true ? (
            <>
              <div className={styles.captionBlock} >
                <span style={{ display: 'block' }} >
                  {language === 'pt' && ("Legenda:")}
                  {language === 'en' && ("Label:")}
                  {language === 'es' && ("Subtitular:")}
                </span>
                <span>
                  {order.header.surveyCaption && language === 'pt' && (order.header.surveyCaption.pt)}
                  {order.header.surveyCaption && language === 'en' && (order.header.surveyCaption.en)}
                  {order.header.surveyCaption && language === 'es' && (order.header.surveyCaption.es)}
                </span>
                <div>
                  <CaretDoubleUp size={24} className={styles.hideInfo} onClick={() => setIsPopupInfoOpenned(false)} />
                </div>
              </div>
              <div className={styles.InfoOverlay} onClick={() => setIsPopupInfoOpenned(false)}></div>
            </>
          ) : (
            <div className={styles.overlayOff}></div>
          )}
        </div>
        <span id="barraProgresso" className={styles.barraProgresso}></span>
        <div className={styles.optionsButton}>
          <Button className="transparent" onClick={() => setIsPopupOpenned(true)}>
            <DotsThreeOutline size={32} color="#603e3e" weight="fill" />
          </Button>
        </div>
        {isPopupOpenned == true ? (
          <>
            <div className={styles.newItemPopUp}>
              <div>
                <span className={styles.titlePopUp}>Ações</span>
              </div>
              <div className={styles.popUpDivisor}></div>
              <div>
                <Button
                  className={styles.optionsPopUp + " " + "transparent"}
                  onClick={copyToClip}
                >
                  <span>Copiar Link</span>
                </Button>
                <Button
                  className={styles.optionsPopUp + " " + "transparent"}
                  onClick={() => downloadPdf()}
                >
                  <span>Exportar PDF</span>
                </Button>
                <Button
                  className={styles.optionsPopUp + " " + "transparent"}
                  onClick={() => navigate("/dashboard")}
                >
                  <span>Ir para Home</span>
                </Button>
                <Button
                  className={styles.optionsPopUp + " " + "transparent"}
                  onClick={() => logOut()}
                >
                  <span>Logout</span>
                </Button>
              </div>
            </div>
            <div className={styles.overlay} onClick={() => controlPopUp()}></div>
          </>
        ) : (
          <div className={styles.overlayOff}></div>
        )}
        <div className={styles.infoSurvey}>
          <span className={styles.titleSurvey}>
            {order.header.headerLine1 && order.header.headerLine1[language] && (
              <span>{order.header.headerLine1[language]}</span>
            )}
          </span>
          <br />
          <span>
            {order.header.headerLine2 && order.header.headerLine2[language] && (
              <span>{order.header.headerLine2[language]}</span>
            )}
          </span>
          <br />
          <span>
            {order.header.headerLine3 && order.header.headerLine3[language] && (
              <span>{order.header.headerLine3[language]}</span>
            )}
          </span>
          <br />
          <span>
            {order.header.headerLine4 && order.header.headerLine4[language] && (
              <span>{order.header.headerLine4[language]}</span>
            )}
          </span>
        </div>
        {order.header.headerLine5 !== null && (
          <div className={styles.explanation}>
            {order.header.headerLine5 && order.header.headerLine5[language] && (
              <span>{order.header.headerLine5[language]}</span>
            )}
          </div>)
        }


        {order.questionnaire.length > 0 && answer != null ? (
          order.questionnaire.map((block, blockIndex) => (
            <div className={styles.questionsContainer}>
              <div className={styles.questionsBlock} key={blockIndex}>
                {block.blockHeader.headerLines &&

                  <div className={styles.teacherInfo}>
                    {block.blockHeader.headerLines.map((lines) => {
                      if (lines.type === 'teacher') {
                        console.log(order)
                        const designationTranslations = {
                          pt: {
                            Professor: 'Professor(a)',
                            Convidado: 'Convidado',
                            Palestrante: 'Palestrante'
                          },
                          en: {
                            Professor: 'Teacher',
                            Convidado: 'Guest',
                            Palestrante: 'Speaker'
                          },
                          es: {
                            Professor: 'Profesor',
                            Convidado: 'Invitado',
                            Palestrante: 'Ponente'
                          }
                        };

                        const translatedDesignation = designationTranslations[language][lines.values.designation];
                        return (                          
                          <>
                            <span className={styles.infoLine}><span className={styles.designation}>
                              {lines.values.designation && translatedDesignation && (
                                <span>{translatedDesignation}</span>
                              )}
                              :</span><span className={styles.text}>{lines.values.name}</span></span>
                            {lines.values.photo && (lines.values.id !== lines.values.photo || lines.values.photo === defaultAvatar) &&
                              <img src={lines.values.photo} className={styles.teacherPhoto} />                              
                            }
                          </>
                        )
                      } if (lines.type === 'labeledText' && lines.values.label) {
                        return (
                          <>
                            <span className={styles.infoLine}>
                              <span className={lines.values.labelBold === true ? styles.labelBold : styles.label}>
                                {lines.values.label && (
                                  (() => {
                                    switch (language) {
                                      case 'pt':
                                        return lines.values.label.pt || lines.values.label.es || lines.values.label.en || "";
                                      case 'es':
                                        return lines.values.label.es || lines.values.label.pt || lines.values.label.en || "";
                                      case 'en':
                                        return lines.values.label.en || lines.values.label.pt || lines.values.label.es || "";
                                      default:
                                        return lines.values.label;
                                    }
                                  })()
                                )}
                                {typeof lines.values.label !== 'object' && lines.values.label}
                                {lines.values.label.pt !== null ? ":" : ""}
                              </span>
                              <span className={lines.values.textBold === true ? styles.textBold : styles.text}>
                                {lines.values.text && (
                                  (() => {
                                    switch (language) {
                                      case 'pt':
                                        return lines.values.text.pt || lines.values.text.es || lines.values.text.en || "";
                                      case 'es':
                                        return lines.values.text.es || lines.values.text.pt || lines.values.text.en || "";
                                      case 'en':
                                        return lines.values.text.en || lines.values.text.pt || lines.values.text.es || "";
                                      default:
                                        return lines.values.text;
                                    }
                                  })()
                                )}
                                {typeof lines.values.text !== 'object' && lines.values.text}
                              </span>
                            </span>
                          </>
                        )
                      } if (lines.type === 'labeledText' && lines.values.label) {
                        return (
                          <>
                            <span className={lines.values.textBold === true ? styles.textBold : styles.text + " " + styles.infoLine}>
                              {lines.values.text && (
                                (() => {
                                  switch (language) {
                                    case 'pt':
                                      return lines.values.text.pt || lines.values.text.es || lines.values.text.en || "";
                                    case 'es':
                                      return lines.values.text.es || lines.values.text.pt || lines.values.text.en || "";
                                    case 'en':
                                      return lines.values.text.en || lines.values.text.pt || lines.values.text.es || "";
                                    default:
                                      return lines.values.text;
                                  }
                                })()
                              )}
                              {typeof lines.values.text !== 'object' && lines.values.text}
                            </span>
                          </>
                        )
                      }
                    })}
                  </div>
                }
                {block.blockHeader.headerLines === undefined &&
                  <div className={styles.noInfo} />}
                <div className={styles.questionsBlock}>
                  {block.collection.questions.map((question, questionIndex) => (
                    <div key={questionIndex}>
                      <Question blockIndex={blockIndex} lang={language} questionIndexInBlock={questionIndex} questionType={question.questionType} questionData={question} blockId={block.blockId} answers={answer} setAnswer={setAnswer}></Question>
                    </div>
                  ))}
                </div>
              </div>
            </div>


          ))
        ) : (
          <h2>Este survey não contém questões para serem respondidas.</h2>
        )}
        {submissionSuccess ? (
          <div className={styles.thankYouMessage}>
            <h2 style={{ textAlign: 'center' }}>
              {language === 'pt' && 'Obrigado pela sua resposta!'}
              {language === 'en' && 'Thank you for your response!'}
              {language === 'es' && '¡Gracias por tu respuesta!'}
            </h2>
            {/* You can add any additional content or formatting for the thank you message */}
          </div>
        ) : (
          <>
            <div style={{ display: "none" }} id={"downloadPDF"}>
              {order.header.logos ? (
                <div className={styles.logoContainer} style={{ visibility: isVisible ? 'visible' : 'hidden' }}>
                  {order.header.logos.logo1 && order.header.logos.logo1.content && (
                    <img className={styles.logoSize} src={order.header.logos.logo1.content} alt="Logo1" />
                  )}
                  {order.header.logos.logo2 && order.header.logos.logo2.content && (
                    <img className={styles.logoSize} src={order.header.logos.logo2.content} alt="Logo2" />
                  )}
                  {order.header.logos.logo3 && order.header.logos.logo3.content && (
                    <img className={styles.logoSize} src={order.header.logos.logo3.content} alt="Logo3" />
                  )}
                </div>
              ) : (<div className={styles.logoContainer} />)}
              <div className={styles.infoSurvey}>
                <span className={styles.titleSurvey}>
                  {order.header.headerLine1 && order.header.headerLine1[language] && (
                    <span>{order.header.headerLine1[language]}</span>
                  )}
                </span>
                <br />
                <span>
                  {order.header.headerLine2 && order.header.headerLine2[language] && (
                    <span>{order.header.headerLine2[language]}</span>
                  )}
                </span>
                <br />
                <span>
                  {order.header.headerLine3 && order.header.headerLine3[language] && (
                    <span>{order.header.headerLine3[language]}</span>
                  )}
                </span>
                <br />
                <span>
                  {order.header.headerLine4 && order.header.headerLine4[language] && (
                    <span>{order.header.headerLine4[language]}</span>
                  )}
                </span>
              </div>
              {order.header.headerLine5 !== null && (
                <div className={styles.explanation}>
                  {order.header.headerLine5 && order.header.headerLine5[language] && (
                    <span>{order.header.headerLine5[language]}</span>
                  )}
                </div>)
              }
              {order.questionnaire.length > 0 && answer != null ? (
                order.questionnaire.map((block, blockIndex) => (
                  <div className={styles.questionsContainer}>
                    <div className={styles.questionsBlock} key={blockIndex}>
                      {block.blockHeader.headerLines &&

                        <div className={styles.teacherInfo}>
                          {block.blockHeader.headerLines.map((lines) => {
                            if (lines.type === 'teacher') {
                              // console.log(order)
                              return (
                                <>
                                  <span className={styles.infoLine}><span className={styles.designation}>{lines.values.designation}:</span><span className={styles.text}>{lines.values.name}</span></span>
                                  {lines.values.photo && lines.values.photo !== defaultAvatar &&
                                    <img src={lines.values.photo} className={styles.teacherPhoto} alt="professor" />
                                  }
                                </>
                              )
                            } if (lines.type === 'labeledText' && lines.values.label) {
                              return (
                                <>
                                  <span>
                                    <span className={lines.values.labelBold === true ? styles.labelBold : styles.label + " " + styles.infoLine}>
                                      {lines.values.label && (
                                        (() => {
                                          switch (language) {
                                            case 'pt':
                                              return lines.values.label.pt || lines.values.label.es || lines.values.label.en || "";
                                            case 'es':
                                              return lines.values.label.es || lines.values.label.pt || lines.values.label.en || "";
                                            case 'en':
                                              return lines.values.label.en || lines.values.label.pt || lines.values.label.es || "";
                                            default:
                                              return lines.values.label;
                                          }
                                        })()
                                      )}
                                      {typeof lines.values.label !== 'object' && lines.values.label}
                                      {lines.values.label.pt !== null ? ":" : ""}
                                    </span>
                                    <span className={lines.values.textBold === true ? styles.textBold : styles.text + " " + styles.infoLine}>
                                      {lines.values.text && (
                                        (() => {
                                          switch (language) {
                                            case 'pt':
                                              return lines.values.text.pt || lines.values.text.es || lines.values.text.en || "";
                                            case 'es':
                                              return lines.values.text.es || lines.values.text.pt || lines.values.text.en || "";
                                            case 'en':
                                              return lines.values.text.en || lines.values.text.pt || lines.values.text.es || "";
                                            default:
                                              return lines.values.text;
                                          }
                                        })()
                                      )}
                                      {typeof lines.values.text !== 'object' && lines.values.text}
                                    </span>
                                  </span>
                                </>
                              )
                            } if (lines.type === 'labeledText' && lines.values.label) {
                              return (
                                <>
                                  <span className={lines.values.textBold === true ? styles.textBold : styles.text + " " + styles.infoLine}>
                                    {lines.values.text && (
                                      (() => {
                                        switch (language) {
                                          case 'pt':
                                            return lines.values.text.pt || lines.values.text.es || lines.values.text.en || "";
                                          case 'es':
                                            return lines.values.text.es || lines.values.text.pt || lines.values.text.en || "";
                                          case 'en':
                                            return lines.values.text.en || lines.values.text.pt || lines.values.text.es || "";
                                          default:
                                            return lines.values.text;
                                        }
                                      })()
                                    )}
                                    {typeof lines.values.text !== 'object' && lines.values.text}
                                  </span>
                                </>
                              )
                            }
                          })}
                        </div>
                      }
                      {block.blockHeader.headerLines === undefined &&
                        <div className={styles.noInfo} />}
                      <div className={styles.questionsBlock}>
                        {block.collection.questions.map((question, questionIndex) => (
                          <div key={questionIndex}>
                            <Question blockIndex={blockIndex} lang={language} questionIndexInBlock={questionIndex} questionType={question.questionType} questionData={question} blockId={block.blockId} answers={answer} setAnswer={setAnswer}></Question>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>


                ))
              ) : (
                <h2>Este survey não contém questões para serem respondidas.</h2>
              )}
            </div>
          </>
        )}
        {submissionSuccess == true ? (
          <div className={styles.sendButton}>
            <Button className={"secondaryBlue"} onClick={() => navigate('/')}>Início</Button>
          </div>
        ) : (
          <div className={styles.sendButton}>
            {
              buttonLoader == false ?
                <Button className={"deactivated"} >Enviar</Button>
                :
                <ButtonLoader />
            }
          </div>
        )}
      </ div>
      :
      <OrderNotAvailable lang={order.languages} />
  );
};

export default Answer;
