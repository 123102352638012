import Field from "components/Field/field";
import styles from "./AddOptions.module.scss";
import ListControl from "components/ListControl/ListControl";

export const TableOfOptions = ({
  list,
  addNewOption,
  removeOption,
  updateOption,
  updateWeightOfOption,
  includeWeight,
  languages,
}) => {
  let optionFieldColumnClass;

  if (includeWeight == true) {
    optionFieldColumnClass = styles.optionFieldColumn;
  } else {
    optionFieldColumnClass = styles.optionFieldColumnWithoutWeight;
  }

  return (
    <div>
      <table style={{ borderSpacing: "0 0.5em" }}>
        <tbody>
          {
            //In case the user had no interaction with the TableOfOptions the list will be empty, so the loop wont be performed, thus a default <tr> will be displayed enabling users to have their first interaction
            list.length == 0 ? (
              <tr
                key={0}
                style={{ marginBottom: 5 }}
                className={styles.optionFieldColumnContainer}
              >
                <td
                  className={
                    optionFieldColumnClass +
                    " " +
                    styles.optionLanguageContainer
                  }
                >
                  {languages.pt ? (
                    <div style={{ width: "100%" }}>
                      <label>
                        PT<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        name={"field_pt"}
                        id={"field_pt"}
                        onChange={(event) => updateOption(event, 0, "pt")}
                        className={styles.optionField}
                        defaultValue={null}
                        placeholder={"Opção 1 PT"}
                      />
                    </div>
                  ) : null}
                  {languages.en ? (
                    <div style={{ width: "100%" }}>
                      <label>
                        EN<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        name={"field_en"}
                        id={"field_en"}
                        onChange={(event) => updateOption(event, 0, "en")}
                        className={styles.optionField}
                        defaultValue={null}
                        placeholder={"Opção 1 EN"}
                      />
                    </div>
                  ) : null}
                  {languages.es ? (
                    <div style={{ width: "100%" }}>
                      <label>
                        ES<span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        name={"field_es"}
                        id={"field_es"}
                        onChange={(event) => updateOption(event, 0, "es")}
                        className={styles.optionField}
                        defaultValue={null}
                        placeholder={"Opção 1 ES"}
                      />
                    </div>
                  ) : null}
                </td>
                {includeWeight == true && (
                  <td className={styles.weightOptionFieldColumn}>
                    <Field
                      type={"number"}
                      name={"weightField"}
                      id={"weightField"}
                      onChange={(event) => updateWeightOfOption(event, 0)}
                      placeholder={"Peso opção 1"}
                      className={styles.weightOptionField}
                      defaultValue={null}
                    />
                  </td>
                )}
                <td className={styles.controlColumn}>
                  <ListControl
                    addsNewItem={addNewOption}
                    removesAnItem={removeOption}
                    indexOfItemToInteractWith={0}
                    newItemDefaultContent={null}
                    className={styles.teste}
                  />
                </td>
              </tr>
            ) : (
              //After the first interaction, the loop will be performed
              (() => {
                //All <tr>s will be added to this list
                let listToBeDisplayed = [];

                //Loops the list
                for (let i = 0; i < list.length; i++) {
                  //Adds indexes to listToBeDisplayed with the suitable parameters for each index
                  listToBeDisplayed.push(
                    <tr
                      key={i}
                      style={{ marginBottom: 5 }}
                      className={styles.optionFieldColumnContainer}
                    >
                      <td
                        className={
                          optionFieldColumnClass +
                          " " +
                          styles.optionLanguageContainer
                        }
                      >
                        {languages.pt ? (
                          <div style={{ width: "100%" }}>
                            <label>
                              PT<span style={{ color: "red" }}>*</span>
                            </label>
                            <Field
                              name={"field_pt" + i}
                              id={"field_pt" + i}
                              onChange={(event) => updateOption(event, i, "pt")}
                              className={styles.optionField}
                              defaultValue={list[i].pt}
                              value={list[i].pt}
                              placeholder={"Opção " + (i + 1) + " PT"}
                            />
                          </div>
                        ) : null}
                        {languages.en ? (
                          <div style={{ width: "100%" }}>
                            <label>
                              EN<span style={{ color: "red" }}>*</span>
                            </label>
                            <Field
                              name={"field_en" + i}
                              id={"field_en" + i}
                              onChange={(event) => updateOption(event, i, "en")}
                              className={styles.optionField}
                              defaultValue={list[i].en}
                              value={list[i].en}
                              placeholder={"Opção " + (i + 1) + " EN"}
                            />
                          </div>
                        ) : null}
                        {languages.es ? (
                          <div style={{ width: "100%" }}>
                            <label>
                              ES<span style={{ color: "red" }}>*</span>
                            </label>
                            <Field
                              name={"field_es" + i}
                              id={"field_es" + i}
                              onChange={(event) => updateOption(event, i, "es")}
                              className={styles.optionField}
                              defaultValue={list[i].es}
                              value={list[i].es}
                              placeholder={"Opção " + (i + 1) + " ES"}
                            />
                          </div>
                        ) : null}
                      </td>
                      {includeWeight == true && (
                        <td className={styles.weightOptionFieldColumn}>
                          <Field
                            type={"number"}
                            name={"weightField"}
                            id={"weightField"}
                            onChange={(event) => updateWeightOfOption(event, i)}
                            className={styles.weightOptionField}
                            value={list[i].weight}
                            defaultValue={null}
                            placeholder={"Peso opção " + (i + 1)}
                          />
                        </td>
                      )}
                      <td className={styles.controlColumn}>
                        <ListControl
                          addsNewItem={addNewOption}
                          removesAnItem={removeOption}
                          indexOfItemToInteractWith={i}
                          newItemDefaultContent={null}
                        />
                      </td>
                    </tr>
                  );
                }

                //returns the content of the table containing all list items
                return listToBeDisplayed;
              })()
            )
          }
        </tbody>
      </table>
    </div>
  );
};

//List is the array of options that you should declare as a state variable in the parent component
//For the mentioned state variable you'll have a "set" function that you'll send to this component as setList
//This way the user will interact with this component and send the data back to the parent component

//Use case:

//First step: set the state variable in the parent component
// set [myOptions, setMyOptions] = useState([])

//Second step: call this component by passing the two required props (setList and list)
// <AddOptions setList={setMyOptions} list={myOptions} />

const AddOptions = ({ setList, list, includeWeight, languages }) => {
  function generateRandomString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  }

  //This function will get the list variable that you're sending from the parent component and call the setList function for adding a new index to it
  function addNewOption(defaultItem, index, weight = null) {
    //Default option list item
    let newItem = {
      index: index + 1,
      id: generateRandomString(6),
    };

    //Checks if user wants to use weight parameter
    if (includeWeight == true) {
      newItem.weight = weight;
    }

    //Gets list
    let _options = [...list];

    //Checks if the user is adding a new item at the end of the list
    if (list.length != 0 && list.length == index) {
      _options.push(newItem);
      setList(_options);
    }

    //In case the first interaction is adding one more option (then it will be added 2 more elements to the list since the list is empty before the first interaction)
    else if (list.length == 0 && defaultItem == null) {
      newItem.index = 1;
      let secondItemWhenListIsEmpty = { ...newItem };
      secondItemWhenListIsEmpty.index = 2;
      _options = [newItem, secondItemWhenListIsEmpty];
      setList(_options);
    }
    //In case the user wants to add the item in the middle of the list
    else {
      //Increase IDs of the following indexes (if the user is adding an item on index 2, then the current index 2 will be increased to index 3 and so on)
      _options.map((i, k) => {
        if (k >= index) {
          _options[k].index += 1;
        }
      });

      //Adding a new item to the required index
      _options.splice(index, 0, newItem);
      setList(_options);
    }
    //console.log(_options);

    return _options;
  }

  //removeOption will remove the desired index from the list
  function removeOption(index) {
    //Gets list
    let _options = [...list];

    //Avoids user removing all items (the list should have at least one item)
    if (_options.length > 1) {
      _options.splice(index, 1);
      setList(_options);
    }
  }

  //This function will be used during onChange when the user interacts to the input option field
  function updateOption(event, index, lang) {
    //Checks if it's the first interaction
    if (list.length < 1) {
      //In case it's the first interaction, an item will be added to the list
      addNewOption(event.target.value, 0);
    } else {
      let _options = [...list];
      event.target.value == ""
        ? delete _options[index][lang]
        : (_options[index][lang] = event.target.value);
      setList(_options);
    }
  }

  //This function will be used during onChange when the user interacts to the input WEIGHT field
  function updateWeightOfOption(event, index) {
    //Checks if it's the first interaction
    if (list.length < 1) {
      //In case it's the first interaction, an item will be added to the list
      addNewOption(null, 0, event.target.value);
    } else {
      let _options = [...list];
      _options[index].weight = parseInt(event.target.value);
      setList(_options);
    }
  }

  return (
    <div>
      <TableOfOptions
        list={list}
        addNewOption={addNewOption}
        removeOption={removeOption}
        updateOption={updateOption}
        updateWeightOfOption={updateWeightOfOption}
        includeWeight={includeWeight}
        languages={languages}
      />
    </div>
  );
};

export default AddOptions;
