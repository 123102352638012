import { EyeOutlined } from "@ant-design/icons";
import Button from "components/Button/button";
import Checkbox from "components/Checkbox/checkbox";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import DropdownListField from "components/DropdownListField/dropdownListFIeld";
import Field from "components/Field/field";
import FormContainer from "components/FormContainer/formContainer";
import Layout from "components/Layout/layout";
import PageTitle from "components/PageTitle/pageTitle";
import UploadAvatarField from "components/UploadAvatarField/uploadAvatarField";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NewUser, PatchUser } from "./userServices";
import styles from "./userSettings.module.scss";
import ReactLoading from "react-loading";
import { defaultAvatar } from "./defaultAvatar";
import { checkUser } from "Services/authenticationServices";
import { addNewAlert } from "Services/alertsManagement";
import { verifySession } from "Services/authenticationServices";
import Alert from "components/Alert/Alert";
import CloseButton from "components/CloseButton/closeButton";
import { PlusCircle } from "phosphor-react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Loading from "components/Loading/loading";

const UserSettings = () => {
  let { userParams } = useParams();

  let pageTitle = userParams === "new" ? "Criar Usuário" : "Editar Usuário";

  const [userEmail, setUserEmail] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [photo, setPhoto] = useState(defaultAvatar);
  const [role, setRole] = useState("");

  const [loading, setLoading] = useState(true);
  const [alerts, setAlerts] = useState([]);
  const [closingConfirmationModal, setClosingConfirmationModal] = useState(false);

  const { user } = useAuthenticator((context) => [context.user]);
  const capabilities = localStorage.getItem("capabilities#" + user.username);
  const stage = process.env.REACT_APP_STAGE;
  const navigate = useNavigate();

  const functionsOptions = [
    { value: "Administrador", label: "Administrador" },
    { value: "Materiais", label: "Materiais" },
    { value: "Admissões", label: "Admissões" },
    { value: "Coordenador", label: "Coordenador" },
    { value: "Docente", label: "Docente" },
    { value: "Helpdesk", label: "Helpdesk" },
    { value: "Convidado", label: "Convidado" }
  ];
  
 // Basic email validation function
const isValidEmail = (email) => {
  return email.includes('@') && email.includes('.');
};

// Validation function
const validateFields = () => {
  if (!name || !lastName || !userEmail || !role) {
    setAlerts([{ message: "Todos os campos obrigatórios devem ser preenchidos.", type: "danger" }]);
    return false;
  }

  if (!isValidEmail(userEmail)) {
    setAlerts([{ message: "Por favor, insira um email válido.", type: "danger" }]);
    return false;
  }

  return true;
};


  async function requestUserData() {
    let user = await checkUser();
    return user;
  }

  const fetchData = () => {
    if (userParams !== "new") {
      fetch(
        `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/users/` +
        userParams
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.message) {
            navigate("/notFound");
          } else {
            setUserEmail(data["email"]);
            setName(data["first_name"]);
            setLastName(data["last_name"]);
            setPhoto(data["photo"]);
            setRole(data["role"]);
            setLoading(false);
            console.log(data.message);
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          setAlerts([{ message: "Erro ao carregar os dados do usuário", type: "danger" }]);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (capabilities.includes("cadastros")) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, []);

  async function savePatch() {
    let response = await PatchUser(
      userParams,
      name,
      lastName,
      userEmail,
      photo,
      role,
      await requestUserData()
    ).then(() => {
      setAlerts([{ message: "Usuário " + name + " modificado", type: "success" }]);
      setLoading(false);
    });

    return response;
  }

  async function createNewUser() {
    if (!validateFields()) {
      setLoading(false);
      return;
    }

    let response = await NewUser(
      name,
      lastName,
      userEmail,
      photo,
      role,
      await requestUserData()
    ).then((data) => {
      navigate("/user/" + data["id"]);
      let updateAlertList = addNewAlert(alerts, {
        message: "Novo usuário criado para " + name,
        type: "success",
      });
      setAlerts(updateAlertList);
      setLoading(false);
    });

    return response;
  }

  let content;

  switch (true) {
    case loading:
      content = (
        <div className={styles.loading}>
          <div className="large">Carregando informações</div>
          <center>
            <ReactLoading
              type="bubbles"
              color="#0064a2"
              height={50}
              width={175}
            />
          </center>
        </div>
      );
      break;
    case capabilities.includes("cadastros"):
      content = (
        <div>
          <div className={styles.pageHeader}>
            <PageTitle>{pageTitle}</PageTitle>
            <div className={styles.closeButton}>
              <ConfirmationModal
                onClick={() => setClosingConfirmationModal(true)}
                title={"Deseja sair?"}
                open={closingConfirmationModal}
                onOk={() => navigate("/users")}
                onCancel={() => setClosingConfirmationModal(false)}
                iconComponent={<CloseButton />}
              />
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <Button
              className={styles.createQuestionButton + " " + "primaryOrange"}
              onClick={() => {
                navigate("/user/new");
                window.location.reload();
              }}
            >
              <PlusCircle size={22} className={styles.addIcon} />
              <span style={{ paddingBottom: "3px" }}>Criar Usuário</span>
            </Button>
          </div>
          <div style={{ maxHeight: "500px", overflow: "none" }}>
            {alerts.map((i, k) => (
              <Alert key={k} message={i.message} type={i.type} style={{ width: '90%' }} />
            ))}
            <FormContainer>
              <div className={styles.container}>
                <div className={styles.name}>
                  <label>
                    Nome<span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    placeholder={"Digite o Nome"}
                    name={"name"}
                    id={"name"}
                    defaultValue={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </div>
                <div className={styles.lastName}>
                  <label>
                    Sobrenome<span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    placeholder={"Digite o Sobrenome"}
                    name={"lastName"}
                    id={"lastName"}
                    defaultValue={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                  />
                </div>
                <div className={styles.userEmail}>
                  <label>
                    E-mail<span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    placeholder={"Digite o email"}
                    name={"userEmail"}
                    id={"userEmail"}
                    defaultValue={userEmail}
                    onChange={(event) => setUserEmail(event.target.value)}
                    disabled={userParams !== "new"}
                  />
                </div>
                <div className={styles.function}>
                  <label>
                    Função<span style={{ color: "red" }}>*</span>
                  </label>
                  <DropdownListField
                    id="function"
                    placeholder={"Selecione"}
                    listOfItems={functionsOptions}
                    style={{ width: "100%" }}
                    defaultValue={role}
                    onChange={(event) => setRole(event)}
                  />
                </div>
                <div className={styles.uploadAvatar}>
                  <UploadAvatarField setAvatar={setPhoto} defaultValue={photo} />
                </div>
              </div>
            </FormContainer>
          </div>
          <div className={styles.buttonsContainer}>
            {userParams === "new" ? (
              <Button
                onClick={() => {
                  setLoading(true);
                  createNewUser();
                }}
                className={styles.saveButton + " " + "primaryOrange"}
              >
                Salvar
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setLoading(true);
                  savePatch();
                }}
                className={styles.saveButton + " " + "primaryOrange"}
              >
                Salvar
              </Button>
            )}
            <div style={{ marginLeft: "auto" }}>
              <Button
                className={styles.cancelButton + " " + "secondaryOrange"}
                onClick={() => setClosingConfirmationModal(true)}
              >
                Cancelar
              </Button>
            </div>
          </div>
        </div>
      );
      break;
    case !capabilities.includes("cadastros"):
      content = (
        <div className={styles.error}>
          <center><Alert type={"danger"} message={"Você não tem permissão para acessar esta página"} /></center>
        </div>
      );
      break;
    default:
      content = <div>Houve um erro ao carregar a página</div>;
  }

  return <Layout>{content}</Layout>;
};

export default UserSettings;
