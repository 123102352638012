import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import Button from "components/Button/button";
import ButtonDropdown from "components/ButtonDropdown/buttonDropdown";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import Field from "components/Field/field";
import {
  DotsSixVertical,
  MagnifyingGlass,
  PlusCircle,
  XCircle,
  ArrowCircleUp,
  ArrowCircleDown,
} from "phosphor-react";
import React, { useRef, useState } from "react";
import Select from "react-select";

import styles from "./manageQuestionnaire.module.scss";
import Checkbox from "components/Checkbox/checkbox";
import brazilIcon from "../../assets/icons/brazil.svg";
import spainIcon from "../../assets/icons/spain.svg";
import usIcon from "../../assets/icons/us.svg";
// Languages must follow the pattern below
// {pt: "Português", en: "English", es: "Español"}
const ManageQuestionnaire = ({
  blocks,
  setBlocks,
  patterns,
  usersList,
  fieldList,
  languages,
}) => {
  const [removeBlockConfirmation, setRemoveBlockConfirmation] = useState(false);
  const [blockIdToRemove, setBlockIdToRemove] = useState();
  const [designations, setDesignations] = useState([
    { value: "Professor", label: "Professor" },
    { value: "Convidado", label: "Convidado" },
    { value: "Palestrante", label: "Palestrante" },
  ]);
  const [userPhoto, setUserPhoto] = useState();
  const [removePhoto, setRemovePhoto] = useState(Boolean);

  const newDefaultBlock = {
    field: null,
    blockHeader: { teacher: [], labeledText: [] },
    blockIndex: null,
    blockId: Math.floor(100000 + Math.random() * 900000).toString(),
    collection: [],
  };

  // used to remove shadow in drag and drop
  document.addEventListener(
    "dragstart",
    function (event) {
      event.dataTransfer.setDragImage(
        event.target,
        window.outerWidth,
        window.outerHeight
      );
    },
    false
  );

  //used to add background in div dragged
  document.addEventListener(
    "dragstart",
    function (event) {
      event.target.style.background = "#d9d9d9";
      event.target.style.borderRadius = "8px";
    },
    false
  );

  //used to remove background in div dragged
  document.addEventListener(
    "dragend",
    function (event) {
      event.target.style.background = "none";
      event.target.style.borderRadius = "none";
    },
    false
  );

  function addTitle(blockIndex) {
    let _blocks = [...blocks];
    _blocks[blockIndex].blockHeader.title = null;
    setBlocks(_blocks);
  }

  function _addProfLine(blockIndex) {
    let _blocks = [...blocks];
    _blocks[blockIndex].blockHeader.teacher.push({
      designation: null,
      id: null,
    });
    setBlocks(_blocks);
  }

  function addProfLine(blockIndex) {
    addHeaderLine(blockIndex, "teacher");
  }

  const teacher = {
    type: "teacher",
    values: { id: null, designation: null, name: null, photo: null },
  };

  const labeledText = {
    type: "labeledText",
    values: {
      label: { pt: null },
      labelBold: false,
      text: { pt: null },
      textBold: false,
    },
  };

  function addHeaderLine(blockIndex, type) {
    let _blocks = [...blocks];
    if (!_blocks[blockIndex].blockHeader.headerLines) {
      _blocks[blockIndex].blockHeader.headerLines = [];
    }

    let newHeaderLine;

    switch (type) {
      case "teacher":
        newHeaderLine = teacher;
        break;
      case "labeledText":
        newHeaderLine = labeledText;
        break;
      // Add more cases for other types if needed
      default:
        // Handle unknown type or throw an error
        throw new Error("Unknown type");
    }

    _blocks[blockIndex].blockHeader.headerLines.push(newHeaderLine);
    console.log(_blocks);
    setBlocks(_blocks);
  }

  function _addLabelText(blockIndex) {
    let _blocks = [...blocks];
    _blocks[blockIndex].blockHeader.labeledText.push({
      label: null,
      text: null,
    });
    setBlocks(_blocks);
  }

  function addLabelText(blockIndex) {
    addHeaderLine(blockIndex, "labeledText");
  }

  const listOfItemsButton = [
    {
      className: styles.buttonWhite,
      action: addProfLine,
      label: "Linha de Professor",
    },
    {
      className: styles.buttonWhite,
      action: addLabelText,
      label: "Rótulo e texto",
    },
  ];

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const addBlock = () => {
    let _blocks = [...blocks];
    _blocks.push(newDefaultBlock);
    setBlocks(_blocks);
  };

  const setDefaultDesignationValue = (user) => {
    return designations.find((designation) => {
      if (designation.value == user.designation) {
        return designation.label;
      }
    });
  };

  const setDefaultUserValue = (id) => {
    console.log(id);
    return usersList.find((user) => {
      if (user.value == id) {
        return user.label;
      } else {
        return null;
      }
    });
  };

  const _setDefaultPatternValue = (patternData) => {
    console.log(patternData);
    if (typeof patternData !== "string") {
      if (typeof patternData === "object") {
        console.log(patternData.collectionId);
        return patterns.find((pattern) => {
          if (pattern.value == patternData.collectionId) {
            return pattern.label;
          }
        });
      } else {
        return null;
      }
    } else {
      return patterns.find((pattern) => {
        if (pattern.value == patternData) {
          return pattern.label;
        }
      });
    }
  };

  const setDefaultFieldValue = (value) => {
    if (typeof value !== "string") {
      return null;
    } else {
      return fieldList.find((field) => {
        if (field.value == value) {
          return field.label;
        }
      });
    }
  };

  const setDefaultPatternValue = (patternData) => {
    console.log(patternData);
    if (typeof patternData !== "string") {
      if (typeof patternData === "object") {
        console.log(patternData.collectionId);
        return patterns.find((pattern) => {
          if (pattern.value == patternData.collectionId) {
            return pattern.label;
          }
        });
      } else {
        return null;
      }
    } else {
      return patterns.find((pattern) => {
        if (pattern.value == patternData) {
          return pattern.label;
        }
      });
    }
  };

  const selectDesignationValue = (designation, blockIndex, userIndex) => {
    let _blocks = [...blocks];
    _blocks[blockIndex].blockHeader.teacher[userIndex].designation =
      designation.value;
    setBlocks(_blocks);
  };

  const selectDesignationValueHeader = (designation, blockIndex, lineIndex) => {
    let _blocks = [...blocks];
    let block = _blocks[blockIndex];
    if (block) {
      console.log(block);
      if (block.blockHeader) {
        console.log(block.blockHeader.headerLines);
        _blocks[blockIndex].blockHeader.headerLines[
          lineIndex
        ].values.designation = designation.value;
        setBlocks(_blocks);
      }
    }
  };

  const selectUserValueHeader = (user, blockIndex, userIndex) => {
    let _blocks = [...blocks];
    let block = _blocks[blockIndex];
    if (block) {
      if (block.blockHeader) {
        _blocks[blockIndex].blockHeader.headerLines[userIndex].values.id =
          user.value;
        _blocks[blockIndex].blockHeader.headerLines[userIndex].values.name =
          user.name;
          _blocks[blockIndex].blockHeader.headerLines[userIndex].values.photo =
          user.photo;
        setBlocks(_blocks);
      }
    }
    console.log(_blocks);
    setBlocks(_blocks); 
    setRemovePhoto(false)
  };

  const setNoPhoto = (user, blockIndex, userIndex) => {
    let _blocks = [...blocks];
    let block = _blocks[blockIndex];
    if (block) {  
      if (block.blockHeader) {
        _blocks[blockIndex].blockHeader.headerLines[userIndex].values.id =
          user.value;
        _blocks[blockIndex].blockHeader.headerLines[userIndex].values.name =
          user.name;
          _blocks[blockIndex].blockHeader.headerLines[userIndex].values.photo =
          user.value;               
        setBlocks(_blocks);
      }
    }
    console.log(_blocks);
    setBlocks(_blocks);  
    setRemovePhoto(true)
  };

  const selectUserValue = (user, blockIndex, userIndex) => {
    let _blocks = [...blocks];
    _blocks[blockIndex].blockHeader.teacher[userIndex].id = user.value;
    setBlocks(_blocks);
  };

  const selectFieldValue = (field, blockIndex) => {
    let _blocks = [...blocks];
    _blocks[blockIndex].field = field.value;
    setBlocks(_blocks);
  };

  const selectPatternValue = (pattern, blockIndex) => {
    let _blocks = [...blocks];
    _blocks[blockIndex].collection = pattern.value;
    setBlocks(_blocks);
  };

  const customStyles = {
    control: (styles) => ({ ...styles, minHeight: "32px" }),
    input: (styles) => ({ ...styles, height: "30px" }),
    indicatorsContainer: (styles) => ({ ...styles, height: "30px" }),
    valueContainer: (styles) => ({
      ...styles,
      height: "30px",
      padding: "0 10px",
      marginLeft: "16px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
  };

  const buildHeader = (
    lineType = null,
    lineValues = null,
    blockIndex,
    lineIndex
  ) => {
    let headerLines = [];
    if (lineType == "labeledText") {
      headerLines.push(
        <>
          <div className={styles.lineControl}>
            {/* Button to move the line up */}
            <div
              className={styles.lineControllers}
              onClick={() => {
                // Clone the blocks array to avoid mutation
                let _blocks = [...blocks];

                // Insert the current line before the previous line in the headerLines array
                _blocks[blockIndex].blockHeader.headerLines.splice(
                  lineIndex - 1,
                  0,
                  _blocks[blockIndex].blockHeader.headerLines[lineIndex]
                );

                // Log the updated headerLines array after adding a new index
                //console.log(_blocks[blockIndex].blockHeader.headerLines);

                // Remove the current line from its original position in the headerLines array
                _blocks[blockIndex].blockHeader.headerLines.splice(
                  lineIndex + 1,
                  1
                );

                // Log the updated headerLines array after removing the old index
                //console.log("_blocks[blockIndex].blockHeader.headerLines\n\n", _blocks[blockIndex].blockHeader.headerLines);

                // Update the state with the modified blocks array
                setBlocks(_blocks);
              }}
            >
              <ArrowCircleUp size={30} />
            </div>

            {/* Button to move the line down */}
            <div
              className={styles.lineControllers}
              onClick={() => {
                // Clone the blocks array to avoid mutation
                let _blocks = [...blocks];
                // Insert the current line after the next line in the headerLines array
                _blocks[blockIndex].blockHeader.headerLines.splice(
                  lineIndex + 2,
                  0,
                  _blocks[blockIndex].blockHeader.headerLines[lineIndex]
                );

                // Log the updated headerLines array after adding a new index
                //console.log(_blocks[blockIndex].blockHeader.headerLines);

                // Remove the current line from its original position in the headerLines array
                _blocks[blockIndex].blockHeader.headerLines.splice(
                  lineIndex,
                  1
                );

                // Log the updated headerLines array after removing the old index
                //console.log(_blocks[blockIndex].blockHeader.headerLines);

                // Update the state with the modified blocks array
                setBlocks(_blocks);
              }}
            >
              <ArrowCircleDown size={30} />
            </div>
            <div
              className={styles.deleteLine}
              onClick={(event) => {
                let _blocks = [...blocks];
                _blocks[blockIndex].blockHeader.headerLines.splice(
                  lineIndex,
                  1
                );
                setBlocks(_blocks);
              }}
            >
              <XCircle size={30} className={styles.deleteIcon} />
            </div>
          </div>
          <div className={styles.labels}>
            <div>
              <span>
                Rótulo
                <div className={styles.boldCheckbox}>
                  <Checkbox
                    onClick={() => {
                      let _blocks = [...blocks];
                      const headerLines =
                        _blocks[blockIndex].blockHeader.headerLines;

                      if (
                        !headerLines[lineIndex].values.hasOwnProperty(
                          "labelBold"
                        )
                      ) {
                        // If labelBold doesn't exist, create it and set it to true (or any default value)
                        headerLines[lineIndex].values.labelBold = true;
                      } else {
                        // Toggle the existing labelBold value
                        headerLines[lineIndex].values.labelBold =
                          !headerLines[lineIndex].values.labelBold;
                      }
                      console.log(_blocks);
                      setBlocks(_blocks);
                    }}                    
                    checked={lineValues.labelBold || false}
                  />{" "}
                  Negrito
                </div>
              </span>

              {languages.pt === true && (
                <div className={styles.languageField}>
                  <div>
                    <img
                      src={brazilIcon}
                      style={{
                        borderRadius: "20%",
                        position: "relative",
                        height: "95%",
                      }}
                    />
                  </div>
                  <div
                    style={{ width: "85%", marginLeft: "auto" }}
                    className={styles.afterLabel}
                  >
                    <Field
                      style={{ width: "100%" }}
                      value={lineValues.label.pt}
                      placeholder={"Digite o rótulo desejado..."}
                      id={"label" + (lineIndex + 1)}
                      onChange={(event) => {
                        let _blocks = [...blocks];
                        _blocks[blockIndex].blockHeader.headerLines[
                          lineIndex
                        ].values.label.pt = event.target.value;
                        console.log(
                          _blocks[blockIndex].blockHeader.headerLines
                        );
                        setBlocks(_blocks);
                      }}
                    ></Field>
                  </div>
                </div>
              )}

              {languages.en === true && (
                <div className={styles.languageField}>
                  <div>
                    <img
                      src={usIcon}
                      style={{
                        borderRadius: "20%",
                        position: "relative",
                        height: "95%",
                      }}
                    />
                  </div>
                  <div
                    style={{ width: "85%", marginLeft: "auto" }}
                    className={styles.afterLabel}
                  >
                    <Field
                      style={{ width: "100%" }}
                      value={lineValues.label.en}
                      placeholder={"Type the desired label..."}
                      id={"label" + (lineIndex + 1)}
                      onChange={(event) => {
                        let _blocks = [...blocks];
                        _blocks[blockIndex].blockHeader.headerLines[
                          lineIndex
                        ].values.label.en = event.target.value;
                        console.log(
                          _blocks[blockIndex].blockHeader.headerLines
                        );
                        setBlocks(_blocks);
                      }}
                    ></Field>
                  </div>
                </div>
              )}

              {languages.es === true && (
                <div className={styles.languageField}>
                  <div>
                    <img
                      src={spainIcon}
                      style={{
                        borderRadius: "20%",
                        position: "relative",
                        height: "95%",
                      }}
                    />
                  </div>
                  <div
                    style={{ width: "85%", marginLeft: "auto" }}
                    className={styles.afterLabel}
                  >
                    <Field
                      style={{ width: "100%" }}
                      value={lineValues.label.es}
                      placeholder={"Escribe la etiqueta deseada..."}
                      id={"label" + (lineIndex + 1)}
                      onChange={(event) => {
                        let _blocks = [...blocks];
                        _blocks[blockIndex].blockHeader.headerLines[
                          lineIndex
                        ].values.label.es = event.target.value;
                        console.log(
                          _blocks[blockIndex].blockHeader.headerLines
                        );
                        setBlocks(_blocks);
                      }}
                    ></Field>
                  </div>
                </div>
              )}
            </div>

            <div>
              <span>
                Texto{" "}
                <div className={styles.boldCheckbox}>
                  <Checkbox
                    onClick={() => {
                      let _blocks = [...blocks];
                      const headerLines =
                        _blocks[blockIndex].blockHeader.headerLines;

                      if (
                        !headerLines[lineIndex].values.hasOwnProperty(
                          "textBold"
                        )
                      ) {
                        // If labelBold doesn't exist, create it and set it to true (or any default value)
                        headerLines[lineIndex].values.textBold = true;
                      } else {
                        // Toggle the existing labelBold value
                        headerLines[lineIndex].values.textBold =
                          !headerLines[lineIndex].values.textBold;
                      }
                      console.log(_blocks);
                      setBlocks(_blocks);
                    }}
                    checked={lineValues.textBold || false}
                  />{" "}
                  Negrito
                </div>
              </span>
              {languages.pt == true && (
                <div className={styles.languageField}>
                  <Field
                    value={lineValues.text.pt}
                    placeholder={"Digite o texto desejado..."}
                    id={"text" + (lineIndex + 1)}
                    onChange={(event) => {
                      let _blocks = [...blocks];
                      _blocks[blockIndex].blockHeader.headerLines[
                        lineIndex
                      ].values.text.pt = event.target.value;
                      setBlocks(_blocks);
                    }}
                  ></Field>
                </div>
              )}

              {languages.en == true && (
                <div className={styles.languageField}>
                  <Field
                    value={lineValues.text.en}
                    placeholder={"Type the desired text..."}
                    id={"text" + (lineIndex + 1)}
                    onChange={(event) => {
                      let _blocks = [...blocks];
                      _blocks[blockIndex].blockHeader.headerLines[
                        lineIndex
                      ].values.text.en = event.target.value;
                      setBlocks(_blocks);
                    }}
                  ></Field>
                </div>
              )}

              {languages.es == true && (
                <div className={styles.languageField}>
                  <Field
                    value={lineValues.text.es}
                    placeholder={"Escribe el texto deseado..."}
                    id={"text" + (lineIndex + 1)}
                    onChange={(event) => {
                      let _blocks = [...blocks];
                      _blocks[blockIndex].blockHeader.headerLines[
                        lineIndex
                      ].values.text.es = event.target.value;
                      setBlocks(_blocks);
                    }}
                  ></Field>
                </div>
              )}
            </div>
          </div>
        </>
      );
    }
    if (lineType == "teacher") {
      let id;
      if (lineValues.id) {
        id = lineValues.id;
      }

      headerLines.push(
        <>
          <div className={styles.lineControl}>
            <div
              className={styles.lineControllers}
              onClick={() => {
                let _blocks = [...blocks];

                _blocks[blockIndex].blockHeader.headerLines.splice(
                  lineIndex - 1,
                  0,
                  _blocks[blockIndex].blockHeader.headerLines[lineIndex]
                );
                console.log("ADD INDEX NOVO!");
                console.log(_blocks[blockIndex].blockHeader.headerLines);
                _blocks[blockIndex].blockHeader.headerLines.splice(
                  lineIndex + 1,
                  1
                );
                console.log("EXCLUINDO INDEX ANTIGO!");
                console.log(_blocks[blockIndex].blockHeader.headerLines);
                setBlocks(_blocks);
              }}
            >
              <ArrowCircleUp size={30} />
            </div>
            <div
              className={styles.lineControllers}
              onClick={() => {
                let _blocks = [...blocks];

                _blocks[blockIndex].blockHeader.headerLines.splice(
                  lineIndex + 2,
                  0,
                  _blocks[blockIndex].blockHeader.headerLines[lineIndex]
                );
                console.log("ADD INDEX NOVO!");
                console.log(_blocks[blockIndex].blockHeader.headerLines);
                _blocks[blockIndex].blockHeader.headerLines.splice(
                  lineIndex,
                  1
                );
                //console.log("EXCLUINDO INDEX ANTIGO!")
                console.log(_blocks[blockIndex].blockHeader.headerLines);
                setBlocks(_blocks);
              }}
            >
              <ArrowCircleDown size={30} />
            </div>
            <div
              className={styles.deleteLine}
              onClick={(event) => {
                let _blocks = [...blocks];
                _blocks[blockIndex].blockHeader.headerLines.splice(
                  lineIndex,
                  1
                );
                setBlocks(_blocks);
              }}
            >
              <XCircle size={30} className={styles.deleteIcon} />
            </div>
          </div>

          <div className={styles.designations}>
            <div>
              <span>
                Designação<span style={{ color: "red" }}>*</span>
              </span>
              <div className={styles.inputContainer}>
                <MagnifyingGlass className={styles.searchIcon} size={18} />
                <Select
                  value={setDefaultDesignationValue(lineValues)}
                  options={designations}
                  placeholder="Digite a designação desejada..."
                  className={styles.searchField}
                  onChange={(event) =>
                    selectDesignationValueHeader(event, blockIndex, lineIndex)
                  }
                  styles={customStyles}
                />
              </div>
            </div>
            <div>
              <span>
                Usuário<span style={{ color: "red" }}>*</span>
              </span>
              <div className={styles.inputContainer}>
                <MagnifyingGlass className={styles.searchIcon} size={18} />
                <Select
                  value={setDefaultUserValue(id)}
                  options={usersList}
                  placeholder="Digite o usuário desejado..."
                  className={styles.searchField_view_index}
                  onChange={(event) => {                    
                    setUserPhoto(event)  
                    selectUserValueHeader(event, blockIndex, lineIndex);
                  }}
                  styles={customStyles}
                />
              </div>
              {userPhoto !== undefined ?
                    <div style={{margin:'6px 0'}}>
                    <span>Ocultar Foto</span>
                        <Checkbox 
                            onClick={() => {
                            if(removePhoto === false){
                                setNoPhoto(userPhoto, blockIndex, lineIndex)
                            } else {
                                selectUserValueHeader(userPhoto, blockIndex, lineIndex); 
                            }}} 
                        />
                        </div>: null
                }
            </div>
          </div>
        </>
      );
    }
    return headerLines;
  };

  const buildHead = (blockHeaderKey, blockHeaderValue, blockIndex) => {
    let headerLines = [];
    if (blockHeaderKey == "title") {
      headerLines.push(
        <div>
          <div
            className={styles.deleteLine}
            onClick={(event) => {
              let _blocks = [...blocks];
              delete _blocks[blockIndex].blockHeader.title;
              setBlocks(_blocks);
            }}
          >
            <XCircle size={18} className={styles.deleteIcon} />
          </div>
          <div>
            <span>
              Título<span style={{ color: "red" }}>*</span>
            </span>
            <Field
              value={blocks[blockIndex].blockHeader.title}
              placeholder={"Digite o título desejado..."}
              id={"title" + (blockIndex + 1)}
              onChange={(event) => {
                let _blocks = [...blocks];
                _blocks[blockIndex].blockHeader.title = event.target.value;
                setBlocks(_blocks);
              }}
            ></Field>
          </div>
        </div>
      );
    }
    return headerLines;
  };

  function removeBlock() {
    setRemoveBlockConfirmation(false);
    let _blocks = [...blocks];
    _blocks.splice(blockIdToRemove, 1);
    setBlocks(_blocks);
  }

  const handleSort = () => {
    //duplicates items list to deal with the changes
    let _blocks = [...blocks];

    //remove and save the dragged item content
    const draggedItemContent = _blocks.splice(dragItem.current, 1)[0];

    //switch the position
    _blocks.splice(dragOverItem.current, 0, draggedItemContent);

    dragItem.current = null;
    dragOverItem.current = null;
    //console.log(_blocks)
    //set the actual array
    setBlocks(_blocks);
  };

  function verifyElementIsInListOfObjects(listToVeirfy, itemToFind) {
    let itemIsPresent = false;

    listToVeirfy.map((i) => {
      if (i.value == itemToFind) {
        itemIsPresent = true;
      }
    });
    return itemIsPresent;
  }

  return (
    <div>
      <div className={styles.headerContainer}>
        {!!blocks.length ? <span className={styles.title}>Blocos</span> : null}
        <Button
          className={styles.buttonOrange + " " + styles.addBlock}
          onClick={addBlock}
          icon={<PlusCircle size={18} className={styles.addIcon} />}
        >
          <span>Adicionar Bloco</span>
        </Button>
      </div>
      {!!blocks.length
        ? blocks.map((block, blockIndex) => {
            return (
              <div
                key={blockIndex}
                className={styles.blocks}
                draggable
                onDragStart={(event) => {
                  dragItem.current = blockIndex;
                }}
                onDragEnter={(event) => (dragOverItem.current = blockIndex)}
                onDragEnd={() => {
                  handleSort();
                }}
                onDragOver={(event) => event.preventDefault()}
              >
                <div className={styles.dragContainer}>
                  <DotsSixVertical className={styles.dragIcon} size={32} />
                </div>
                {/* Accordion make with MUI */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={blockIndex}
                    id={`panel + ${blockIndex}`}
                  >
                    <Typography>
                      <div className="header">
                        <span style={{ display: "flex", alignItems: "center" }}>
                          Cabeçalho da Sessão {blockIndex + 1}
                        </span>
                        <p className={styles.subtitle}>
                          {" "}
                          {blocks[blockIndex].blockHeader.title}
                        </p>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className={`${styles.row} ${styles.collapsible}`}>
                        <div
                          className={`${styles.headerBlock} ${styles.content}`}
                        >
                          <Field
                            defaultValue={() =>
                              blocks[blockIndex].blockHeader.title == null ||
                              undefined
                                ? `Bloco ${blockIndex + 1}`
                                : blocks[blockIndex].blockHeader.title
                            }
                            value={
                              blocks[blockIndex].blockHeader.title == null ||
                              undefined
                                ? `Bloco ${blockIndex + 1}`
                                : blocks[blockIndex].blockHeader.title
                            }
                            placeholder={"Digite o título do bloco..."}
                            id={"blockTitle"}
                            onChange={(event) => {
                              let _blocks = [...blocks];
                              _blocks[blockIndex].blockHeader.title =
                                event.target.value;
                              setBlocks(_blocks);
                            }}
                          ></Field>
                          <div className={styles.headLines}>
                            {!!block.blockHeader.headerLines ? (
                              <div key={blockIndex}>
                                {block.blockHeader.headerLines.map(
                                  (line, index) => {
                                    let lineType = line.type;
                                    return buildHeader(
                                      lineType,
                                      line.values,
                                      blockIndex,
                                      index
                                    );
                                  }
                                )}
                              </div>
                            ) : null}
                          </div>
                          <ButtonDropdown
                            className={
                              styles.buttonOrange + " " + styles.addLine
                            }
                            label="Adicionar Linha"
                            icon={
                              <PlusCircle
                                size={18}
                                className={styles.addIcon}
                              />
                            }
                            listOfItems={listOfItemsButton}
                            blockIndex={blockIndex}
                          ></ButtonDropdown>
                        </div>
                        <div className={styles.deleteBlock}>
                          <ConfirmationModal
                            id={"deleteBlock"}
                            iconComponent={
                              <XCircle
                                size={18}
                                className={styles.deleteIcon}
                              />
                            }
                            title={"Deseja realmente remover este bloco?"}
                            onClick={() => {
                              setBlockIdToRemove(blockIndex);
                              setRemoveBlockConfirmation(true);
                            }}
                            open={removeBlockConfirmation}
                            onOk={() => removeBlock(blockIndex)}
                            onCancel={() => {
                              setRemoveBlockConfirmation(false);
                            }}
                          />
                        </div>
                        <div className={styles.pattern}>
                          <span>
                            Padrão da Sessão:
                            <span style={{ color: "red" }}>*</span>
                          </span>
                          <div
                            id={"blockPattern" + blockIndex + 1}
                            className={styles.inputContainer}
                          >
                            <MagnifyingGlass
                              className={styles.searchIcon}
                              size={18}
                            />

                            <Select
                              value={setDefaultPatternValue(block.collection)}
                              options={patterns}
                              placeholder="Digite o padrão desejado..."
                              className={styles.searchField}
                              onChange={(event) =>
                                selectPatternValue(event, blockIndex)
                              }
                              styles={customStyles}
                            />
                          </div>
                        </div>
                        <div className={styles.academicArea}>
                          <span>
                            Área Acadêmica
                            <span style={{ color: "red" }}>*</span>
                          </span>
                          <div
                            id={"blockAcademic" + blockIndex + 1}
                            className={styles.inputContainer}
                          >
                            {console.log(fieldList)}
                            <MagnifyingGlass
                              className={styles.searchIcon}
                              size={18}
                            />
                            <Select
                              value={setDefaultFieldValue(block.field)}
                              options={fieldList}
                              placeholder="Digite a área desejada..."
                              className={styles.searchField}
                              onChange={(event) =>
                                selectFieldValue(event, blockIndex)
                              }
                              styles={customStyles}
                            />
                          </div>
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default ManageQuestionnaire;
