import Alert from "components/Alert/Alert";
import { unarchiveAssessmentById } from "components/ArchivedAssessmentLists/archivedAssessmentsServices";
import Button from "components/Button/button";
import { UnarchiveIcon } from "components/Icon/Icon";
import Layout from "components/Layout/layout";
import PageTitle from "components/PageTitle/pageTitle";
import Pagination from "components/Pagination/pagination";
import TableControl from "components/TableControl/tableControl";
import { ArrowCircleLeft, ArrowsDownUp, Eye } from "phosphor-react";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import ReactLoading from "react-loading";
import Loading from "components/Loading/loading";

import { useNavigate } from "react-router-dom";
import { addNewAlert } from "Services/alertsManagement";

import styles from "./archivedReportsList.module.scss";
import { getAllArchivedReports } from "./archivedReportsServices";
import MainStyle from 'components/UI/main.module.scss';

const ArchivedReportsList = () => {
  const navigate = useNavigate();
  const [memResultList, setMemResultList] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tablePagination, setTablePagination] = useState([]);
  const [slice, setSlice] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [alerts, setAlerts] = useState([]);
  const [listIsSorted, setListIsSorted] = useState(false);

  function sortObjByKey(key) {
    let _resultList = [...resultList];
    function compare(a, b) {
      a = a[key];
      b = b[key];
      var type =
        typeof a === "string" || typeof b === "string" ? "string" : "number";
      var result;
      if (type === "string") result = a.localeCompare(b);
      else result = a - b;
      return result;
    }

    //Check if list was ever sorted during the session
    if (memResultList.length == 0 && listIsSorted == false) {
      //Saves original user API response to memmory
      setMemResultList(resultList);

      //sort user list
      _resultList = _resultList.sort(compare);
      setListIsSorted(true);
    }

    //In case list was already sorted before
    else {
      //In case list isn't currently sorted
      if (listIsSorted == false && memResultList.length > 0) {
        _resultList = _resultList.sort(compare);
        setListIsSorted(true);
      }
      //In case list is currently sorted
      else {
        _resultList = memResultList;
        setListIsSorted(false);
      }
    }

    setResultList(_resultList);
    return _resultList;
  }

  const unarchiveReport = (id) => {
    let updateAlertList = "";
    let message = "";
    setLoading(true);

    unarchiveAssessmentById(id).then((response) => {
      let messageResponse = response.message;
      if (messageResponse.includes("successfully unarchived")) {
        message = "Relatório desarquivado com sucesso!";
        updateAlertList = addNewAlert([], { message, type: "success" });
        getAllArchivedReports(setResultList, setMemResultList, setLoading);
      } else {
        setLoading(false);
        message = "Ocorreu um erro ao desarquivar o relatório.";
        updateAlertList = addNewAlert([], {
          message,
          type: "danger",
        });
      }
      setAlerts(updateAlertList);
    });
  };

  useEffect(() => {
    if (resultList.length == 0) {
      getAllArchivedReports(setResultList, setMemResultList, setLoading);
    }
  }, []);

  return (
    <Layout>
      <div>
        <Button
          className={styles.backButton + " " + "secondaryOrange"}
          onClick={() => navigate("/reports")}
        >
          <ArrowCircleLeft size={22} className={styles.backIcon} />
          <span className={styles.backLabel}>Voltar</span>
        </Button>
        <div className={styles.pageHeader}>
          <PageTitle center={true}>Relatórios Arquivados</PageTitle>
        </div>
        {loading == true ? (
          <div className={styles.loading}>
            <div className="large">Carregando informações</div>
            <center>
              <ReactLoading
                type="bubbles"
                color="#0064a2"
                height={50}
                width={175}
              />
            </center>
          </div>
          // <Loading />
        ) : (
          <div>
            <TableControl
              resultList={resultList}
              setResultList={setResultList}
              originalListTable={memResultList}
              setTablePagination={setTablePagination}
              setSlice={setSlice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              showByPageDefault={10}
              searchPlaceholder={"Buscar Relatório"}
              hideBulkActions={true}
              hideFilter={true}
            ></TableControl>
            <Pagination
              tablePagination={tablePagination}
              slice={slice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></Pagination>
            {alerts.map((i, k) => {
              return <Alert message={i.message} type={i.type} />;
            })}
            <table className={styles.table}>
              <thead>
                <tr style={{ backgroundColor: "white" }}>
                  <th
                    style={{
                      width: "40vw",
                      textAlign: "left",
                      paddingLeft: "30px",
                    }}
                  >
                    Título
                    <ArrowsDownUp
                      onClick={() => sortObjByKey("title")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "20%" }}>
                    Final da Coleta
                    <ArrowsDownUp
                      onClick={() => sortObjByKey("finalDate")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "20%" }}>
                    Respostas
                    <ArrowsDownUp
                      onClick={() => sortObjByKey("submissions")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "20%" }}>
                    Status
                    <ArrowsDownUp
                      onClick={() => sortObjByKey("statusLabel")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "10%" }}>Ações</th>
                </tr>
              </thead>
              <tbody>
                {slice.map((item, i) => {
                  let id = item.id;
                  return (
                    <tr key={i} id={"actions" + i} className={styles.tableRow}>
                      <td
                        style={{maxWidth: '250px'}}
                        className={
                          styles.reportRow +
                          " " +
                          styles.reportTitle +
                          " " +
                          styles.rowTitle + 
                          " " + 
                          MainStyle.text_truncate
                        }
                      >
                        {item.title}
                      </td>
                      <td className={styles.reportRow}>{item.finalDate}</td>
                      <td
                        className={styles.reportRow}
                        style={{ fontWeight: "600" }}
                      >
                        {item.submissions}
                      </td>
                      <td className={styles.reportRow}>
                        <span
                          className={
                            item.itemStatus == "ready"
                              ? styles.readylabel
                              : styles.closedLabel
                          }
                        >
                          {item.statusLabel}
                        </span>
                      </td>
                      <td className={styles.actionsContainer}>
                        <UnarchiveIcon
                          size={26}
                          className={styles.unarchiveReportButton}
                          onClick={() => unarchiveReport(id)}
                        ></UnarchiveIcon>
                        <div className={styles.helpPopupUnarchive}>
                          Desarquivar Relatório
                        </div>
                        <Eye
                          size={28}
                          className={styles.showReportButton}
                          onClick={() => window.open("/report/" + id, '_blank').focus()}
                        />
                        <div className={styles.helpPopupShowReport}>
                          Ver Relatório
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ArchivedReportsList;
