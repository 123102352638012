/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f5b22dc2-b598-412f-8a9d-13e4c5392bf0",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Uj92RUsLe",
    "aws_user_pools_web_client_id": "38pohfaqj3jl81fp5djui0rket",
    "oauth": {
        "domain": "isesurvey.auth.us-east-1.amazoncognito.com", // Your Cognito domain
        "scope": ["email", "openid", "phone"],
        "redirectSignIn": "https://survey2.0.ise.org.br/dashboard",
        "redirectSignOut": "https://survey2.0.ise.org.br/",
        "responseType": "code",
        "options": {
            "AdvancedSecurityDataCollectionFlag": true
        }
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
