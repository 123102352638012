import { verifySession } from "Services/authenticationServices";
import { formatDate } from "Services/globalServices";

const today = new Date().toISOString().split('T')[0];

const oneYearAgo = new Date();
oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
const formattedOneYearAgo = oneYearAgo.toISOString().split('T')[0];

const stage = process.env.REACT_APP_STAGE;
const baseReportsURL =
  `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/orders/submissions/1`;

const baseOldReportsURL =
`https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/orders/v1?date_from=${formattedOneYearAgo}&date_to=${today}`;

export async function getAllReports(setList, setMemList, setLoading) {
  const questions = await getReports();
  setList(questions);
  setMemList(questions);
  setLoading(false);
}

export async function getAllOldReports(setList, setMemList, setLoading) {
  const questions = await getOldReports();
  setList(questions);
  setMemList(questions);
  setLoading(false);
}

export async function getFilteredOldReports(startDate, endDate, setResultList, setMemList, setLoading) {
  const questions = await getOldReportsFiltered(startDate, endDate);
  setResultList(questions);
  setMemList(questions);
  setLoading(false);
}

export async function getFilteredOrdersAndSetState(queryString, setList, setMemList, setLoading) {
  const orders = await getFilteredOrders(queryString);
  setList(orders);
  setMemList(orders);
  setLoading(false);
}

export async function getReports() {
  let jwtToken = await verifySession();
  let reportsData;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };

  const formatStatus = (status) => {
    if (status == "closed") {
      return "encerrada";
    } else if (status == "ready") {
      return "pronta";
    }
  };

  await fetch(baseReportsURL, parameters)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data) {
        if (data.length > 0) {
          reportsData = data;
          console.log(data);
          reportsData.map((report, index) => {
            reportsData[index].initialDate = formatDate(
              report.meta.opening,
              "DD/MM/YYYY"
            );
            reportsData[index].finalDate = formatDate(
              report.meta.closing,
              "DD/MM/YYYY"
            );
            reportsData[index].statusLabel = formatStatus(report.itemStatus);
          });
          console.log(data);
        }
      }    
    });

  return reportsData;
}

export async function getFilteredOrders(queryString , setLoading) {
  let jwtToken = await verifySession();
  let ordersData;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };

  const formatStatus = (status) => {
    if (status == "closed") {
      return "encerrada";
    } else if (status == "ready") {
      return "pronta";
    }
  };

  await fetch(`https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/filter/order?${queryString}`, parameters)
    .then((response) => {
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      ordersData = data.orders;
      ordersData.map((report, index) => {
        // console.log(ordersData[index]);
        ordersData[index].initialDate = formatDate(
          report.meta.opening,
          "DD/MM/YYYY"
        );
        ordersData[index].finalDate = formatDate(
          report.meta.closing,
          "DD/MM/YYYY"
        );
        ordersData[index].statusLabel = formatStatus(report.itemStatus);
      });
      console.log(data);
    })
    .catch((error) => {
      console.error('Erro na chamada da API:', error);
    });

  return ordersData;
}

export async function getOldReports() {
  let jwtToken = await verifySession();
  let reportsData;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };

    await fetch(baseOldReportsURL, parameters)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data) {
        if (data.length > 0) {
          reportsData = data.orders;    
          reportsData.map((report, index) => {
            reportsData[index].Data = formatDate(
              report.Data,
              "DD/MM/YYYY"
            );

          });
          console.log(data);
        }
      }
    });

  return reportsData;
}

export async function getOldReportsFiltered(startDate, endDate) {
  let jwtToken = await verifySession();
  let reportsData;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };
    console.log(`https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/orders/v1?date_from=${startDate}&date_to=${endDate}`)

    await fetch(`https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/orders/v1?date_from=${startDate}&date_to=${endDate}`, parameters)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      reportsData = data.orders;    
      reportsData.map((report, index) => {
        reportsData[index].Data = formatDate(
          report.Data,
          "DD/MM/YYYY"
        );
        
      });
    });

  return reportsData;
}


