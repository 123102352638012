import { UploadOutlined } from "@ant-design/icons";
import { getAssetsItems } from "components/AssetsLists/assetsListsServices";
import Button from "components/Button/button";
import CloseButton from "components/CloseButton/closeButton";
import { Image, MagnifyingGlass } from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";

import styles from "./uploadImageField.module.scss";
import Field from "components/Field/field";

export const Thumbnail = ({ url, setUrl, width }) => {
  const validateURL = (url) => {
    return !!url && url;
  };
  return (
    <div class={styles.thumbnailContainer}>
      {url == null ? (
        <div>
          <Image className={styles.thumbImg} />
        </div>
      ) : (
        <div>
          <div className={styles.closeButton}>
            <a onClick={() => setUrl({ title: null, content: null })}>
              <CloseButton color={"#696a6b"} />
            </a>
          </div>
          <img
            src={validateURL(url)}
            style={{ width: width }}
            className={styles.thumbImg}
          />
        </div>
      )}
    </div>
  );
};

export const SelectImageField = ({
  image,
  setImage,
  id,
  imageSelected,
  setImageSelected,
  Dataset_Image,
}) => {

  /*
        Added Dataset_Image as prop in 2024-10-21.
        If you don't pass a prop o dataset images to select the program
        will call for another set in the server called in the async method.
        in the useEffect.
  */

  const [isMediaPopupOpenned, setIsMediaPopupOpenned] = useState(false);
  const [imageList, setImageList] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredImages, setFilteredImages] = useState(imageList);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if(imageList) {
      if(imageList.length > 0) {
        const filtered = imageList.filter((image) =>
          image.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
        if (isMounted.current) {
          setFilteredImages(filtered);
        }
      }
    }
  }, [searchTerm, imageList]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  function controlPopUp() {
    if (isMediaPopupOpenned == true) {
      setIsMediaPopupOpenned(false);
      setImageSelected({ title: null, content: null });
    } else {
      setIsMediaPopupOpenned(true);
    }
  }

  const getImageList = async () => {
    let dataImage = await getAssetsItems("image");
    setImageList(dataImage);
  };

  useEffect(() => {
    if(Dataset_Image != undefined && Dataset_Image.length >  0) {  
      setImageList(Dataset_Image);
    }
    else {
      getImageList();
    }
  });

  const handleClass = (content) => {
    const isSelected =
      imageSelected.content == content ? styles.imageSelected : null;
    return styles.imagePreview + " " + isSelected;
  };

  const customStyles = {
    control: (styles) => ({ ...styles, minHeight: "32px" }),
    input: (styles) => ({ ...styles, height: "30px" }),
    indicatorsContainer: (styles) => ({ ...styles, height: "30px" }),
    valueContainer: (styles) => ({
      ...styles,
      height: "30px",
      padding: "0 10px",
      marginLeft: "16px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
  };

  return (
    <>
      <div className={styles.uploadImageField}>
        <div className={styles.uploadImageThumb}>
          <Thumbnail
            url={!!image ? image.content : ""}
            setUrl={setImage}
            width="6vw"
          ></Thumbnail>
        </div>
        <div className={styles.uploadButton}>
          <label
            for={id}
            className={styles.uploadImageLabel}
            onClick={() => {
              setSearchTerm("");
              setIsMediaPopupOpenned(true);
            }}
          >
            Selecionar Imagem
          </label>
        </div>
      </div>
      {isMediaPopupOpenned == true ? (
        <>
          <div className={styles.mediaPopUp}>
            <div className={styles.header}>Selecione a imagem</div>
            <div className={styles.inputContainer}>
              <Field
                placeholder="Digite o nome da imagem"
                className={styles.searchField}
                onChange={handleSearchChange}
                styles={customStyles}
              />
            </div>
            <div className={styles.popupRow}>
              {filteredImages !== imageList
                ? filteredImages.map((image) => {
                    return (
                      <img
                        src={image.content}
                        alt={image.title}
                        className={handleClass(image.content)}
                        onClick={() => {
                          setImageSelected({
                            title: image.title,
                            content: image.content,
                            id: image.id,
                          });
                        }}
                      ></img>
                    );
                  })
                : imageList.map((image) => {
                    return (
                      <img
                        src={image.content}
                        alt={image.title}
                        className={handleClass(image.content)}
                        onClick={() => {
                          setImageSelected({
                            title: image.title,
                            content: image.content,
                            id: image.id,
                          });
                        }}
                      ></img>
                    );
                  })}
            </div>
            <div className={styles.popupFooter}>
              <Button
                className={styles.tabsButtonFooter + " " + "primaryOrange"}
                onClick={() => {
                  setImage(imageSelected);
                  setIsMediaPopupOpenned(false);
                  setImageSelected({ title: null, content: null, id: null });
                  setSearchTerm("");
                }}
              >
                Selecionar
              </Button>
              <Button
                className={styles.tabsButtonFooter + " " + "secondaryOrange"}
                onClick={() => {
                  setIsMediaPopupOpenned(false);
                  setImageSelected({ title: null, content: null });
                }}
              >
                Cancelar
              </Button>
            </div>
          </div>
          <div className={styles.overlay} onClick={() => controlPopUp()}></div>
        </>
      ) : (
        <div className={styles.overlayOff}></div>
      )}
    </>
  );
};

const UploadImageField = ({ image, setImage, id }) => {
  // const [image, setImage] = useState(null);

  const changeImage = (event) => {
    // console.log(event);
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  return (
    <div className={styles.uploadImageField}>
      <div className={styles.uploadImageThumb}>
        <Thumbnail url={image} setUrl={setImage} width="6vw"></Thumbnail>
      </div>
      <div className={styles.uploadButton}>
        <label for={id} className={styles.uploadImageLabel}>
          Carregar Imagem
          <UploadOutlined style={{ paddingLeft: "5px", fontSize: "18px" }} />
          <input
            type="file"
            id={id}
            className={styles.inputUpload}
            onChange={changeImage}
          />
        </label>
      </div>
    </div>
  );
};

export default UploadImageField;
