import styles from "./notFound.module.scss";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo-header.png";

const NotFound = () => {

  const navigate = useNavigate();

  return (
    <div className={styles.notFound}>
      <img src={logo} alt="Logo ISE" className={styles.headerLogo}></img>
      <span className={styles.message} >Página não encontrada, por favor retorne à página de <span className={styles.redirect} onClick={() => navigate('/login')}>login</span> ou escaneie novamente o QR Code da avaliação</span>
      <span className={styles.messageSubtitle}>Caso o erro persista, entre em contato com a equipe de suporte</span>
    </div>
  );
};

export default NotFound;
