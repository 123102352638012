import { verifySession } from "Services/authenticationServices";
import { formatDate } from "Services/globalServices";

const stage = process.env.REACT_APP_STAGE;
const baseAssetsURL =
  `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/assets`;

export async function getAllArchivedAssets(
  setList,
  setMemList,
  setLoading,
  asset
) {
  const assets = await getArchivedAssets(asset);
  setList(assets);
  setMemList(assets);
  setLoading(false);
}

export async function getArchivedAssets(asset) {
  let jwtToken = await verifySession();
  let assets;
  let assetUrl = baseAssetsURL + "/" + asset + "/archive";
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };

  await fetch(assetUrl, parameters)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data) {
        if (data.length > 0) {
          assets = data;
          assets.map((asset) => {
            asset.date = formatDate(asset.created_At, "DD/MM/YYYY");
          });
        }
      }
    });

  return assets;
}

export async function unarchiveAssetById(asset, id) {
  let apiResponse;
  let getJwt = await verifySession();

  let unarchiveAssetURL = baseAssetsURL + "/" + asset + "/archive/" + id;

  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
    };

    await fetch(unarchiveAssetURL, parameters)
      .then((response) => response.json())
      .then((data) => {
        apiResponse = data;
      });
  };

  await fetchData(getJwt);
  return apiResponse;
}

export async function deleteAssetById(asset, id) {
  let apiResponse;
  let getJwt = await verifySession();

  let unarchiveAssetURL = baseAssetsURL + "/" + asset + "/archive/" + id;

  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
    };

    await fetch(unarchiveAssetURL, parameters)
      .then((response) => response.json())
      .then((data) => {
        apiResponse = data;
      });
  };

  await fetchData(getJwt);
  return apiResponse;
}
