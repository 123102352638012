import Alert from "components/Alert/Alert";
import Button from "components/Button/button";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import Field from "components/Field/field";
import Layout from "components/Layout/layout";
import PageTitle from "components/PageTitle/pageTitle";
import Pagination from "components/Pagination/pagination";
import TableControl from "components/TableControl/tableControl";
import UploadImageField from "components/UploadImageField/uploadImageField";
import {
  Archive,
  ArrowsDownUp,
  Image,
  Palette,
  Pencil,
  PlusCircle,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router";
import { addNewAlert } from "Services/alertsManagement";
import { imageToBase64 } from "Services/globalServices";

import styles from "./assetsLists.module.scss";
import {
  archiveAssetById,
  createAssetItem,
  getAllAssetsItems,
  updateAssetById,
} from "./assetsListsServices";
import Loading from "components/Loading/loading";
import { useAuthenticator } from "@aws-amplify/ui-react";

const AssetsLists = () => {
  const [currentButton, setCurrentButton] = useState("image");
  const [loading, setLoading] = useState(true);
  const [memResultList, setMemResultList] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [tablePagination, setTablePagination] = useState([]);
  const [slice, setSlice] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPopupOpenned, setIsPopupOpenned] = useState(false);
  const [newTitle, setNewIitle] = useState();
  const [newImageItem, setNewImageItem] = useState();
  const [alertsPopup, setAlertsPopup] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [colorCod, setColorCod] = useState();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [listIsSorted, setListIsSorted] = useState(false);
  const [isEditingItem, setIsEditingItem] = useState(false);
  const [itemIdToEdit, setItemIdToEdit] = useState("");
  const [archiveConfirmationModal, setArchiveConfirmationModal] =
    useState(false);

  const { user } = useAuthenticator((context) => [context.user]);
  const capabilities = localStorage.getItem("capabilities#" + user.username);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (capabilities.includes("cadastros")) {
        setLoading(true);
        await getAllAssetsItems(
          setResultList,
          setMemResultList,
          setLoading,
          currentButton
        );
      } else {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentButton, capabilities]);

  const handleTabClass = (button) => {
    let tabsButtonClass =
      button === currentButton ? styles.tabsButtonSelected : "secondaryBlue";
    return styles.tabsButton + " " + tabsButtonClass;
  };

  const sortObjByKey = (key) => {
    let sortedList = [...resultList];
    sortedList.sort((a, b) => {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    });
    setResultList(sortedList);
  };

  const triggerEditItem = (id) => {
    const item = resultList.find((item) => item.id === id);
    if (item) {
      setIsEditingItem(true);
      setItemIdToEdit(id);
      setNewIitle(item.title);
      if (currentButton === "image") {
        setNewImageItem(item.content);
      } else if (currentButton === "color") {
        setColorCod(item.content);
      }
      setIsPopupOpenned(true);
    }
  };

  const archiveItem = async () => {
    try {
      await archiveAssetById(currentButton, itemIdToEdit);
      setArchiveConfirmationModal(false);
      const updatedList = resultList.filter((item) => item.id !== itemIdToEdit);
      setResultList(updatedList);
      addNewAlert(alerts, { message: "Item arquivado com sucesso!", type: "success" });
    } catch (error) {
      addNewAlert(alerts, { message: "Erro ao arquivar o item!", type: "danger" });
    }
  };

  const addNewItem = async () => {
    try {
      let content;
      if (currentButton === "image") {
        content = await imageToBase64(newImageItem);
      } else {
        content = colorCod;
      }

      const newItem = { title: newTitle, content };
      await createAssetItem(newItem, currentButton);
      setIsPopupOpenned(false);
      await getAllAssetsItems(setResultList, setMemResultList, setLoading, currentButton);
      setNewIitle("");
      setNewImageItem("");
      setColorCod("");
    } catch (error) {
      addNewAlert(alertsPopup, { message: "Erro ao criar item!", type: "danger" });
    }
  };

  const editItem = async () => {
    try {
      let content;
      if (currentButton === "image") {
        content = await imageToBase64(newImageItem);
      } else {
        content = colorCod;
      }

      const updatedItem = { title: newTitle, content };
      await updateAssetById(updatedItem, currentButton, itemIdToEdit);
      setIsPopupOpenned(false);
      await getAllAssetsItems(setResultList, setMemResultList, setLoading, currentButton);
      setIsEditingItem(false);
      setItemIdToEdit("");
      setNewIitle("");
      setNewImageItem("");
      setColorCod("");
    } catch (error) {
      addNewAlert(alertsPopup, { message: "Erro ao editar item!", type: "danger" });
    }
  };

  const controlPopUp = () => {
    setIsPopupOpenned(!isPopupOpenned);
    setNewIitle("");
    setNewImageItem("");
    setColorCod("");
    setDisplayColorPicker(false);
    setAlertsPopup([]);
  };

  let content;

  switch (true) {
    case loading:
      content = (
        <div className={styles.loading}>
          <div className="large">Carregando informações</div>
          <center>
            <ReactLoading
              type="bubbles"
              color="#0064a2"
              height={50}
              width={175}
            />
          </center>
        </div>
      );
      break;
    case capabilities.includes("cadastros"):
      content = (
        <div>
          <TableControl
            resultList={resultList}
            setResultList={setResultList}
            originalListTable={memResultList}
            setTablePagination={setTablePagination}
            setSlice={setSlice}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            showByPageDefault={10}
            searchPlaceholder={"Buscar Item"}
            hideBulkActions={true}
            hideFilter={true}
          ></TableControl>
          <Pagination
            tablePagination={tablePagination}
            slice={slice}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          ></Pagination>
          {alerts.map((i, k) => (
            <Alert message={i.message} type={i.type} key={k} />
          ))}
          <table className={styles.table}>
            <thead>
              <tr style={{ backgroundColor: "white" }}>
                <th style={{ width: "40vw" }} className={styles.tableTitle}>
                  Título
                  <ArrowsDownUp
                    onClick={() => sortObjByKey("title")}
                    className={styles.arrowsDownUp}
                    size={16}
                  />
                </th>
                {currentButton === "image" && (
                  <th style={{ width: "25%" }}>Prévia</th>
                )}
                {currentButton === "color" && (
                  <th style={{ width: "25%" }}>Código da Cor</th>
                )}
                <th style={{ width: "30%" }}>
                  Data
                  <ArrowsDownUp
                    onClick={() => sortObjByKey("created_At")}
                    className={styles.arrowsDownUp}
                    size={16}
                  />
                </th>
                <th style={{ width: "15%" }}>Ações</th>
              </tr>
            </thead>
            <tbody>
              {slice.map((item, i) => (
                <tr key={i} id={"actions" + i} className={styles.tableRow}>
                  <td className={styles.itemRow + " " + styles.itemTitle}>
                    {item.title}
                  </td>
                  {currentButton === "image" && (
                    <td className={styles.itemRow + " " + styles.alignCenter}>
                      <img
                        src={item.content}
                        alt={item.title}
                        className={styles.imagePreview}
                      ></img>
                    </td>
                  )}
                  {currentButton === "color" && (
                    <td className={styles.itemRow + " " + styles.alignCenter}>
                      <div>
                        {item.content}
                        <span
                          className={styles.colorDot}
                          style={{ backgroundColor: item.content }}
                        ></span>
                      </div>
                    </td>
                  )}
                  <td className={styles.itemRow + " " + styles.alignCenter}>
                    {item.date}
                  </td>
                  <td className={styles.actionsContainer}>
                    <Pencil
                      className={styles.editItemButton}
                      onClick={() => {
                        triggerEditItem(item.id);
                      }}
                      size={28}
                    />
                    <div className={styles.helpPopupEdit}>Editar Item</div>
                    <ConfirmationModal
                      id={"archiveItem" + item.id}
                      iconComponent={
                        <>
                          <Archive
                            className={styles.archiveItemButton}
                            onClick={() => { }}
                            size={28}
                          />
                          <div className={styles.helpPopupArchive}>
                            Arquivar Item
                          </div>
                        </>
                      }
                      title={"Deseja realmente arquivar este item?"}
                      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
                      onClick={() => {
                        setItemIdToEdit(item.id);
                        setArchiveConfirmationModal(true);
                      }}
                      open={archiveConfirmationModal}
                      onOk={() => {
                        archiveItem();
                      }}
                      onCancel={() => setArchiveConfirmationModal(false)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            tablePagination={tablePagination}
            slice={slice}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          ></Pagination>
        </div>
      );
      break;
    case !capabilities.includes("cadastros"):
      content = (
        <div className={styles.error}>
          <center>
            <Alert
              type={"danger"}
              message={"Você não tem permissão para acessar esta página"}
            />
          </center>
        </div>
      );
      break;
    default:
      content = <div>Houve um erro ao carregar a página</div>;
  }

  return (
    <Layout>
      <div>
        <div className={styles.pageHeader}>
          <PageTitle style={{ marginTop: "-0.5rem" }} center={true}>
            Outros
          </PageTitle>
        </div>
        <div className={styles.tabsContainer}>
          <Button
            className={handleTabClass("image")}
            onClick={() => {
              setCurrentButton("image");
            }}
          >
            <Image size={22} className={styles.addIcon} />
            <span style={{ paddingBottom: "3px" }}>Imagens</span>
          </Button>
          <Button
            className={handleTabClass("color")}
            onClick={() => {
              setCurrentButton("color");
            }}
          >
            <Palette size={22} className={styles.addIcon} />
            <span style={{ paddingBottom: "3px" }}>Cores</span>
          </Button>
        </div>
        {content}
      </div>
      {isPopupOpenned === true ? (
        <>
          <div className={styles.newItemPopUp}>
            <div className={styles.header}>
              {isEditingItem ? "Editar Item" : "Novo Item"}
            </div>
            {currentButton === "image" && (
              <div className={styles.popupRow}>
                {alertsPopup.map((i, k) => (
                  <Alert message={i.message} type={i.type} key={k} />
                ))}
                <span>
                  Nome da Imagem<span style={{ color: "red" }}>*</span>
                </span>
                <Field
                  key={"newImageTitle"}
                  name={"newImageTitle"}
                  id={"newImageTitle"}
                  value={newTitle}
                  onChange={(event) => {
                    setNewIitle(event.target.value);
                  }}
                ></Field>
                <div className={styles.imageContainer}>
                  <UploadImageField
                    id={"imageItem"}
                    image={newImageItem}
                    setImage={setNewImageItem}
                  ></UploadImageField>
                </div>
              </div>
            )}
            {currentButton === "color" && (
              <>
                <div className={styles.popupRow}>
                  {alertsPopup.map((i, k) => (
                    <Alert message={i.message} type={i.type} key={k} />
                  ))}
                  <span>
                    Nome da Cor<span style={{ color: "red" }}>*</span>
                  </span>
                  <Field
                    key={"newColorTitle"}
                    name={"newColorTitle"}
                    id={"newColorTitle"}
                    value={newTitle}
                    onChange={(event) => {
                      setNewIitle(event.target.value);
                    }}
                  ></Field>
                </div>
                <div className={styles.popupRow + " " + styles.colorContainer}>
                  <div className={styles.colorPreviewContainer}>
                    <div
                      onClick={() => {
                        setDisplayColorPicker(!displayColorPicker);
                      }}
                      className={styles.colorPreview}
                      style={{ backgroundColor: colorCod }}
                    ></div>
                  </div>
                  {displayColorPicker && (
                    <div style={{ position: "relative", zIndex: "2" }}>
                      <ChromePicker
                        width={130}
                        disableAlpha={true}
                        color={colorCod}
                        presetColors={[]}
                        onChange={(color) => {
                          setColorCod(color.hex);
                        }}
                      />
                    </div>
                  )}
                  <div className={styles.colorCodeField}>
                    <Field
                      key={"colorCod"}
                      name={"colorCod"}
                      id={"colorCod"}
                      value={colorCod}
                      onChange={(event) => {
                        setColorCod(event.target.value);
                      }}
                    ></Field>
                  </div>
                </div>
                <div className={styles.popupRow}></div>
              </>
            )}
            <div className={styles.popupFooter}>
              <Button
                className={styles.tabsButtonFooter + " " + "primaryOrange"}
                onClick={() => (isEditingItem ? editItem() : addNewItem())}
              >
                {isEditingItem ? "Salvar" : "Adicionar"}
              </Button>
              <Button
                className={styles.tabsButtonFooter + " " + "secondaryOrange"}
                onClick={() => {
                  setIsPopupOpenned(false);
                  setNewIitle("");
                  setNewImageItem("");
                  setNewIitle("");
                  setColorCod("");
                  setDisplayColorPicker(false);
                  setAlertsPopup([]);
                }}
              >
                Cancelar
              </Button>
            </div>
          </div>
          <div className={styles.overlay} onClick={() => controlPopUp()}></div>
        </>
      ) : (
        <div className={styles.overlayOff}></div>
      )}
    </Layout>
  );
};

export default AssetsLists;
