import { Funnel } from "phosphor-react";
import React, { useState } from "react";
import Alert from "components/Alert/Alert";
import { addNewAlert } from "Services/alertsManagement";
import MainStyle from "components/UI/main.module.scss";
import styles from "./reportsFilter.module.scss";
import { getParameterItems } from "components/GeneralLists/generalListsServices";
import { useEffect } from "react";
import Checkbox from "components/Checkbox/checkbox";
import Select from "react-select";
import Field from "components/Field/field";
import { getUsers } from "components/UsersList/usersServices";
import Button from "components/Button/button";
import { getFilteredOrders } from "../reportsService";

const ReportsFilter = ({ setList, setFilteredList, setResultList, setMemResultList, setLoading, setSlice}) => {
  
  const [alerts, setAlerts] = useState([]);
  const [overlayState, setOverlayState] = useState(false);
  const [memmoryList, setMemmoryList] = useState();
  const [schoolsList, setSchoolsList] = useState([]);
  const [areasList, setAreasList] = useState([]);
  const [fieldList, setFieldList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [teacherList, setTeacherList] = useState([]);
  const [statusList, setStatusList] = useState([
    { value: "ready", label: "Prontas", sk: "ready" },
    { value: "closed", label: "Encerradas", sk: "closed"},
  ]);
  const [parametersList, setParametersList] = useState({ school: [] });


  var [IcoFunnelStyle, SetIcoFunnelStyle] = useState(styles.funnelIcon);

  // Opens and closes pop-up/overlay
  function controlPopUp() {
    if (overlayState == true) {
      // setPopupState(false);
      setOverlayState(false);
    } else {
      // setPopupState(true);
      setOverlayState(true);
    }
  }

  const getParametersFilter = async () => {
    let dataSchool = await getParameterItems("school");
    let _dataSchool = [];
    dataSchool.map((school) => {
      _dataSchool.push({ value: school.title, label: school.title, sk: school.sk});
    });
    setSchoolsList(_dataSchool);

    let dataArea = await getParameterItems("area");
    let _dataArea = [];
    dataArea.map((area) => {
      _dataArea.push({ value: area.title, label: area.title, sk: area.sk });
    });
    setAreasList(_dataArea);

    let dataField = await getParameterItems("field");
    let _dataField = [];
    dataField.map((field) => {
      _dataField.push({ value: field.title, label: field.title, sk: field.sk });
    });
    setFieldList(_dataField);

    let dataProgram = await getParameterItems("program");
    let _programList = [];
    dataProgram.map((program) => {
      _programList.push({ value: program.title, label: program.title, sk: program.sk });
    });
    setProgramList(_programList);

    let dataCompany = await getParameterItems("company");
    let _companyList = [];
    dataCompany.map((company) => {
      _companyList.push({ value: company.title, label: company.title, sk: company.sk });
    });
    setCompanyList(_companyList);

    let dataTeacher = await getUsers();
    let _teacherList = [];
    dataTeacher.map((teacher) => {
      const teacherLabel = teacher.first_name + " (" + teacher.email + ")";
      _teacherList.push({ value: teacher.id, label: teacherLabel, sk: teacher.id});
    });
    setTeacherList(_teacherList);
  };

  const customStyles = {
    control: (styles) => ({ ...styles, minHeight: "32px" }),
    input: (styles) => ({ ...styles, height: "30px" }),
    indicatorsContainer: (styles) => ({ ...styles, height: "30px" }),
    valueContainer: (styles) => ({
      ...styles,
      minHeight: "30px",
      padding: "0 10px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
  };

  useEffect(() => {
    getParametersFilter();
  }, []);

  const useStringList = (initialList = [], queryParam = '') => {
    const [list, setList] = useState(initialList);
  
    const addItems = (items) => {
      setList(items);
    };
  
    const formattedString = list.length > 0 ? `${queryParam}=${list.map(item => item.sk).join(',')}` : '';
  
    return [formattedString, addItems];
  };

  const [schoolString, setSchools] = useStringList([], 'school');
  const [statusString, setStatus] = useStringList([], 'status');
  const [areasString, setAreas] = useStringList([], 'areas');
  const [teachersString, setTeachers] = useStringList([], 'teacher');
  const [programString, setPrograms] = useStringList([], 'program');
  const [companyString, setCompanies] = useStringList([], 'client');
  const [fieldString, setFields] = useStringList([], 'field');
  const [evaluationStartDate, setEvaluationStartDate] = useState('');
  const [evaluationEndDate, setEvaluationEndDate] = useState('');
  const [collectionStartDate, setCollectionStartDate] = useState('');
  const [collectionEndDate, setCollectionEndDate] = useState('');


  const [queryString, setQueryString] = useState('');

  const handleDateChange = (event, setDate) => {
    const date = new Date(event.target.value);
    // get Current date & time in UTC value and convert to milliseconds, divided by 1k and add + 30k seconds to be BRT Timezone
    const timestamp = ((date.getTime() / 1000) - 30000);
    setDate(timestamp);
  };

  useEffect(() => {
    const combinedString = [
      schoolString,
      statusString,
      areasString,
      teachersString,
      programString,
      companyString,
      fieldString,
      evaluationStartDate ? `datefrom=${evaluationStartDate}` : '',
      evaluationEndDate ? `dateto=${evaluationEndDate}` : ''
    ].filter(Boolean).join('&');
    
    setQueryString(combinedString);
  }, [
    schoolString, 
    statusString, 
    areasString, 
    teachersString, 
    programString, 
    companyString, 
    fieldString,
    evaluationStartDate,
    evaluationEndDate,
    collectionStartDate,
    collectionEndDate
  ]);

  const filterAlert = () => {
    let message = "Aguarde enquanto o filtro é carregado...";
    let updateAlertList = addNewAlert([], {
      message,
      type: "info",
    });
    setAlerts(updateAlertList);
  };

  return (
    <div>
      <div className={styles.funnelContainer}>
        <Funnel
          className={IcoFunnelStyle}
          onClick={() => controlPopUp()}
          size={22}
        />
      </div>
      {overlayState == true ? (
        <>
          <div className={styles.overlay} onClick={() => controlPopUp()}></div>
          <div className={MainStyle.modal}>
            <div className={[MainStyle.modal_dialog, MainStyle.modal_dialog_centered]}>
              <div className={MainStyle.modal_content}>
              <div className={styles.filterPopUp}>                
                <div className={MainStyle.row}>
                  <div className={MainStyle.col_lg_6}>
                    <div className={styles.header}>
                      Filtro              
                    </div>
                  </div>
                  <div className={MainStyle.col_lg_6}>
                    <div className={styles.cleanFilter} 
                      onClick={() => window.location.reload()}>
                        Limpar filtros
                      </div>
                  </div>
                      {alerts.map((i, k) => {
                      return <Alert message={i.message} type={i.type} />;
                    })}
                </div>
                <div className={MainStyle.px_3}>
                <div className={styles.filterBody}>
                  <div className={styles.firstRow}>
                  <div className={styles.itemRow}>
                    <div className={styles.rowTitle}>Escolas</div>
                    <div>
                      <Select
                        options={schoolsList}
                        isMulti
                        placeholder="Buscar Escola"
                        className={styles.selectUsers}
                        onChange={setSchools}
                        styles={customStyles}
                        defaultValue={schoolString ? schoolsList.filter(option => schoolString.includes(option.label)) : []}
                      />
                    </div>
                    </div>
                    <div className={styles.itemRow}>
                      <div className={styles.rowTitle}>Status</div>
                      <div>
                      <Select
                        options={statusList}
                        isMulti
                        placeholder="Selecione o status"
                        className={styles.selectUsers}
                        onChange={setStatus}
                        styles={customStyles}
                        defaultValue={statusString ? statusList.filter(option => statusString.includes(option.label)) : []}
                      />
                    </div>
                    </div>

                    <div className={styles.itemRow}>
                    <div className={styles.rowTitle}>Áreas</div>
                    <div>
                      <Select
                        options={areasList}
                        isMulti
                        placeholder="Buscar Área"
                        className={styles.selectUsers}
                        onChange={setAreas}
                        styles={customStyles}
                        defaultValue={areasString ? areasList.filter(option => areasString.includes(option.label)) : []}
                      />
                    </div>
                    </div>
                  </div>
                  <div className={styles.itemRow}>
                    <div className={styles.rowTitle}>Professores</div>
                    <div>
                      <Select
                        options={teacherList}
                        isMulti
                        placeholder="Buscar professor"
                        className={styles.selectUsers}
                        onChange={setTeachers}
                        styles={customStyles}
                        defaultValue={teachersString ? teacherList.filter(option => teachersString.includes(option.label)) : []}

                      />
                    </div>
                  </div>
                  <div className={styles.itemRow}>
                    <div className={styles.rowTitle}>Programa</div>
                    <div>
                      <Select
                        options={programList}
                        isMulti
                        placeholder="Buscar programa"
                        className={styles.selectUsers}
                        onChange={setPrograms}
                        styles={customStyles}
                        defaultValue={programString ? programList.filter(option => programString.includes(option.label)) : []}

                      />
                    </div>
                  </div>
                  <div className={styles.itemRow}>
                    <div className={styles.rowTitle}>Empresa</div>
                    <div>
                      <Select
                        options={companyList}
                        isMulti
                        placeholder="Buscar empresa"
                        className={styles.selectUsers}
                        onChange={setCompanies}
                        styles={customStyles}
                        defaultValue={companyString ? companyList.filter(option => companyString.includes(option.label)) : []}

                      />
                    </div>
                  </div>
                  <div className={styles.itemRow}>
                    <div className={styles.rowTitle}>Área Acadêmica</div>
                    <div>
                      <Select
                        options={fieldList}
                        isMulti
                        placeholder="Buscar Área Acadêmica"
                        className={styles.selectUsers}
                        onChange={setFields}
                        styles={customStyles}
                        defaultValue={fieldString ? fieldList.filter(option => fieldString.includes(option.label)) : []}

                      />
                    </div>
                  </div>
                  <div className={styles.itemRow}>
                    <div className={styles.rowTitle}>Data da Avaliação</div>
                    <div className={styles.dateFieldRow}>
                        <span style={{ fontSize: "14px", fontWeight: "600" }}>
                          de
                        </span>
                        <Field
                          type={"date"}
                          id={"initialDate"}
                          onChange={(event) => handleDateChange(event, setEvaluationStartDate)}
                          defaultValue={evaluationStartDate ? new Date(evaluationStartDate).toISOString().substr(0, 10) : ''}

                        />
                        <span style={{ fontSize: "14px", fontWeight: "600" }}>
                          até
                        </span>
                        <Field
                          type={"date"}
                          id={"initialDate"}
                          onChange={(event) => handleDateChange(event, setEvaluationEndDate)}
                          defaultValue={evaluationEndDate ? new Date(evaluationEndDate).toISOString().substr(0, 10) : ''}


                        />
                    </div>
                  </div>
                </div>
                <div className={styles.popupFooter}>
                <Button
                    className={styles.tabsButtonFooter + " " + "secondaryOrange"}
                    onClick={() => controlPopUp()}
                  >
                    Cancelar
                  </Button>
                  <Button
                    className={styles.tabsButtonFooter + " " + "primaryOrange"}
                    onClick={async () => {
                      filterAlert()
                      let results = await getFilteredOrders(queryString);
                      console.log(results);
                      setResultList(results);
                      controlPopUp()
                      setAlerts([]);
                      SetIcoFunnelStyle(styles.funnelIconActivate)
                      setSlice={setSlice}
                    }}
                  >
                    Filtrar
                  </Button>
                  
                </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.overlayOff}></div>
      )}
    </div>
  );
};

export default ReportsFilter;
