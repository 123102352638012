import Alert from "components/Alert/Alert";
import Button from "components/Button/button";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import { UnarchiveIcon } from "components/Icon/Icon";
import Layout from "components/Layout/layout";
import PageTitle from "components/PageTitle/pageTitle";
import Pagination from "components/Pagination/pagination";
import TableControl from "components/TableControl/tableControl";
import {
  ArrowCircleLeft,
  ArrowsDownUp,
  Image,
  Palette,
  Trash,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import Loading from "components/Loading/loading";
import { useNavigate } from "react-router-dom";
import { addNewAlert } from "Services/alertsManagement";

import styles from "./archivedAssetsList.module.scss";
import {
  deleteAssetById,
  getAllArchivedAssets,
  unarchiveAssetById,
} from "./ArchivedAssetsServices";
import { useAuthenticator } from "@aws-amplify/ui-react";

const ArchivedAssetsList = () => {
  const [currentButton, setCurrentButton] = useState("image");
  const [loading, setLoading] = useState(true);
  const [memResultList, setMemResultList] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [tablePagination, setTablePagination] = useState([]);
  const [slice, setSlice] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [listIsSorted, setListIsSorted] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [itemIdToDelete, setItemIdToDelete] = useState("");

  const navigate = useNavigate();

  const handleTabClass = (button) => {
    let tabsButtonClass =
      button == currentButton ? styles.tabsButtonSelected : "secondaryBlue";

    return styles.tabsButton + " " + tabsButtonClass;
  };

  //Sort list by specific key or restore original response
  function sortObjByKey(key) {
    let _resultList = [...resultList];
    function compare(a, b) {
      a = a[key];
      b = b[key];
      var type =
        typeof a === "string" || typeof b === "string" ? "string" : "number";
      var result;
      if (type === "string") result = a.localeCompare(b);
      else result = a - b;
      return result;
    }

    //Check if list was ever sorted during the session
    if (memResultList.length == 0 && listIsSorted == false) {
      //Saves original user API response to memmory
      setMemResultList(resultList);

      //sort user list
      _resultList = _resultList.sort(compare);
      setListIsSorted(true);
    }

    //In case list was already sorted before
    else {
      //In case list isn't currently sorted
      if (listIsSorted == false && memResultList.length > 0) {
        _resultList = _resultList.sort(compare);
        setListIsSorted(true);
      }
      //In case list is currently sorted
      else {
        _resultList = memResultList;
        setListIsSorted(false);
      }
    }

    setResultList(_resultList);
    return _resultList;
  }

  const unarchiveAsset = (id) => {
    let updateAlertList = "";
    let message = "";
    unarchiveAssetById(currentButton, id).then((response) => {
      let messageResponse = response.message;

      if (messageResponse.includes("desarquivado com sucesso")) {
        message = "Item desarquivado com sucesso!";
        updateAlertList = addNewAlert([], { message, type: "success" });
        getAllArchivedAssets(
          setResultList,
          setMemResultList,
          setLoading,
          currentButton
        );
      } else {
        message = "Ocorreu um erro ao desarquivar o item.";
        updateAlertList = addNewAlert([], {
          message,
          type: "danger",
        });
      }
      setAlerts(updateAlertList);
    });
  };

  const deleteAsset = () => {
    let updateAlertList = "";
    let message = "";
    setDeleteConfirmationModal(false);
    deleteAssetById(currentButton, itemIdToDelete).then((response) => {
      let messageResponse = response.message;

      if (messageResponse.includes("deleted successfully")) {
        message = "Item deletado com sucesso!";
        updateAlertList = addNewAlert([], { message, type: "success" });
        getAllArchivedAssets(
          setResultList,
          setMemResultList,
          setLoading,
          currentButton
        );
      } else {
        message = "Ocorreu um erro ao deletar o item.";
        updateAlertList = addNewAlert([], {
          message,
          type: "danger",
        });
      }
      setAlerts(updateAlertList);
    });
  };

  useEffect(() => {
    setLoading(true);
    getAllArchivedAssets(
      setResultList,
      setMemResultList,
      setLoading,
      currentButton
    );
  }, []);

  const { user } = useAuthenticator((context) => [context.user]);
  const id = user.username
  const userRole = localStorage.getItem("role#" + id);

  return (
    <Layout>
      <div>
        <div className={styles.pageHeader}>
          <PageTitle center={true}>Arquivados</PageTitle>
        </div>
        <div className={styles.tabsContainer}>
          <Button
            className={handleTabClass("image")}
            onClick={() => {
              setCurrentButton("image");
            }}
          >
            <Image size={22} className={styles.addIcon} />
            <span style={{ paddingBottom: "3px" }}>Imagens</span>
          </Button>
          <Button
            className={handleTabClass("color")}
            onClick={() => {
              setCurrentButton("color");
            }}
          >
            <Palette size={22} className={styles.addIcon} />
            <span style={{ paddingBottom: "3px" }}>Cores</span>
          </Button>
        </div>
        <Button
          className={styles.backButton + " " + "secondaryOrange"}
          onClick={() => navigate("/assetsLists")}
        >
          <ArrowCircleLeft size={22} className={styles.backIcon} />
          <span className={styles.backLabel}>Voltar</span>
        </Button>
      </div>
      {loading == true ? (
        <div className={styles.loading}>
          <div className="large">Carregando informações</div>
          <center>
            <ReactLoading
              type="bubbles"
              color="#0064a2"
              height={50}
              width={175}
            />
          </center>
        </div>
        // <Loading />
      ) : (
        <div>
          <TableControl
            resultList={resultList}
            setResultList={setResultList}
            originalListTable={memResultList}
            setTablePagination={setTablePagination}
            setSlice={setSlice}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            showByPageDefault={10}
            searchPlaceholder={"Buscar Item"}
            hideBulkActions={true}
            hideFilter={true}
          ></TableControl>
          <Pagination
            tablePagination={tablePagination}
            slice={slice}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          ></Pagination>
          {alerts.map((i, k) => {
            return <Alert message={i.message} type={i.type} />;
          })}
          <table className={styles.table}>
            <thead>
              <tr style={{ backgroundColor: "white" }}>
                <th style={{ width: "40vw" }} className={styles.tableTitle}>
                  Título
                  <ArrowsDownUp
                    onClick={() => sortObjByKey("title")}
                    className={styles.arrowsDownUp}
                    size={16}
                  />
                </th>
                {currentButton == "image" && (
                  <th style={{ width: "25%" }}>Prévia</th>
                )}
                {currentButton == "color" && (
                  <th style={{ width: "25%" }}>Código da Cor</th>
                )}
                <th style={{ width: "30%" }}>
                  Data
                  <ArrowsDownUp
                    onClick={() => sortObjByKey("created_At")}
                    className={styles.arrowsDownUp}
                    size={16}
                  />
                </th>
                <th style={{ width: "15%" }}>Ações</th>
              </tr>
            </thead>
            <tbody>
              {slice.map((item, i) => {
                return (
                  <tr key={i} id={"actions" + i} className={styles.tableRow}>
                    <td className={styles.itemRow + " " + styles.itemTitle}>
                      {item.title}
                    </td>
                    {currentButton == "image" && (
                      <td className={styles.itemRow + " " + styles.alignCenter}>
                        <img
                          src={item.content}
                          alt={item.title}
                          className={styles.imagePreview}
                        ></img>
                      </td>
                    )}
                    {currentButton == "color" && (
                      <td className={styles.itemRow + " " + styles.alignCenter}>
                        <div>
                          {item.content}
                          <span
                            className={styles.colorDot}
                            style={{ backgroundColor: item.content }}
                          ></span>
                        </div>
                      </td>
                    )}
                    <td className={styles.itemRow + " " + styles.alignCenter}>
                      {item.date}
                    </td>
                    <td className={styles.actionsContainer}>
                      <UnarchiveIcon
                        className={styles.unarchiveAssetButton}
                        size={26}
                        onClick={() => {
                          unarchiveAsset(item.id);
                        }}
                      ></UnarchiveIcon>
                      <div className={styles.helpPopupUnarchive}>
                        Desarquivar Item
                      </div>
                      {userRole === "Administrador" &&
                        <ConfirmationModal
                          id={"deleteItem" + item.id}
                          iconComponent={
                            <>
                              <Trash
                                className={styles.deleteAssetButton}
                                size={28}
                              />
                              <div className={styles.helpPopupDelete}>
                                Deletar Item
                              </div>
                            </>
                          }
                          title={"Deseja realmente deletar este item?"}
                          onClick={() => {
                            setItemIdToDelete(item.id);
                            setDeleteConfirmationModal(true);
                          }}
                          open={deleteConfirmationModal}
                          onOk={() => {
                            deleteAsset();
                          }}
                          onCancel={() => setDeleteConfirmationModal(false)}
                        />
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </Layout>
  );
};

export default ArchivedAssetsList;
