import Alert from "components/Alert/Alert";
import Button from "components/Button/button";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import { UnarchiveIcon } from "components/Icon/Icon";
import Layout from "components/Layout/layout";
import PageTitle from "components/PageTitle/pageTitle";
import Pagination from "components/Pagination/pagination";
import TableControl from "components/TableControl/tableControl";
import {
  ArrowCircleLeft,
  ArrowsDownUp,
  DotsThree,
  Trash,
  X,
} from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import ReactLoading from "react-loading";
import Loading from "components/Loading/loading";
import { useNavigate } from "react-router-dom";
import { addNewAlert } from "Services/alertsManagement";

import styles from "./archivedAssessmentLists.module.scss";
import {
  deleteAssessmentById,
  getAllArchivedAssessment,
  unarchiveAssessmentById,
} from "./archivedAssessmentsServices";
import { useAuthenticator } from "@aws-amplify/ui-react";
import MainStyle from 'components/UI/main.module.scss';

const ArchivedAssessmentLists = () => {
  const navigate = useNavigate();
  const [resultList, setResultList] = useState([]);
  const [memResultList, setMemResultList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tablePagination, setTablePagination] = useState([]);
  const [slice, setSlice] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [alerts, setAlerts] = useState([]);
  const [listIsSorted, setListIsSorted] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [showMoreActions, setShowMoreActions] = useState({});
  const [itemIdToEdit, setItemIdToEdit] = useState("");
  const [ascendingOrDescendingTitle, setAscendingOrDescendingTitle] = useState(null);

  //Sort list by specific key or restore original response
  function sortObjByKey(key, order = null) {
    let _resultList = [...resultList];

    function compare(a, b) {
      function parseDate(dateString) {
        // Assuming the date format is "DD/MM/YYYY"
        const [day, month, year] = dateString.split('/').map(Number);
        return new Date(year, month - 1, day);
      }

      function getDateValue(obj, key) {
        console.log(obj)
        return key.includes('.')
          ? key.split('.').reduce((acc, cur) => acc && acc[cur], obj)
          : obj[key];
      }

      const dateA = getDateValue(a, key);
      const dateB = getDateValue(b, key);    
      const isDateKey = key === 'initialDate' || key === 'finalDate';

      if (isDateKey) {
        // Parse dates and compare
        const parsedDateA = parseDate(dateA);
        const parsedDateB = parseDate(dateB); 

        if (order === 'ascending') return parsedDateA - parsedDateB;
        else if (order === 'descending') return parsedDateB - parsedDateA;
        else return parsedDateA - parsedDateB; // Default sort (ascending)

      } else {
        // Compare as strings or numbers
        const type =
          typeof dateA === 'string' || typeof dateB === 'string' ? 'string' : 'number';
        let _resultList;

        if (type === 'string' && ascendingOrDescendingTitle === 'A') {    
          setAscendingOrDescendingTitle('B')
          _resultList = dateA.localeCompare(dateB);
        }         
          
        else {       
          setAscendingOrDescendingTitle('A')
          _resultList = dateB.localeCompare(dateA);
        }
        return _resultList;
      }
    }

    // Check if the list was ever sorted during the session
    if (memResultList.length === 0 && listIsSorted === false) {
      // Save the original API response to memory
      setMemResultList(resultList);

      // Sort the user list
      _resultList = _resultList.sort(compare);
      setListIsSorted(true);
    } else {
      // In case the list was already sorted before
      // In case the list isn't currently sorted
      if (listIsSorted === false && memResultList.length > 0) {
        _resultList = _resultList.sort(compare);
        setListIsSorted(true);
      } else {
        // In case the list is currently sorted
        _resultList = _resultList.sort(compare);
        setListIsSorted(false);
      }
    }

    setResultList(_resultList);
    return _resultList;
  }

    //Sort list by specific key or restore original response
    function sortObjByKeyTitle(key) {
      let _resultList = [...resultList];
      function compare(a, b) {
        a = a[key];
        b = b[key];
        var type =
          typeof a === "string" || typeof b === "string" ? "string" : "number";
        var result;
        if (type === "string") result = a.localeCompare(b);
        else result = a - b;
        return result;
      }
  
      //Check if list was ever sorted during the session
      if (memResultList.length == 0 && listIsSorted == false) {
        //Saves original user API response to memmory
        setMemResultList(resultList);
  
        //sort user list
        _resultList = _resultList.sort(compare);
        setListIsSorted(true);
      }
  
      //In case list was already sorted before
      else {
        //In case list isn't currently sorted
        if (listIsSorted == false && memResultList.length > 0) {
          _resultList = _resultList.sort(compare);
          setListIsSorted(true);
        }
        //In case list is currently sorted
        else {
          _resultList = memResultList;
          setListIsSorted(false);
        }
      }
  
      setResultList(_resultList);
      return _resultList;
    }

  const unarchiveAssessment = (id) => {
    let updateAlertList = "";
    let message = "";
    unarchiveAssessmentById(id).then((response) => {
      let messageResponse = response.message;
      if (messageResponse.includes("successfully unarchived")) {
        message = "Avaliação desarquivada com sucesso!";
        updateAlertList = addNewAlert([], { message, type: "success" });
        getAllArchivedAssessment(setResultList, setMemResultList, setLoading);
      } else {
        message = "Ocorreu um erro ao desarquivar a avaliação.";
        updateAlertList = addNewAlert([], {
          message,
          type: "danger",
        });
      }
      setAlerts(updateAlertList);
    });
  };

  const deleteAssessment = () => {
    let updateAlertList = "";
    let message = "";
    setDeleteConfirmationModal(false);

    deleteAssessmentById(itemIdToEdit).then((response) => {
      let messageResponse = response.message;

      if (messageResponse.includes("deleted successfully")) {
        message = "Avaliação deletada com sucesso!";
        updateAlertList = addNewAlert([], { message, type: "success" });
        getAllArchivedAssessment(setResultList, setMemResultList, setLoading);
      } else {
        message = "Ocorreu um erro ao tentar deletar a avaliação.";
        updateAlertList = addNewAlert([], {
          message,
          type: "danger",
        });
      }
      setAlerts(updateAlertList);
    });
  };



  useEffect(() => {
    if (resultList.length == 0) {
      getAllArchivedAssessment(setResultList, setMemResultList, setLoading);
    }
  }, []);

  const { user } = useAuthenticator((context) => [context.user]);
  const id = user.username
  const userRole = localStorage.getItem("role#" + id);

  return (
    <Layout>
      <div>
        <Button
          className={styles.backButton + " " + "secondaryOrange"}
          onClick={() => navigate(-1)}
        >
          <ArrowCircleLeft size={22} className={styles.backIcon} />
          <span className={styles.backLabel}>Voltar</span>
        </Button>
        <div className={styles.pageHeader}>
          <PageTitle center={true}>Avaliações Arquivadas</PageTitle>
        </div>
        {loading == true ? (
          <div className={styles.loading}>
            <div className="large">Carregando informações</div>
            <center>
              <ReactLoading
                type="bubbles"
                color="#0064a2"
                height={50}
                width={175}
              />
            </center>
          </div>
          // <Loading />
        ) : (
          <div>
            <TableControl
              resultList={resultList}
              setResultList={setResultList}
              originalListTable={memResultList}
              setTablePagination={setTablePagination}
              setSlice={setSlice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              showByPageDefault={10}
              searchPlaceholder={"Buscar Item"}
              hideBulkActions={true}
              hideFilter={true}
            ></TableControl>
            <Pagination
              tablePagination={tablePagination}
              slice={slice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></Pagination>
            {alerts.map((i, k) => {
              return <Alert message={i.message} type={i.type} />;
            })}
            <table className={styles.table}>
              <thead>
                <tr style={{ backgroundColor: "white" }}>
                  <th style={{ width: "40vw" }}>
                    Título
                    <ArrowsDownUp
                      onClick={() => sortObjByKeyTitle("title")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "25%" }}>
                    Data da avaliação
                    <ArrowsDownUp
                      onClick={() => sortObjByKey("opening")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "25%" }}>
                    Final da Coleta
                    <ArrowsDownUp
                      onClick={() => sortObjByKey("closing")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "20%" }}>Ações</th>
                </tr>
              </thead>
              <tbody>
                {slice.map((item, i) => {
                  let id = item.id;
                  return (
                    <tr key={i} id={"actions" + i} className={styles.tableRow}>
                      <td
                        style={{maxWidth: '250px'}}
                        className={
                          styles.assessmentRow +
                          " " +
                          styles.assessmentTitle +
                          " " +
                          styles.rowTitle + 
                          " " +
                          MainStyle.text_truncate
                        }
                      >
                        {item.title}
                      </td>
                      <td className={styles.assessmentRow}>
                        {item.initialDate}
                      </td>
                      <td className={styles.assessmentRow}>{item.finalDate}</td>
                      <td className={styles.actionsContainer}>
                        <UnarchiveIcon
                          size={26}
                          className={styles.unarchiveAssessmentButton}
                          onClick={() => unarchiveAssessment(id)}
                        ></UnarchiveIcon>
                        <div className={styles.helpPopupUnarchive}>
                          Desarquivar Avaliação
                        </div>
                        {userRole === "Administrador" &&
                          <ConfirmationModal
                            id={"deleteAssessment"}
                            iconComponent={
                              <>
                                <Trash
                                  size={26}
                                  className={styles.deleteAssessmentButton}
                                />
                                <div className={styles.helpPopupDelete}>
                                  Deletar Avaliação
                                </div>
                              </>
                            }
                            title={
                              "Deseja deletar permanentemente este avaliação?"
                            }
                            onClick={() => {
                              setDeleteConfirmationModal(true);
                              setItemIdToEdit(item.id);
                            }}
                            open={deleteConfirmationModal}
                            onOk={() => deleteAssessment()}
                            onCancel={() => setDeleteConfirmationModal(false)}
                          />
                        }
                        <DotsThree
                          size={28}
                          title={"Mais ações"}
                          className={styles.moreActionsButton}
                          onClick={() => {
                            let _showMoreActions = { ...showMoreActions };
                            _showMoreActions = {};
                            if (!showMoreActions[id]) {
                              _showMoreActions[id] = true;
                            }
                            setShowMoreActions(_showMoreActions);
                          }}
                        />
                        <div className={styles.helpPopupAction}>Mais ações</div>
                        {showMoreActions[id] == true ? (
                          <MoreActionsArchivedAssessment
                            index={i}
                            id={item.id}
                            setShowMoreActions={setShowMoreActions}
                            showMoreActions={showMoreActions}
                          ></MoreActionsArchivedAssessment>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              tablePagination={tablePagination}
              slice={slice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></Pagination>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ArchivedAssessmentLists;

export const MoreActionsArchivedAssessment = ({
  index,
  id,
  setShowMoreActions,
  showMoreActions,
}) => {
  const navigate = useNavigate();
  const wrapperRef = useRef(null);

  //This const return if a element is alredy in view
  const [refInView, inView] = useInView();

  const [activePopUp, setActivePopUp] = useState(true);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && inView) {
          let _showMoreActions = { ...showMoreActions };
          _showMoreActions = {};
          setShowMoreActions(_showMoreActions);
        }
      }
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
    }, []);
  }

  useOutsideAlerter(wrapperRef);
  return (
    <div
      ref={wrapperRef}
      key={index}
      id={"actions" + index}
      className={styles.moreActionsPopup}
      style={{ display: activePopUp ? 'block' : 'none' }}
    >
      <div ref={refInView} className={styles.popupHeader} id={"action-title"}>
        Mais ações
        <X size={16} className={styles.closeMoreActions}
          onClick={() => setActivePopUp(false)}
        />
      </div>
      <div
        className={styles.popupOption}
        onClick={() => window.open("/" + id + "/preview", '_blank').focus()}
      >
        Ver Avaliação
      </div>
    </div>
  );
};
