import Checkbox from "components/Checkbox/checkbox";
import React, { useState } from "react";
import styles from "./question.module.scss";
import Radio from "components/Radio/radio";
import TextArea from "antd/es/input/TextArea";
import { Rate } from "antd";
import { Height, Widgets } from "@mui/icons-material";
//import { position } from "html2canvas/dist/types/css/property-descriptors/position";
import * as html2canvas from 'html2canvas'
import MainStyle from "components/UI/main.module.scss";


const Question = ({ blockIndex, questionData, questionType, lang, answers, setAnswer, questionIndexInBlock, highlight }) => {
  function addAnswer(answerSended) {  
    let answerArray = answers;
    answerArray.answers[blockIndex].answer[questionIndexInBlock].answer = answerSended;
    setAnswer(answerArray)
  }
  //  NPS Handlers for btn selection of values and capture of multiple states
  const [values_NPS, setValues_NPS] = useState({});
  const [components_NPS, setComponents_NPS] = useState([1]);
  const [selectedValue_NPS, setSelectedValue_NPS] = useState(0);
  const Main_Scale_btn_handler = ({npsText}) => {

    const Scale_Buttons = ({ id, onValueChange }) => {      
      const handleButtonClick = (value) => {
        setSelectedValue_NPS(value);
        onValueChange(id, value);
        addAnswer(value);
      };
      return (
        <div className={[MainStyle.row, MainStyle.py_3, MainStyle.px_2].join(" ")}>
          <div className={[MainStyle.col_12].join(" ")}>
            <div 
              style={{
                display: 'flex',     
                justifyContent: 'center', 
                alignItems: 'center',
              }}>
            {[...Array(11)].map((_, index) => {
              const value = index;
              return (
                <button
                  key={value}
                  className={MainStyle.npsForm_Btn}
                  onClick={() => handleButtonClick(value)}
                  style={{                    
                    backgroundColor: selectedValue_NPS === value ? '#696a6b' : "#ffffff",
                    color: selectedValue_NPS === value ? "#ffffff" : '#000000',
                  }}
                >
                  <span className={MainStyle.npsForm_Txt}>
                    {value}
                  </span>
                </button>
              );
            })}
            </div>
          </div>
          {/*<p>Selected Value: {selectedValue_NPS}</p>*/}
        </div>
      );
    };
    const handleValueChange = (id, value) => {
      setValues_NPS((prev) => ({ ...prev, [id]: value }));
    };
    return (
      <>
      <div className={styles.questionContainer}>
        <div className={MainStyle.row}>
          <div className={MainStyle.col_12}>
            <p style={{}}>
              {
                questionData.required == true &&
                <span style={{ color: 'red', fontWeight: 700 }}>*</span>
              }
            </p>
            <p className={styles.npsText}>
            {
              (highlight == true) ? <b>
                                      {npsText}
                                    </b> 
                                  : <span>
                                      {npsText}
                                    </span>
            }
            </p>
              {components_NPS.map((id) => (
                <div className={styles.npsForm_Custom}>
                  <Scale_Buttons key={questionData.pk} id={questionData.pk} onValueChange={handleValueChange} />
                </div>
              ))}
          </div>
        </div>
      </div>
      </>
    );
  };
  function npsText() {
    switch (lang) {
      case "pt":
        return (
          <span className={styles.npsHeader} style={{}}>{questionData.prefix && (questionData.prefix !== null || questionData.prefix !== undefined) ? `${questionData.prefix}${' '}` : ""}  Em uma escala de 0 a 10, o quanto você indicaria {questionData.subject[lang]}{" "}
            para um amigo ou colega?</span>
        )
      case "en":
        return (
          <span className={styles.npsHeader} style={{}}>{questionData.prefix && (questionData.prefix !== null || questionData.prefix !== undefined) ? `${questionData.prefix}${' '}` : ""}   How likely is it that you would recommend {questionData.subject[lang]}{" "} to a friend or colleague?</span>
        )
      case "es":
        return (
          <span className={styles.npsHeader} style={{}}>{questionData.prefix && (questionData.prefix !== null || questionData.prefix !== undefined) ? `${questionData.prefix}${' '}` : ""}   ¿Qué tan probable es que recomiendes {questionData.subject[lang]}{" "} a colegas y amigos?</span>
        )
    }
  }

  //  Single Choice Handlers for btn selection of values and capture of multiple states
  const [SingleChoice_Value, setSingleChoice_Value] = useState();
  const [SingleChoice_Selected, setSingleChoice_Selected] = useState({});
  const Main_SingleChoice_btn_handler = ({obj}) => {

    const handleValueChange = (value) => {
      setSingleChoice_Selected((prevState) => ({
        ...prevState,
        value,
      }));
    };

    const SingleChoices = ({ objList, onValueChange }) => {

      const handleBtnOnClick =  (value, int) => {
        setSingleChoice_Value(value.index + '_' + value.id + '_' + int);
        onValueChange(value.index + '_' + value.id + '_' + int);
        addAnswer(value.id);
      }
      return (
        <div>
          {objList.alternatives.map((alternative, index) => {
            let value = alternative;
            return (
              <div className={[MainStyle.row, MainStyle.my_3, MainStyle.mx_3, MainStyle.text_center].join(" ")}>
                <div className={[MainStyle.col_1]}>
                  <Radio
                    className={`${SingleChoice_Value === value.index + '_' + value.id + '_' + index ? styles.radioDefaultChecked : styles.radioDefault}`}
                    onClick={() => handleBtnOnClick(value, index)}
                  />
                </div>
                <div className={MainStyle.col_11}>
                  <div className={[MainStyle.ms_4, MainStyle.text_start].join(" ")}>
                    <span className={[MainStyle.fs_6].join(" ")}>
                      {alternative[lang]}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}          
          {/* For debugging purposes only: <p>Selected Value: {SingleChoice_Value}</p>*/}
        </div>
      );
    };
    
    return (
      <>
        {
          <div className={styles.radioText} >
            <div className={MainStyle.row}>
              <div className={MainStyle.col_lg_12}>
                <SingleChoices objList={obj} onValueChange={handleValueChange} />
              </div>
            </div>
          </div>
        }
      </>
    );

  };  

  switch (questionType) {
    case "multiple-choice":
      let choicesList = answers.answers[blockIndex].answer[questionIndexInBlock].answer
      choicesList = []
      return (
        <div className={styles.questionContainer}>
          <span>
            <div className={styles.questTitle} style={{}}>
              <span >{questionData.prefix && (questionData.prefix !== null || questionData.prefix !== undefined) ? `${questionData.prefix}${' '}` : ""} </span>
              {
                (highlight == true) ? <b>
                                        {questionData.header[lang]}
                                      </b> 
                                    : <span>
                                        {questionData.header[lang]}
                                      </span>
              }
              {
                questionData.required == true &&
                <span style={{ color: 'red', fontWeight: 700 }}>*</span>
              }
            </div>
          </span>
          {questionData.alternatives.map((alternative) => (
            <div className={styles.multipleChoiceText}>
              <Checkbox style={{ height: '1.25rem', width: '1.25rem', marginRight: '0.75rem' }} onClick={(event) => {
                if (event.target.checked == true) {
                  choicesList.push(alternative.id)
                  addAnswer(choicesList)
                } else {
                  choicesList.splice(choicesList.indexOf(alternative.id), 1)
                  addAnswer(choicesList)
                }

              }} />
              {alternative[lang]}
              <br />
            </div>
          ))}
        </div>
      );
    case "single-choice":
      return (
        <div className={styles.questionContainer}>
          <span>
            <div className={styles.questTitle} style={{}}>
              <span>{questionData.prefix && (questionData.prefix !== null || questionData.prefix !== undefined) ? `${questionData.prefix}${' '}` : ""}</span>
              {
                (highlight == true) ? <b>
                                        {questionData.header[lang]}
                                      </b> 
                                    : <span>
                                        {questionData.header[lang]}
                                      </span>
              }
              {
                questionData.required == true &&
                <span style={{ color: 'red', fontWeight: 700 }}>*</span>
              }
            </div>
          </span>
          <div className={styles.questionText}>
            <Main_SingleChoice_btn_handler obj={questionData}/>
          </div>
        </div>
      );
    case "rating-scale":
      return (
        <div className={styles.questionContainer}>
          <div className={styles.questTitle} style={{}}>
            <span>{questionData.prefix && (questionData.prefix !== null || questionData.prefix !== undefined) ? `${questionData.prefix}${' '}` : ""} </span>
            {
              (highlight == true) ? <b>
                                        {questionData.header[lang]}
                                    </b> 
                                  : <span>
                                      {questionData.header[lang]}
                                    </span>
            }
            {
              questionData.required == true &&
              <span style={{ color: 'red', fontWeight: 700 }}>*</span>
            }
          </div>
          <div className={styles.rateQuestion}>
            <Rate onChange={(event) => addAnswer(event)} />
          </div>
        </div>
      );
    case "nps":
      return (
        <Main_Scale_btn_handler npsText={npsText()}/>
      );
    case "text-area":
      return (
        <div className={styles.questionContainer}>
          <div className={styles.questTitle} >
            <span>{questionData.prefix && (questionData.prefix !== null || questionData.prefix !== undefined) ? `${questionData.prefix}${' '}` : ""}</span>            
            {
              (highlight == true) ? <b>
                                        {questionData.header[lang]}
                                    </b> 
                                  : <span>
                                      {questionData.header[lang]}
                                    </span>
            }
            {
              questionData.required == true &&
              <span style={{ color: 'red', fontWeight: 700 }}>*</span>
            }
          </div>
          <div>
            <TextArea autoSize={{
          minRows: 3,
        }} size="large"
        style={{fontSize: "18px"}} onChange={(event) => addAnswer(event.target.value)} />
          </div>
        </div>
      );
    case "label":
      return (
        <div className={styles.questionContainer}>
          <div className={styles.labelQuestion} style={{ textTransform: 'capitalize' }}>
            <span>{questionData.prefix && (questionData.prefix !== null || questionData.prefix !== undefined) ? `${questionData.prefix}${' '}` : ""}</span>
              {
                (highlight == true) ? <b>
                                        {questionData.header[lang]}
                                      </b> 
                                    : <span>
                                        {questionData.header[lang]}
                                      </span>
              }
              {
                questionData.required == true &&
                <span style={{ color: 'red', fontWeight: 700 }}>*</span>
              }
          </div>
        </div>
      );
    default:
      <></>
  }
};

export default Question;
