import { Authenticator } from "@aws-amplify/ui-react";
import Answer from "components/Answer/answer";
import AnswerPreview from "components/AnswerPreview/answerPreview";
import ArchivedAssessmentLists from "components/ArchivedAssessmentLists/archivedAssessmentLists";
import ArchivedAssetsList from "components/ArchivedAssetsList/archivedAssetsList";
import ArchivedCollectionsList from "components/ArchivedCollectionsList/archivedCollectionsList";
import ArchivedQuestionsList from "components/ArchivedQuestionsList/archivedQuestionsList";
import ClosedAssessmentLists from "components/AssessmentLists/ClosedAssessmentLists/closedAssessmentLists";
import PreparationAssessmentList from "components/AssessmentLists/PreparationAssessmentList/preparationAssessmentList";
import ReadyAssessmentList from "components/AssessmentLists/ReadyAssessmentList/readyAssessmentList";
import AssetsLists from "components/AssetsLists/assetsLists";
import CollectionsList from "components/CollectionsList/collectionsList";
import Dashboard from "components/Dashboard/dashboard";
import DefaultPage from "components/DefaultPage/defaultPage";
import EditAssessment from "components/EditAssessment/editAssessment";
import EditCollection from "components/EditCollection/editCollection";
import EditQuestion from "components/EditQuestion/editQuestion";
import GeneralLists from "components/GeneralLists/generalLists";
import LoginArea from "components/LoginArea/loginArea";
import NotFound from "components/NotFound/notFound";
import QuestionsList from "components/QuestionsList/questionsList";
import SurveyHome from "components/SurveyHome/surveyHome";
import UserSettings from "components/UserSettings/userSettings";
import UsersList from "components/UsersList/usersList";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RequireAuth from "RequireAuth";
import { verifySession } from "Services/authenticationServices";
import { DefaultReport } from "components/DefaultReport/defaultReport";
import ReportsList from "components/ReportsList/reportsList";
import ArchivedReportsList from "components/ArchivedReportsList/archivedReportsList";
import OldReportsList from "components/ReportsList/oldReportsList";
import LoginMethods from "components/LoginMethods/loginMethods";

function App() {
  return (
    <Authenticator.Provider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<DefaultPage></DefaultPage>}>
            <Route index element={<SurveyHome></SurveyHome>}></Route>
            <Route path="login/basic" element={<LoginArea></LoginArea>}></Route>
            <Route path="login" element={<LoginMethods></LoginMethods>}></Route>
            <Route
              path="dashboard"
              element={
                <RequireAuth>
                  <Dashboard></Dashboard>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="report/:orderId"
              element={
                <RequireAuth>
                  <DefaultReport></DefaultReport>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="questions/"
              element={
                <RequireAuth>
                  <QuestionsList></QuestionsList>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="archivedQuestions/"
              element={
                <RequireAuth>
                  <ArchivedQuestionsList></ArchivedQuestionsList>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="question/:questionToEdit"
              element={
                <RequireAuth>
                  <EditQuestion></EditQuestion>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="users/"
              element={
                <RequireAuth>
                  <UsersList></UsersList>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="user/:userParams"
              element={
                <RequireAuth>
                  <UserSettings></UserSettings>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="collections/"
              element={
                <RequireAuth>
                  <CollectionsList></CollectionsList>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="archivedCollections/"
              element={
                <RequireAuth>
                  <ArchivedCollectionsList></ArchivedCollectionsList>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="collection/:collectionToEdit"
              element={
                <RequireAuth>
                  <EditCollection></EditCollection>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="generalLists/"
              element={
                <RequireAuth>
                  <GeneralLists></GeneralLists>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="assetsLists/"
              element={
                <RequireAuth>
                  <AssetsLists></AssetsLists>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="oldReports/"
              element={
                <RequireAuth>
                  <OldReportsList></OldReportsList>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="archivedAssets/"
              element={
                <RequireAuth>
                  <ArchivedAssetsList></ArchivedAssetsList>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="order/:assessmentToEdit"
              element={
                <RequireAuth>
                  <EditAssessment></EditAssessment>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="archivedOrders/"
              element={
                <RequireAuth>
                  <ArchivedAssessmentLists></ArchivedAssessmentLists>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="preparationOrders/"
              element={
                <RequireAuth>
                  <PreparationAssessmentList></PreparationAssessmentList>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="readyOrders/"
              element={
                <RequireAuth>
                  <ReadyAssessmentList></ReadyAssessmentList>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="closedOrders/"
              element={
                <RequireAuth>
                  <ClosedAssessmentLists></ClosedAssessmentLists>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="reports/"
              element={
                <RequireAuth>
                  <ReportsList></ReportsList>
                </RequireAuth>
              }
            ></Route>
            <Route
              path="archivedReports/"
              element={
                <RequireAuth>
                  <ArchivedReportsList></ArchivedReportsList>
                </RequireAuth>
              }
            ></Route>
          </Route>
          <Route path="*" element={<NotFound></NotFound>}></Route>
          <Route path="/:orderId" element={<Answer></Answer>}></Route>
          <Route
            path="/:orderId/preview"
            element={
              <RequireAuth>
                <AnswerPreview></AnswerPreview>
              </RequireAuth>
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    </Authenticator.Provider>
  );
}

export default App;
