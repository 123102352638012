import Checkbox from "components/Checkbox/checkbox";
import Field from "components/Field/field";
import ListControl from "components/ListControl/ListControl";
import { ListSelectColor } from "components/SelectColor/selectColor";
import { DotsSixVertical, MagnifyingGlass } from "phosphor-react";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import styles from "./manageCollection.module.scss";
import { getColors } from "Services/globalServices";

import { addNewAlert } from "Services/alertsManagement";
import MainStyle from "components/UI/main.module.scss";


/*export const SelectFromObj = ({ json, index, setItems, items }) => {
  const [alerts, setAlerts] = useState([]);

  // Selected value for the current item is initialized
  const selectedOption = items[index]?.questionId || ""; // Get the selected value for this item

  const handleChange = (event) => {
    let value = event.target.value;

    if(value == "") {      
      let message = "Preencha todos os campos obrigátorios para salvar o padrão!";
      let updateAlertList = addNewAlert([], { message, type: "danger" });
      setAlerts(updateAlertList);
      value = json[0].value;

    }

    // Make a copy of the items array
    let updatedItems = [...items];
    const currentItem = updatedItems[index];
    const questionIndex = json.findIndex((question) => question.value === value);

    let questionLabel = "";
    if (questionIndex > -1) {
      // If find the question, update the item with new values
      questionLabel = json[questionIndex].label;
      updatedItems[index] = {
        ...currentItem,
        questionLabel,
        questionId: value,
        questionType: json[questionIndex].type,
      };
    } else {
      // If no valid option is selected, clear the item properties
      updatedItems[index] = {
        ...currentItem,
        questionLabel,
        questionId: undefined,
      };
    }

    // Update the state with the modified items array
    setItems(updatedItems);
  };

  return (
    json && (
      <select
        style={{ maxWidth: '100%' }}
        value={selectedOption}
        onChange={handleChange}
      >
        <option value="">Selecione a opção desejada...</option>
        {json.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    )
  );
};*/

export const SelectFromObj = ({ json, index, setItems, items }) => {
  const [alerts, setAlerts] = useState([]);

  const selectedOption = items[index]?.questionId || "";

  const handleChange = (selectedOption) => {
    let value = selectedOption ? selectedOption.value : "";

    if (value === "") {
      let message = "Preencha todos os campos obrigatórios para salvar o padrão!";
      let updateAlertList = addNewAlert([], { message, type: "danger" });
      setAlerts(updateAlertList);
      value = json[0].value;
    }
    let updatedItems = [...items];
    const currentItem = updatedItems[index];
    const questionIndex = json.findIndex((question) => question.value === value);

    let questionLabel = "";
    if (questionIndex > -1) {
      questionLabel = json[questionIndex].label;
      updatedItems[index] = {
        ...currentItem,
        questionLabel,
        questionId: value,
        questionType: json[questionIndex].type,
      };
    } else {
      updatedItems[index] = {
        ...currentItem,
        questionLabel,
        questionId: undefined,
      };
    }
    setItems(updatedItems);
  };

  const options = json.map((option) => ({
    value: option.value,
    label: option.label,
  }));

  const selected = options.find((option) => option.value === selectedOption) || null;



  const customStyles = {
    container: (provided) => ({
      ...provided,
      maxWidth: '100%',
    }),
    option: (provided) => ({
      ...provided,
      minHeight: '40px', 
      padding: '10px',   
      whiteSpace: 'nowrap',
      overflow: 'hidden',  
      textOverflow: 'ellipsis',
    }),
    menuList: (provided) => ({
      ...provided,
      overflowY: 'auto',  
    }),
  };

  return (
    json && (
      <Select
        options={options}
        value={selected}
        onChange={handleChange}
        placeholder="Selecione a opção desejada..."
        styles={customStyles}        
      />
    )
  );
};

const ManageCollection = ({ items, setItems, questions }) => {

  //save reference for dragItem and dragOverItem
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
 

  //Updates the "items" list  after dragging 'n dropping an element to another index
  const handleSort = () => {
    //duplicates items list to deal with the changes
    let _items = [...items];

    //remove and save the dragged item content
    const draggedItemContent = _items.splice(dragItem.current, 1)[0];

    //switch the position
    _items.splice(dragOverItem.current, 0, draggedItemContent);

    //reset the variable
    dragItem.current = null;
    dragOverItem.current = null;

    //set the actual array
    setItems(_items);
  };

  //defaultItem to be added as an element to "item" list after clicking on "+" button
  let newDefaultItem = {
    question: null,
    highlight: false,
    required: false, 
    prefix: null,
  };

  //Adds a new item to te "items" list
  const addNewItem = (defaultItem, indexOfItemToInteractWith) => {
    //Get array of items
    let _items = [...items];

    //Add new item to the array 
    _items.splice(indexOfItemToInteractWith, 0, defaultItem);

    //set modified array
    setItems(_items);
  };

  //removeOption will remove the desired index from the list
  function removeOption(index) {
    //Gets list
    let _items = [...items];

    //Avoids user removing all items (the list should have at least one item)
    if (_items.length > 1) {
      _items.splice(index, 1);
      setItems(_items);
    }
  }

  //This function verifies if there's a match between the string the user typed in the 'question' field and the available 'questions' in the 'questions' list
  function verifyElementIsInListOfObjects(listToVeirfy, itemToFind) {
    let itemIsPresent = false;

    listToVeirfy.map((i) => {
      if (i.value == itemToFind) {
        itemIsPresent = true;
        // console.log(i.value + " = " + itemToFind);
      }
    });
    return itemIsPresent;
  }

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.listContainer + " " + styles.externalLabels}>
        <div className={MainStyle.row}>
          <div className={[MainStyle.col_lg_6, MainStyle.text_start].join(" ")}>
            <span>Adicionar questão</span>
            <span style={{ color: "red" }}>*</span>
          </div>
          <div className={[MainStyle.col_lg_2, MainStyle.text_center].join(" ")}>
            <span>Obrigatória</span>
          </div>
          <div className={[MainStyle.col_lg_2, MainStyle.text_center].join(" ")}>
            <span>Negrito</span>
          </div>
          <div className={[MainStyle.col_lg_2, MainStyle.text_center].join(" ")}>
            <span>Prefixo</span>
          </div>
        </div>
      </div>
      <div className={styles.listContainer}>
        {items.map((item, index) => {
          let currentQuestionIndex = questions.findIndex(
            (question) => question.value == item.questionId
          );
          return (
            <div
              key={index}
              draggable
              onDragStart={(event) => (dragItem.current = index)}
              onDragEnter={(event) => (dragOverItem.current = index)}
              onDragEnd={() => {
                handleSort();
              }}
              onDragOver={(event) => event.preventDefault}
            >
              {" "}                
              <div className={MainStyle.row}>
                <div className={[MainStyle.col_lg_6, MainStyle.text_start].join(" ")}>
                  <div className={MainStyle.row}>
                    <div className={MainStyle.col_1}>
                      <DotsSixVertical className={styles.dragIcon} size={32} />
                    </div>
                    {/*
                    <div className={MainStyle.col_1}>
                      <MagnifyingGlass className={styles.searchIcon} size={22} />
                    </div>
                    */}
                    <div className={MainStyle.col_11}>
                      <SelectFromObj json={questions} index={index} setItems={setItems} items={items}/>
                    </div>
                  </div>
                </div>
                <div className={MainStyle.col_lg_2}>
                  {currentQuestionIndex > -1 &&
                      questions[currentQuestionIndex].type !== 'label' ?
                        <center>
                          <Checkbox
                            checked={item.required}
                            onClick={() => {
                              let _items = [...items];
                              if (
                                _items[index].required == null ||
                                _items[index].required == false
                              ) {
                                _items[index].required = true;
                              } else {
                                _items[index].required = false;
                              }
                              setItems(_items);
                            }}
                          />
                        </center>
                      :
                        <center>                          
                          <Checkbox
                            className={styles.checkboxField}
                            checked={item.required}
                            disabled={true}
                          />
                        </center>
                  }
                </div>
                <div className={MainStyle.col_lg_2}>
                    <center>
                      <Checkbox
                        className={styles.checkboxField}
                        checked={item.highlight}
                        onClick={() => {
                          let _items = [...items];
                          if (
                            _items[index].highlight == null ||
                            _items[index].highlight == false
                          ) {
                            _items[index].highlight = true;
                          } else {
                            _items[index].highlight = false;
                          }
                          setItems(_items);
                        }}
                      />
                    </center>
                </div>
                <div className={MainStyle.col_lg_2}>
                  <div className={MainStyle.row}>
                    <div className={MainStyle.col_lg_4}>
                      <div className={MainStyle.mx_2}>
                        <Field
                          value={items[index].prefix}
                          name={"itemColor" + index + 1}
                          id={"itemColor" + index + 1}
                          className={styles.fieldPrefix + " " + styles.smallField}
                          onChange={(event) => {
                            let _items = [...items];
                            _items[index].prefix = event.target.value;
                            setItems(_items);
                          }}
                        />
                      </div>
                    </div>
                    <div className={MainStyle.col_lg_8}>
                      <div className={MainStyle.mx_4}>
                        <ListControl 
                          className={styles.listControl}
                          newItemDefaultContent={newDefaultItem}
                          addsNewItem={addNewItem}
                          indexOfItemToInteractWith={index}
                          removesAnItem={removeOption}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ManageCollection;
