import Menu from "components/Menu/menu";
import MainStyle from 'components/UI/main.module.scss';

import styles from "./layout.module.scss";

const Layout = ({ children }) => {
  return (
    <>
      <Menu></Menu>
      <div className={MainStyle.row}>
        <div className={MainStyle.col_12}>
          <div style={{minHeight: '100%'}}>
            <div className={styles.layoutContainer}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
