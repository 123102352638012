import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { CalendarX } from "phosphor-react";
import FormContainer from "components/FormContainer/formContainer";
import styles from "./answer.module.scss";
import logo1 from "../../assets/logo1.png";
import logo2 from "../../assets/logo2.png";
import Question from "components/Question/question";
import Button from "components/Button/button";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { SubmitAnswer } from "./answerServices";
import { Question as Info, CaretDoubleUp } from "phosphor-react";
import DropdownList from "components/DropdownListField/dropdownListFIeld";
import { defaultAvatar } from "components/UserSettings/defaultAvatar";
import NotFound from "components/NotFound/notFound";
import MainStyle from "components/UI/main.module.scss";

// #region Utils
  function _BytesFromUrl(url) {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => {
          resolve(arrayBuffer);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  function highlightQuestions(arr) {        //  Work around to fix the model of questions that does not have highlight prop.
    let arr_copy = arr;
    for (var i = 0; i < arr_copy.questions.length; i++) {
      let same = false;
      for (var j = 0; j < arr_copy.collection.length; j++) {
        if (arr_copy.questions[i].id == arr_copy.collection[j].questionId) {
          same = true;
          break;
        }
      }
      if (same) {
        arr_copy.questions[i].highlight = arr_copy.collection[j].highlight;
      }
    }
    return (arr_copy.questions);
    
  }
// #endregion
export const AvatarImage = ({ photo_link }) => {
  const [avatarImg, setavatarImg] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadImage = async () => {
      try {
        setLoading(true);
        const arrayBuffer = await _BytesFromUrl(photo_link);
        const base64String = arrayBufferToBase64(arrayBuffer);
        setavatarImg(`data:image/jpeg;base64,${base64String}`);
        setLoading(false);
      } catch (err) {
        setError('Failed');
        setLoading(false);
      }
    };

    loadImage();
  }, [photo_link]);

  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    // Usually empty responses deliver 1010 bytes
    if(len > 11500) {
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }
    else {
      setError('Failed');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className={[MainStyle.p_2, MainStyle.m_2].join(" ")}></div>;
  }

  return <img src={avatarImg} className={[styles.teacherPhoto_Custom, styles.img_teacher_circle].join(" ")}/>
};


/**
 * Header component displaying rating information.
 */
const RatingInformationHeader = ({ lang, orderColor }) => {
  const headerText = {
    pt: {
      stars: "Estrelas:",
      lowHigh:
        "1 = Baixo/Negativo/Discordo/Insatisfatório<br />5 = Alto/Positivo/Concordo/Excelente",
    },
    en: {
      stars: "Stars:",
      lowHigh:
        "1 = Low/Negative/Disagree/Unsatisfactory<br />5 = High/Positive/Agree/Excellent",
    },
    es: {
      stars: "Estrellas:",
      lowHigh:
        "1 = Bajo/Negativo/Discrepar/Insatisfactorio<br />5 = Alto/Positivo/Concordar/Excelente",
    },
  };

  const texts = headerText[lang] || headerText.en; // Default to English if languages is not provided

  return (
    <div className={styles.sessionHeaderRight}>
      <div
        className={styles.divisorCardSubtitle}
        style={{ backgroundColor: orderColor }}
      ></div>
      <div>
        <span className={styles.bold}>{texts.stars}</span>
        <br />
        <span
          className={styles.subtitle}
          dangerouslySetInnerHTML={{ __html: texts.lowHigh }}
        ></span>
      </div>
    </div>
  );
};

/**
 * Component displaying teacher information.
 * @param {object} props - Props containing teacher, blockInfo, index and lang.
 */
const TeacherInfo = ({ teacher, blockInfo, index, lang, orderColor }) => {
  // Define translations for the "Professor" label
  const translations = {
    pt: "Professor:",
    en: "Teacher:",
    es: "Profesor:",
  };

  const professorLabel = translations[lang] || translations.en; // Default to English if languages is not provided

  return (
    <div style={{ display: "flex", margin: "1.25rem 0" }}>
      <div className={styles.divTeacherPhoto}>
        <img
          className={styles.teacherPhoto}
          src={teacher.photo}
          alt={teacher.first_name}
        />
      </div>
      <div
        className={styles.divisorCard}
        style={{ backgroundColor: orderColor }}
      ></div>
      <div className={styles.headerLeftInfo}>
        {index === 0 &&
          blockInfo.blockHeader.labeledText.map((line, lineIndex) => {
            return (
              <div key={lineIndex}>
                <span className={styles.bold}>{line.label}:</span>
                <span>{line.text}</span> <br />
              </div>
            );
          })}
        <span className={styles.bold}>{professorLabel}</span>
        <span>
          {teacher.first_name} {teacher.last_name}
        </span>
      </div>
    </div>
  );
};

/**
 * Loading component displaying a loading animation.
 */
const Loading = () => {
  return (
    <div style={{ marginTop: "20%", position: "relative" }}>
      <center>
        <div className={styles.loading}>
          <center>
            <ReactLoading
              type="bubbles"
              color="#0064a2"
              height={50}
              width={175}
            />
          </center>
        </div>
      </center>
    </div>
  );
};

const ButtonLoader = () => {
  return (
    <div style={{ position: "relative" }}>
      <center>
        <ReactLoading type="bubbles" color="#0064a2" height={30} width={155} />
      </center>
    </div>
  );
};
/**
 * Component displaying a message when the order is not available.
 * @param {object} props - Props containing the languages settings.
 */

/**
 * Main Answer component.
 */
const Answer = () => {
  const [loading, setLoading] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [answer, setAnswer] = useState(null);
  const [isOrderAvailable, setIsOrderAvailable] = useState(null);
  const [order, setOrder] = useState(null); // State to store the order data
  let { orderId } = useParams();
  const [language, setLanguage] = useState("pt");
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [isPopupInfoOpenned, setIsPopupInfoOpenned] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [sended, setSended] = useState(false);



  // Handle animation
  useEffect(() => {
    if (isPopupInfoOpenned) {
      document.getElementById("captionBlock").style.height = '0%';
      
      let n = 0;
      const interval = setInterval(() => {
        n += 1;
        document.getElementById("captionBlock").style.height = `${n}%`;

        if (n >= 100) {
          clearInterval(interval);
        }
      }, 5);

      return () => clearInterval(interval);
    }
  }, [isPopupInfoOpenned]);


  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 30) {
        setIsVisible(false);
        setIsPopupInfoOpenned(false);
      } else {
        setIsVisible(true);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const texts = {
    submit: { en: "Submit", es: "Enviar", pt: "Enviar" },
    error: {
      en: "An error occurred while sending your response, please try again and if the error persists, contact the academic coordinator.",
      pt: "Aconteceu um erro ao enviar a sua resposta, por favor tente novamente e caso o erro persista, comunique o coordenador acadêmico.",
      es: "Ocurrió un error al enviar su respuesta, inténtelo de nuevo y si el error persiste, comuníquese con el coordinador académico.",
    },
    home: { en: "Return", es: "Regresar", pt: "Início" },
  };

  const navigate = useNavigate();

  useEffect(() => {
    // The Orders API URI for getting specific order information
    const stage = process.env.REACT_APP_STAGE;
    const uri =
      `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/orders/`;
    const authToken = "SVNFX1N1cnZleTphcGk0cHVibDFjUjNzMHVyY2Vz";
    //console.log(uri)
    // Makes GET request to fetch order details with Authorization header
    fetch(`${uri}${orderId}`, {
      headers: {
        Authorization: `${authToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setOrder(data);
        // console.log(data)
        // setLanguage(data.languages)
        let answersData = { answers: [] };
        data.questionnaire.map((block, index) => {
          let questions = [];
          block.collection.questions.map((question) => {
            questions.push({
              questionId: question.id,
              questionType: question.questionType,
              answer: null,
              required: question.required,
            });
          });
          answersData.answers.push({
            blockId: block.blockId,
            collection: block.collection.collectionId,
            answer: questions,
          });
        });
        setAnswer(answersData);
        let openingDatetime = new Date(data.meta.opening);
        let closureDatetime = new Date(data.meta.closing);
        let orderAvailable;
        if (
          new Date() > openingDatetime &&
          new Date() < closureDatetime &&
          data.itemStatus == "ready"
        ) {
          orderAvailable = true;
        } else {
          orderAvailable = false;
        }
        setIsOrderAvailable(orderAvailable);
      })
      .catch((error) => console.error("Error fetching order details:", error));
  }, []);

  if (!order && answer == null) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (!order.id) {
    return <NotFound />;
  }

  function checkIfAllRequiredAreAnswered() {
    // Initialize an array to store required questions that haven't been answered
    const requiredQuestions = [];

    // Iterate through the questionnaire
    order.questionnaire.forEach((block) => {
      // console.log(order)
      block.collection.questions.forEach((question) => {
        // Check if an answer for this question is required but hasn't been provided
        const foundAnswer = answer.answers.find(
          (ans) =>
            ans.blockId === block.blockId &&
            ans.answer &&
            ans.answer.find(
              (ansDetail) =>
                ansDetail.questionId === question.id &&
                ansDetail.answer === null &&
                ansDetail.required === true
            )
        );

        // If a required question without an answer is found, add it to the array
        if (foundAnswer) {
          requiredQuestions.push(foundAnswer);
        }
      });
    });
    // Check if there are required questions that haven't been answered
    if (requiredQuestions.length > 0) {
      // Get the languages-specific alert message for unanswered required questions
      let alertMessage;
      if (language === "pt") {
        alertMessage = "Por favor, responda todas as perguntas obrigatórias.";
      } else if (language === "es") {
        alertMessage = "Por favor, responda todas las preguntas obligatorias.";
      } else {
        // Default to English if the languages is not provided or recognized
        alertMessage = "Please answer all required questions.";
      }

      // Show the alert message to the user
      alert(alertMessage);

      // Return false to indicate that not all required questions have been answered
      return false;
    }

    // Return true if all required questions have been answered
    return true;
  }

  const setLanguageList = () => {
    const { pt, en, es } = order.languages;

    if (typeof order.languages !== "object") {
      return languageList;
    }

    if (pt && en && es) {
      return languageList;
    } else if (pt && en) {
      return [languageList[0], languageList[1]];
    } else if (pt && es) {
      return [languageList[0], languageList[2]];
    } else if (en && es) {
      return [languageList[1], languageList[2]];
    } else if (pt) {
      return [languageList[0]];
    } else if (en) {
      return [languageList[1]];
    } else if (es) {
      return [languageList[2]];
    }

    return languageList; // Fallback caso nenhuma condição seja atendida
  };

  const languageList = [
    { value: "pt", label: "Português" },
    { value: "en", label: "English" },
    { value: "es", label: "Español" },
  ];
  
  const setDefaultLanguage = () => {
    if (order.languages.pt === true) {
      return "pt";
    } else if (order.languages.en === true) {
      return "en";
    } else if (order.languages.es === true) {
      return "es";
    }
  };

  async function answerSubmission() {
    setLoading(true);
    answer.language = language;
    if (checkIfAllRequiredAreAnswered()) {
      setButtonLoader(true);
      try {
        // Submit the answers and wait for the response
        const response = await SubmitAnswer(orderId, answer);
        if (response.status == 200) {
          // If the submission was successful, set a flag to show the thank you message
          setSubmissionSuccess(true);
        } else {
          // Handle the case where submission was not successful, e.g., display an error message
          console.error("Answer submission failed:", response.message);
          alert(texts["error"]["languages"]);
          // Optionally, you can set an error state and display an error message to the user.
        }
      } catch (error) {
        console.error("Answer submission error:", error);
        alert(texts["error"]["languages"]);
        // Handle any unexpected errors here, e.g., display an error message to the user.
      }
    }
  }

  // Função para atualizar a largura da barra de progresso com base no scroll
  function atualizarBarraDeProgresso() {
    var winScroll =
      document.documentElement.scrollTop || document.body.scrollTop;
    var height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    document.getElementById("barraProgresso").style.width = `${scrolled}%`;
  }

  // Função para ajustar o padding-top do conteúdo
  function ajustarPaddingTopContent() {
    var alturaHeader = document.querySelector("header").offsetHeight;
    var alturaBarraProgresso =
      document.getElementById("barraProgresso").offsetHeight;
    var alturaTotalHeader = alturaHeader + alturaBarraProgresso;
    document.querySelector(
      ".content"
    ).style.paddingTop = `${alturaTotalHeader}px`;
  }

  setTimeout(() => {
    // Adiciona evento de scroll para atualizar a barra de progresso
    window.addEventListener("scroll", atualizarBarraDeProgresso);
    // Adiciona evento de load e resize para ajustar o padding-top do conteúdo
    window.addEventListener("load", ajustarPaddingTopContent);
    window.addEventListener("resize", ajustarPaddingTopContent);
  }, 300);

  return isOrderAvailable === true ? (
    <div className={styles.content}>
      {order.header.logos ? (
        <div
          className={styles.logoContainer}
          style={{
            visibility: isVisible ? "visible" : "hidden",
            transition: "0.1s",
          }}
        >
          {order.header.logos.logo1 && (order.header.logos.logo2 || order.header.logos.logo3) && order.header.logos.logo1.content && (
            <img
              className={styles.logoSize}
              src={order.header.logos.logo1.content}
              alt="Logo1"
            />
          )}
          {order.header.logos.logo1 && !order.header.logos.logo2 && !order.header.logos.logo3 && (
            <img
              className={styles.OnelogoSize}
              src={order.header.logos.logo1.content}
              alt="Logo1"
            />
          )}
          {order.header.logos.logo2 && order.header.logos.logo2.content && (
            <img
              className={styles.logoSize}
              src={order.header.logos.logo2.content}
              alt="Logo2"
            />
          )}
          {order.header.logos.logo3 && order.header.logos.logo3.content && (
            <img
              className={styles.logoSize}
              src={order.header.logos.logo3.content}
              alt="Logo3"
            />
          )}
        </div>
      ) : (
        <div className={styles.logoContainer} />
      )}
      {typeof order.languages !== "string" &&
        ((order.languages.pt === true && order.languages.en === true) ||
          (order.languages.pt === true && order.languages.es === true) ||
          (order.languages.en === true && order.languages.es === true) ||
          (order.languages.pt === true &&
            order.languages.en === true &&
            order.languages.es === true)) && (
          <div className={styles.selectLanguageContainer}>
            <DropdownList
              defaultValue={setDefaultLanguage()}
              style={{ minWidth: "10rem" }}
              placeholder={"Selecione..."}
              id={"language"}
              listOfItems={setLanguageList()}
              onChange={(event) => {
                let languages;

                if (event === "pt") {
                  languages = "pt";
                } else if (event === "en") {
                  languages = "en";
                } else if (event === "es") {
                  languages = "es";
                }
                let selectedLanguage = order.languages[event] === true;
                selectedLanguage = languages;
                setLanguage(selectedLanguage);
              }}
            />
          </div>
      )}
      <>
        <div className={styles.logoContainerFixed}>
          {order.header.logos && (
            <>
              {order.header.logos.logo1 && (order.header.logos.logo2 || order.header.logos.logo3) && order.header.logos.logo1.content && (
            <img
              className={styles.logoSize}
              src={order.header.logos.logo1.content}
              alt="Logo1"
            />
          )}
              
          {order.header.logos.logo1 && !order.header.logos.logo2 && !order.header.logos.logo3 && (
            <img
              className={styles.OnelogoSizeFixed}
              src={order.header.logos.logo1.content}
              alt="Logo1"
            />
          )}
              {order.header.logos.logo2 && order.header.logos.logo2.content && (
                <img
                  className={styles.logoSizeFixed}
                  src={order.header.logos.logo2.content}
                  alt="Logo2"
                />
              )}
              {order.header.logos.logo3 && order.header.logos.logo3.content && (
                <img
                  className={styles.logoSizeFixed}
                  src={order.header.logos.logo3.content}
                  alt="Logo3"
                />
              )}
            </>
          )}
          <div
            className={styles.caption}
            style={{
              visibility:
                isVisible || isPopupInfoOpenned || sended === true ? "hidden" : "visible",
            }}
          >
            <Info size={32} onClick={() => setIsPopupInfoOpenned(true)} />
          </div>
          {isPopupInfoOpenned === true ? (
            <>
              <div id="captionBlock" className={styles.captionBlock}>
                <div className={MainStyle.m_4}>
                  <span style={{ display: "block" }}>
                    {language === "pt" && "Legenda:"}
                    {language === "en" && "Label:"}
                    {language === "es" && "Subtitular:"}
                  </span>
                  <span>
                    {order.header.surveyCaption &&
                      language === "pt" &&
                      order.header.surveyCaption.pt}
                    {order.header.surveyCaption &&
                      language === "en" &&
                      order.header.surveyCaption.en}
                    {order.header.surveyCaption &&
                      language === "es" &&
                      order.header.surveyCaption.es}
                  </span>
                  {/*<div>
                    <CaretDoubleUp
                      size={24}
                      className={styles.hideInfo}
                      onClick={() => setIsPopupInfoOpenned(false)}
                    />
                  </div>*/}
                </div>
              </div>
              <div
                className={styles.InfoOverlay}
                onClick={() => setIsPopupInfoOpenned(false)}
              ></div>
            </>
          ) : (
            <div className={styles.overlayOff}></div>
          )}
        {sended === false ? (<span
          id="barraProgresso"
          className={styles.barraProgresso}
          style={{ width: "0px" }}
        ></span>):(<span
          id="barraProgresso"
          className={styles.barraProgresso}
          style={{ width: "0px", display: "none" }}
        ></span>)}
        </div>
      </>
      {submissionSuccess === false ? (
        <>
          <div className={MainStyle.row}>
            <div className={MainStyle.col_lg_12}>
              <div className={[MainStyle.text_center, styles.titleSurvey, MainStyle.mx_3].join(" ")}>
                    {order.header.headerLine1 && order.header.headerLine1[language] && (
                      <>{order.header.headerLine1[language]}</>
                    )}
              </div>
              {/*<div className={styles.infoSurvey}>
                <span className={styles.titleSurvey}>
                  {order.header.headerLine1 && order.header.headerLine1[language] && (
                    <span>{order.header.headerLine1[language]}</span>
                  )}
                </span>
              </div>*/}
            </div>
          </div>
          <div className={MainStyle.pt_5}></div>
          <div className={MainStyle.row}>
            <div className={MainStyle.col_lg_2}></div>
            <div className={MainStyle.col_lg_8}>
              <div className={MainStyle.mx_3}>
                <div className={[MainStyle.my_2, MainStyle.text_center].join(" ")}>
                  <div className={[MainStyle.text_center, MainStyle.text_wrap].join(" ")}>
                    {order.header.headerLine2 && order.header.headerLine2[language] && (
                      <>{order.header.headerLine2[language]}</>
                    )}
                  </div>
                </div>
                <div className={[MainStyle.my_2, MainStyle.text_center].join(" ")}>
                  <div className={[MainStyle.text_center, MainStyle.text_wrap].join(" ")}>
                    {order.header.headerLine3 && order.header.headerLine3[language] && (
                      <>{order.header.headerLine3[language]}</>
                    )}
                  </div>
                </div>
                <div className={[MainStyle.my_2, MainStyle.text_center].join(" ")}>
                  <div className={[MainStyle.text_center, MainStyle.text_wrap].join(" ")}>
                    {order.header.headerLine4 && order.header.headerLine4[language] && (
                      <>{order.header.headerLine4[language]}</>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={MainStyle.col_lg_2}></div>
          </div>
        </>
      ) : (
        <></>
      )}

      {order.header.headerLine5 !== null && submissionSuccess === false ? (
        <>
          <div className={MainStyle.pt_5}></div>
          <div className={MainStyle.row}>
            <div className={MainStyle.col_lg_2}></div>
            <div className={MainStyle.col_lg_8}>
              <div className={[MainStyle.mx_2, MainStyle.text_center].join(" ")}>
                {order.header.headerLine5 && order.header.headerLine5[language] && (
                  <div className={[MainStyle.m_2, MainStyle.text_wrap].join(" ")}>
                    {order.header.headerLine5[language]}
                  </div>
                )}
              </div>
            </div>
            <div className={MainStyle.col_lg_2}></div>
          </div>
        </>
      ) : (
        <></>
      )}

      {submissionSuccess ? (
        <div className={styles.thankYouMessageTitle}>
          <h2 style={{ textAlign: "center", fontSize: '3rem' }}>
            {language === "pt" && "Obrigado pela sua avaliação!"}
            {language === "en" && "Thank you for your response!"}
            {language === "es" && "¡Gracias por tu respuesta!"}
          </h2>
          <span className={styles.thankYouMessage}>
            {language === "pt" &&
              "Todas as opiniões e sugestões são cuidadosamente analisadas pela nossa equipe para que possamos nos aprimorar cada vez mais."}
            {language === "en" &&
              "All opinions and suggestions are carefully analyzed by our team so that we can improve more and more."}
            {language === "es" &&
              "Todas las opiniones y sugerencias son cuidadosamente analizadas por nuestro equipo para que podamos mejorar cada vez más."}
          </span>
          {/* You can add any additional content or formatting for the thank you message */}
        </div>
      ) : (
        <>
          {order.questionnaire.length > 0 && answer != null ? (
            order.questionnaire.map((block, blockIndex) => (
              <div className={styles.questionsContainer}>
                <div className={MainStyle.row}>
                  <div className={MainStyle.col_lg_2}></div>
                  <div className={MainStyle.col_lg_8}>
                    <div key={blockIndex} className={MainStyle.mx_3}>
                      {block.blockHeader.headerLines && (
                        <div className={[MainStyle.card_teacher, MainStyle.mx_3].join(" ")}>
                          <div className={[MainStyle.m_2, MainStyle.py_2].join(" ")}>
                            {block.blockHeader.headerLines.map((lines) => {
                              if (lines.type === "teacher") {
                                const designationTranslations = {
                                pt: {
                                  Professor: "Professor(a)",
                                  Convidado: "Convidado",
                                  Palestrante: "Palestrante",
                                },
                                en: {
                                  Professor: "Teacher",
                                  Convidado: "Guest",
                                  Palestrante: "Speaker",
                                },
                                es: {
                                  Professor: "Profesor",
                                  Convidado: "Invitado",
                                  Palestrante: "Ponente",
                                },
                                };

                                const translatedDesignation =
                                  designationTranslations[language][
                                    lines.values.designation
                                  ];
                                return (
                                  <>
                                    <div className={MainStyle.row}>
                                      <div className={MainStyle.col_9}>
                                        <div style={{alignContent: 'center', height: '100%'}} className={MainStyle.mx_2}>
                                              <span className={styles.designation}>
                                                {" "}
                                                {lines.values.designation &&
                                                  translatedDesignation && (
                                                    <span>{translatedDesignation}</span>
                                                  )}
                                                :
                                              </span>
                                              <span className={styles.text}>
                                                {lines.values.name}
                                              </span>
                                        </div>
                                      </div>
                                      <div className={MainStyle.col_3}>
                                      <div className={MainStyle.m_1}>
                                        {lines.values.photo && (lines.values.id !== lines.values.photo || lines.values.photo === defaultAvatar) && (
                                          <div className={[MainStyle.d_flex, MainStyle.justify_content_center].join(" ")}>
                                              <AvatarImage photo_link={lines.values.photo}></AvatarImage>
                                          </div>
                                        )}
                                      </div>                                      
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                              if (
                                lines.type === "labeledText" &&
                                lines.values.label
                              ) {
                                return (
                                <>
                                  <div className={MainStyle.row}>
                                    <div className={MainStyle.col_12}>
                                      <div className={MainStyle.m_2}>
                                        <>
                                          <span
                                            className={
                                              lines.values.labelBold === true
                                                ? styles.labelBold
                                                : styles.label
                                            }
                                          >
                                            {lines.values.label &&
                                              (() => {
                                                switch (language) {
                                                  case "pt":
                                                    return (
                                                      lines.values.label.pt ||
                                                      lines.values.label.es ||
                                                      lines.values.label.en ||
                                                      ""
                                                    );
                                                  case "es":
                                                    return (
                                                      lines.values.label.es ||
                                                      lines.values.label.pt ||
                                                      lines.values.label.en ||
                                                      ""
                                                    );
                                                  case "en":
                                                    return (
                                                      lines.values.label.en ||
                                                      lines.values.label.pt ||
                                                      lines.values.label.es ||
                                                      ""
                                                    );
                                                  default:
                                                    return lines.values.label;
                                                }
                                              })()}
                                            {typeof lines.values.label !== "object" &&
                                              lines.values.label}
                                            {lines.values.label.pt !== null ? ":" : ""}
                                          </span>
                                          <span
                                            className={
                                              lines.values.textBold === true
                                                ? styles.textBold
                                                : styles.text
                                            }
                                          >
                                            {lines.values.text &&
                                              (() => {
                                                switch (language) {
                                                  case "pt":
                                                    return (
                                                      lines.values.text.pt ||
                                                      lines.values.text.es ||
                                                      lines.values.text.en ||
                                                      ""
                                                    );
                                                  case "es":
                                                    return (
                                                      lines.values.text.es ||
                                                      lines.values.text.pt ||
                                                      lines.values.text.en ||
                                                      ""
                                                    );
                                                  case "en":
                                                    return (
                                                      lines.values.text.en ||
                                                      lines.values.text.pt ||
                                                      lines.values.text.es ||
                                                      ""
                                                    );
                                                  default:
                                                    return lines.values.text;
                                                }
                                              })()}
                                            {typeof lines.values.text !== "object" &&
                                              lines.values.text}
                                          </span>
                                        </>
                                      </div>
                                    </div>
                                  </div>
                                </>
                                );
                              }
                              if (
                                lines.type === "labeledText" &&
                                lines.values.label
                              ) {
                              return (
                                <>
                                  <span
                                    className={
                                      lines.values.textBold === true
                                        ? styles.textBold
                                        : styles.text + " " + styles.infoLine
                                    }
                                  >
                                    {lines.values.text &&
                                      (() => {
                                        switch (language) {
                                          case "pt":
                                            return (
                                              lines.values.text.pt ||
                                              lines.values.text.es ||
                                              lines.values.text.en ||
                                              ""
                                            );
                                          case "es":
                                            return (
                                              lines.values.text.es ||
                                              lines.values.text.pt ||
                                              lines.values.text.en ||
                                              ""
                                            );
                                          case "en":
                                            return (
                                              lines.values.text.en ||
                                              lines.values.text.pt ||
                                              lines.values.text.es ||
                                              ""
                                            );
                                          default:
                                            return lines.values.text;
                                        }
                                      })()}
                                    {typeof lines.values.text !== "object" &&
                                      lines.values.text}
                                  </span>
                                </>
                              );
                              }
                            })}
                          </div>
                        </div>
                      )}
                      {block.blockHeader.headerLines === undefined && (
                        <div className={styles.noInfo} />
                      )}
                      <div className={MainStyle.row}>
                        <div className={MainStyle.col_12}>
                          <div className={MainStyle.mx_2}>
                            {highlightQuestions(block.collection).map(
                              (question, questionIndex) => (
                                <>
                                <div className={MainStyle.pt_3}></div>
                                <div key={questionIndex}>
                                  <Question
                                    blockIndex={blockIndex}
                                    lang={language}
                                    questionIndexInBlock={questionIndex}
                                    questionType={question.questionType}
                                    questionData={question}
                                    blockId={block.blockId}
                                    answers={answer}
                                    setAnswer={setAnswer}
                                    highlight={question.highlight}
                                  ></Question>
                                </div>
                                </>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={MainStyle.col_lg_2}></div>
                </div>
                {/*}
                <div className={styles.questionsBlock} key={blockIndex}>
                  {block.blockHeader.headerLines && (
                    <div className={styles.teacherInfo}>
                      {block.blockHeader.headerLines.map((lines) => {
                        if (lines.type === "teacher") {
                          console.log(order);
                          const designationTranslations = {
                            pt: {
                              Professor: "Professor(a)",
                              Convidado: "Convidado",
                              Palestrante: "Palestrante",
                            },
                            en: {
                              Professor: "Teacher",
                              Convidado: "Guest",
                              Palestrante: "Speaker",
                            },
                            es: {
                              Professor: "Profesor",
                              Convidado: "Invitado",
                              Palestrante: "Ponente",
                            },
                          };

                          const translatedDesignation =
                            designationTranslations[language][
                              lines.values.designation
                            ];
                          return (
                            <>
                              <span className={styles.infoLine}>
                                <span className={styles.designation}>
                                  {" "}
                                  {lines.values.designation &&
                                    translatedDesignation && (
                                      <span>{translatedDesignation}</span>
                                    )}
                                  :
                                </span>
                                <span className={styles.text}>
                                  {lines.values.name}
                                </span>
                              </span>
                              {lines.values.photo && (lines.values.id !== lines.values.photo || lines.values.photo === defaultAvatar) && (
                                  <img
                                    src={lines.values.photo}
                                    className={styles.teacherPhoto}
                                  />
                                )}
                            </>
                          );
                        }
                        if (
                          lines.type === "labeledText" &&
                          lines.values.label
                        ) {
                          return (
                            <>
                              <div className={styles.infoLine}>
                                <span
                                  className={
                                    lines.values.labelBold === true
                                      ? styles.labelBold
                                      : styles.label
                                  }
                                >
                                  {lines.values.label &&
                                    (() => {
                                      switch (language) {
                                        case "pt":
                                          return (
                                            lines.values.label.pt ||
                                            lines.values.label.es ||
                                            lines.values.label.en ||
                                            ""
                                          );
                                        case "es":
                                          return (
                                            lines.values.label.es ||
                                            lines.values.label.pt ||
                                            lines.values.label.en ||
                                            ""
                                          );
                                        case "en":
                                          return (
                                            lines.values.label.en ||
                                            lines.values.label.pt ||
                                            lines.values.label.es ||
                                            ""
                                          );
                                        default:
                                          return lines.values.label;
                                      }
                                    })()}
                                  {typeof lines.values.label !== "object" &&
                                    lines.values.label}
                                  {lines.values.label.pt !== null ? ":" : ""}
                                </span>
                                <span
                                  className={
                                    lines.values.textBold === true
                                      ? styles.textBold
                                      : styles.text
                                  }
                                >
                                  {lines.values.text &&
                                    (() => {
                                      switch (language) {
                                        case "pt":
                                          return (
                                            lines.values.text.pt ||
                                            lines.values.text.es ||
                                            lines.values.text.en ||
                                            ""
                                          );
                                        case "es":
                                          return (
                                            lines.values.text.es ||
                                            lines.values.text.pt ||
                                            lines.values.text.en ||
                                            ""
                                          );
                                        case "en":
                                          return (
                                            lines.values.text.en ||
                                            lines.values.text.pt ||
                                            lines.values.text.es ||
                                            ""
                                          );
                                        default:
                                          return lines.values.text;
                                      }
                                    })()}
                                  {typeof lines.values.text !== "object" &&
                                    lines.values.text}
                                </span>
                              </div>
                            </>
                          );
                        }
                        if (
                          lines.type === "labeledText" &&
                          lines.values.label
                        ) {
                          return (
                            <>
                              <span
                                className={
                                  lines.values.textBold === true
                                    ? styles.textBold
                                    : styles.text + " " + styles.infoLine
                                }
                              >
                                {lines.values.text &&
                                  (() => {
                                    switch (language) {
                                      case "pt":
                                        return (
                                          lines.values.text.pt ||
                                          lines.values.text.es ||
                                          lines.values.text.en ||
                                          ""
                                        );
                                      case "es":
                                        return (
                                          lines.values.text.es ||
                                          lines.values.text.pt ||
                                          lines.values.text.en ||
                                          ""
                                        );
                                      case "en":
                                        return (
                                          lines.values.text.en ||
                                          lines.values.text.pt ||
                                          lines.values.text.es ||
                                          ""
                                        );
                                      default:
                                        return lines.values.text;
                                    }
                                  })()}
                                {typeof lines.values.text !== "object" &&
                                  lines.values.text}
                              </span>
                            </>
                          );
                        }
                      })}
                    </div>
                  )}
                  {block.blockHeader.headerLines === undefined && (
                    <div className={styles.noInfo} />
                  )}
                  <div className={styles.questionsBlock}>
                    {block.collection.questions.map(
                      (question, questionIndex) => (
                        <div key={questionIndex}>
                          <Question
                            blockIndex={blockIndex}
                            lang={language}
                            questionIndexInBlock={questionIndex}
                            questionType={question.questionType}
                            questionData={question}
                            blockId={block.blockId}
                            answers={answer}
                            setAnswer={setAnswer}
                          ></Question>
                        </div>
                      )
                    )}
                  </div>
                </div>*/}
              </div>
            ))
          ) : (
            <h2>Este survey não contém questões para serem respondidas.</h2>
          )}
        </>
      )}
      {submissionSuccess == true ? (
        <div className={styles.sendButton}>
          <Button className={"secondaryBlue"} onClick={() => navigate("/")}>
            {texts["home"][language]}
          </Button>
        </div>
      ) : (
        <div className={styles.sendButton}>
          {buttonLoader == false ? (
            <Button
              className={"sendButton"}
              onClick={ () => {
                  setSended(true);
                 answerSubmission();

                // console.log(answer);
              }}
            >
              {texts["submit"][language]}
            </Button>
          ) : (
            <ButtonLoader />
          )}
        </div>
      )}
    </div>
  ) : (
    <div>
      {order.header.logos ? (
        <div className={styles.logoContainer}>
          {order.header.logos.logo1 && order.header.logos.logo1.content && (
            <img
              className={styles.logoSize}
              src={order.header.logos.logo1.content}
              alt="Logo1"
            />
          )}
          {order.header.logos.logo2 && order.header.logos.logo2.content && (
            <img
              className={styles.logoSize}
              src={order.header.logos.logo2.content}
              alt="Logo2"
            />
          )}
          {order.header.logos.logo3 && order.header.logos.logo3.content && (
            <img
              className={styles.logoSize}
              src={order.header.logos.logo3.content}
              alt="Logo3"
            />
          )}
        </div>
      ) : (
        <div className={styles.logoContainer} />
      )}
      {typeof order.languages !== "string" &&
        ((order.languages.pt === true && order.languages.en === true) ||
          (order.languages.pt === true && order.languages.es === true) ||
          (order.languages.en === true && order.languages.es === true) ||
          (order.languages.pt === true &&
            order.languages.en === true &&
            order.languages.es === true)) && (
          <div className={styles.selectLanguageContainer}>
            <DropdownList
              defaultValue={setDefaultLanguage()}
              style={{ minWidth: "10rem" }}
              placeholder={"Selecione..."}
              id={"language"}
              listOfItems={setLanguageList()}
              onChange={(event) => {
                let languages;

                if (event === "pt") {
                  languages = "pt";
                } else if (event === "en") {
                  languages = "en";
                } else if (event === "es") {
                  languages = "es";
                }
                let selectedLanguage = order.languages[event] === true;
                selectedLanguage = languages;
                setLanguage(selectedLanguage);
              }}
            />
          </div>
      )}

      <>
      {sended === false ? (
        <div className={styles.logoContainerFixed}>
          {order.header.logos && (
            <>
              {order.header.logos.logo1 && order.header.logos.logo1.content && (
                <img
                  className={styles.logoSizeFixed}
                  src={order.header.logos.logo1.content}
                  alt="Logo1"
                />
              )}
              {order.header.logos.logo2 && order.header.logos.logo2.content && (
                <img
                  className={styles.logoSizeFixed}
                  src={order.header.logos.logo2.content}
                  alt="Logo2"
                />
              )}
              {order.header.logos.logo3 && order.header.logos.logo3.content && (
                <img
                  className={styles.logoSizeFixed}
                  src={order.header.logos.logo3.content}
                  alt="Logo3"
                />
              )}
            </>
          )}
          {order.header.surveyCaption && (
            <div
              className={styles.caption}
              style={{
                visibility:
                  isVisible || isPopupInfoOpenned === true
                    ? "hidden"
                    : "visible",
              }}
            >
              <Info size={32} onClick={() => setIsPopupInfoOpenned(true)} />
            </div>
          )}
          {isPopupInfoOpenned === true ? (
            <>
              <div className={styles.captionBlock}>
                <div className={MainStyle.m_2}>
                  <span style={{ display: "block" }}>
                    {language === "pt" && "Legenda:"}
                    {language === "en" && "Label:"}
                    {language === "es" && "Subtitular:"}
                  </span>
                  <span>
                    {order.header.surveyCaption &&
                      language === "pt" &&
                      order.header.surveyCaption.pt}
                    {order.header.surveyCaption &&
                      language === "en" &&
                      order.header.surveyCaption.en}
                    {order.header.surveyCaption &&
                      language === "es" &&
                      order.header.surveyCaption.es}
                  </span>
                  {/*<div>
                    <CaretDoubleUp
                      size={24}
                      className={styles.hideInfo}
                      onClick={() => setIsPopupInfoOpenned(false)}
                    />
                  </div>*/}
                </div>
              </div>
              <div
                className={styles.InfoOverlay}
                onClick={() => setIsPopupInfoOpenned(false)}
              ></div>
            </>
          ) : (
            <div className={styles.overlayOff}></div>
          )}
        </div>
        ):(<></>)}
      </>

      {order.header && (
        <div className={styles.infoSurvey}>
          {/* {console.log(order)} */}
          <span className={styles.titleSurvey}>
            {order.header.headerLine1 && order.header.headerLine1[language] && (
              <span>{order.header.headerLine1[language]}</span>
            )}
          </span>
          <br />
          <span>
            {order.header.headerLine2 && order.header.headerLine2[language] && (
              <span>{order.header.headerLine2[language]}</span>
            )}
          </span>
          <br />
          <span>
            {order.header.headerLine3 && order.header.headerLine3[language] && (
              <span>{order.header.headerLine3[language]}</span>
            )}
          </span>
          <br />
          <span>
            {order.header.headerLine4 && order.header.headerLine4[language] && (
              <span>{order.header.headerLine4[language]}</span>
            )}
          </span>
        </div>
      )}
      {order.header.headerLine5 !== null && (
        <div className={styles.explanation}>
          {order.header.headerLine5 && order.header.headerLine5[language] && (
            <span>{order.header.headerLine5[language]}</span>
          )}
        </div>
      )}
      {/* You can add any additional content or formatting for the thank you message */}
      <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "6rem",
      }}
      >
        <CalendarX size={32} weight="fill" style={{ marginBottom: "16px" }} />{" "}
        {/* Display the Calendar-X icon at the top */}
        {language === "pt" && <span>Esta avaliação não está disponível.</span>}
        {language === "es" && <span>Esta evaluación no está disponible.</span>}
        {language === "en" && <span>This survey is not available.</span>}
      </div>
    </div>
  );
};

export default Answer;
