import Alert from "components/Alert/Alert";
import ReactDOM from "react-dom";
import { archiveAssessmentById } from "components/AssessmentLists/assessmentListsServices";
import { getGroupedResults } from "Services/results";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import Layout from "components/Layout/layout";
import PageTitle from "components/PageTitle/pageTitle";
import Pagination from "components/Pagination/pagination";
import TableControl from "components/TableControl/tableControl";
import {
  Archive,
  ArrowsDownUp,
  DotsThree,
  Eye,
  FilePdf,
  X,
} from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import ReactLoading from "react-loading";
import { useNavigate, useParams } from "react-router-dom";
import { addNewAlert } from "Services/alertsManagement";
import { useAuthenticator } from "@aws-amplify/ui-react";
import styles from "./reportsList.module.scss";
import { getAllReports, getFilteredOrdersAndSetState } from "./reportsService";
import Button from "components/Button/button";
import { DefaultReportTemplate, font_style_pdf } from "components/DefaultReport/defaultReport";
import { styled, Tooltip, tooltipClasses } from "@mui/material";
import { getAssessmentById } from "components/EditAssessment/assessmentService";
import Loading from "components/Loading/loading";

// Main Style with usefull responsives grid classes to be used
import MainStyle from "components/UI/main.module.scss";

const ReportsList = () => {
  const navigate = useNavigate();
  const [memResultList, setMemResultList] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tablePagination, setTablePagination] = useState([]);
  const [slice, setSlice] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [alerts, setAlerts] = useState([]);
  const [listIsSorted, setListIsSorted] = useState(false);
  const [showMoreActions, setShowMoreActions] = useState({});
  const [ascendingOrDescending, setAscendingOrDescending] =
    useState("ascending");
  let { orderId } = useParams();
  const [order, setOrder] = useState();
  const [results, setResults] = useState();
  const [groupedQuestions, setGroupedQuestions] = useState();
  const pdfExportComponent = useRef(null);
  const [fileName, setFileName] = useState("");

  const { user } = useAuthenticator((context) => [context.user]);

  const currentUrl = window.location.href

  const capabilities = localStorage.getItem("capabilities#" + user.username);

  useEffect(() => {

    if (capabilities.includes("resultados")) {
      if (resultList.length == 0) {
        getAllReports(setResultList, setMemResultList, setLoading);
      }
    } else {
      setLoading(false);
    }
  }, []);



  //Sort list by specific key or restore original response
  function sortObjByKey(key, order = null) {
    let _resultList = [...resultList];

    function compare(a, b) {
      function parseDate(dateString) {
        // Assuming the date format is "DD/MM/YYYY"
        const [day, month, year] = dateString.split('/').map(Number);
        return new Date(year, month - 1, day);
      }

      function getDateValue(obj, key) {
        console.log(obj)
        return key.includes('.')
          ? key.split('.').reduce((acc, cur) => acc && acc[cur], obj)
          : obj[key];
      }

      const dateA = getDateValue(a, key);
      const dateB = getDateValue(b, key);
      const isDateKey = key === 'initialDate' || key === 'finalDate';

      if (isDateKey) {
        // Parse dates and compare
        const parsedDateA = parseDate(dateA);
        const parsedDateB = parseDate(dateB);

        if (order === 'ascending') return parsedDateA - parsedDateB;
        else if (order === 'descending') return parsedDateB - parsedDateA;
        else return parsedDateA - parsedDateB; // Default sort (ascending)

      } else {
        // Compare as strings or numbers
        const type =
          typeof dateA === 'string' || typeof dateB === 'string' ? 'string' : 'number';
        let _resultList;

        if (type === 'string') {
          _resultList = dateA.localeCompare(dateB);
        }

        else {
          _resultList = dateB.localeCompare(dateA);
        }
        return _resultList;
      }
    }

    // Check if the list was ever sorted during the session
    if (memResultList.length === 0 && listIsSorted === false) {
      // Save the original API response to memory
      setMemResultList(resultList);

      // Sort the user list
      _resultList = _resultList.sort(compare);
      setListIsSorted(true);
    } else {
      // In case the list was already sorted before
      // In case the list isn't currently sorted
      if (listIsSorted === false && memResultList.length > 0) {
        _resultList = _resultList.sort(compare);
        setListIsSorted(true);
      } else {
        // In case the list is currently sorted
        _resultList = _resultList.sort(compare);
        setListIsSorted(false);
      }
    }

    setResultList(_resultList);
    return _resultList;
  }

  //Sort list by specific key or restore original response
  function sortObjByKeyTitle(key) {
    let _resultList = [...resultList];
    function compare(a, b) {
      a = a[key];
      b = b[key];
      var type =
        typeof a === "string" || typeof b === "string" ? "string" : "number";
      var result;
      if (type === "string") result = a.localeCompare(b);
      else result = a - b;
      return result;
    }

    //Check if list was ever sorted during the session
    if (memResultList.length == 0 && listIsSorted == false) {
      //Saves original user API response to memmory
      setMemResultList(resultList);

      //sort user list
      _resultList = _resultList.sort(compare);
      setListIsSorted(true);
    }

    //In case list was already sorted before
    else {
      //In case list isn't currently sorted
      if (listIsSorted == false && memResultList.length > 0) {
        _resultList = _resultList.sort(compare);
        setListIsSorted(true);
      }
      //In case list is currently sorted
      else {
        _resultList = memResultList;
        setListIsSorted(false);
      }
    }

    setResultList(_resultList);
    return _resultList;
  }

  
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  function organizeBlocks(questionnaire) {
    let organizedQuestionnaire = [];
    //let _questionnaire = [...questionnaire]
    questionnaire &&
      questionnaire.map((block, index) => {
        //let organizedBlock = []
        let organizedQuestions = collectionOrganizer(
          block.collection.questions
        );
        //block.collection.questions = collectionOrganizer(listOfQuestions)
        let thisBlock = JSON.parse(JSON.stringify(block));
        thisBlock.collection.questions = organizedQuestions;

        organizedQuestionnaire.push(thisBlock);
      });
    return organizedQuestionnaire;
  }

  function collectionOrganizer(questions) {
    let organizedCollection = [];
    let group = null;
    let lastGroupIndex = null;
    questions &&
      questions.map((question, index) => {
        if (question.questionType === "label") {
          group = {
            ...question,
            type: "group",
            label: question.header,
            questions: [],
          };

          lastGroupIndex = index;
          organizedCollection.push(group);
        } else {
          if (lastGroupIndex != null) {
            group.questions.push(question);
          } else {
            let _question = { ...question };
            _question.type = "question";
            organizedCollection.push(_question);
          }
        }
      });
    return organizedCollection;
  }

  const renderContent = () => {
    switch (loading) {
      case true:
        return (<Loading/>);
      default:
        if (capabilities.includes("resultados")) {
          return (
            <div>
              <TableControl
                // setList={getFilteredOrdersAndSetState}
                resultList={resultList}
                setResultList={setResultList}
                originalListTable={memResultList}
                setTablePagination={setTablePagination}
                setSlice={setSlice}
                currentPage={(currentPage > tablePagination.length) ?  1 : currentPage}
                setCurrentPage={setCurrentPage}
                showByPageDefault={10}
                searchPlaceholder={"Buscar Avaliação"}
                filterableParameters={[
                  {
                    parameter: "statusLabel",
                    displayName: "Status",
                    dataType: "string",
                  },
                ]}
                hideBulkActions={true}
              ></TableControl>

              <Pagination
                tablePagination={tablePagination}
                slice={slice}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              ></Pagination>
              {alerts.map((i, k) => {
                return <Alert message={i.message} type={i.type} />;
              })}
              <table className={styles.table}>
                <thead>
                  <tr style={{ backgroundColor: "white" }}>
                    <th
                      style={{
                        width: "40rem",
                        textAlign: "left",
                        paddingLeft: "30px",
                      }}
                    >
                      Título
                      <ArrowsDownUp
                        onClick={() => sortObjByKeyTitle("title")}
                        className={styles.arrowsDownUp}
                        size={16}
                      />
                    </th>
                    <th style={{ width: "15%" }}>
                      Data da Avaliação
                      <ArrowsDownUp
                        onClick={() => {
                          if (ascendingOrDescending === "ascending")
                            setAscendingOrDescending("descending");
                          else setAscendingOrDescending("ascending");
                          sortObjByKey(
                            "initialDate",
                            ascendingOrDescending === "descending"
                              ? "ascending"
                              : "descending"
                          );
                        }}
                        className={styles.arrowsDownUp}
                        size={16}
                      />
                    </th>
                    <th style={{ width: "15%" }}>
                      Final da Coleta
                      <ArrowsDownUp
                        onClick={() => {
                          if (ascendingOrDescending === "ascending")
                            setAscendingOrDescending("descending");
                          else setAscendingOrDescending("ascending");
                          sortObjByKey(
                            "finalDate",
                            ascendingOrDescending === "descending"
                              ? "ascending"
                              : "descending"
                          );
                        }}
                        className={styles.arrowsDownUp}
                        size={16}
                      />
                    </th>
                    <th style={{ width: "15%" }}>
                      Respostas
                      <ArrowsDownUp
                        onClick={() => sortObjByKeyTitle("submissions")}
                        className={styles.arrowsDownUp}
                        size={16}
                      />
                    </th>
                    <th style={{ width: "15%" }}>
                      Status
                      <ArrowsDownUp
                        onClick={() => sortObjByKeyTitle("statusLabel")}
                        className={styles.arrowsDownUp}
                        size={16}
                      />
                    </th>
                    <th style={{ width: "10%" }}>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {slice.map((item, i) => {
                    let id = item.id;
                    let initialDate = item.initialDate.split("/");
                    let initialDateFormatted = `${initialDate[1]}/${initialDate[0]}/${initialDate[2]}`;
                    let readyToView =
                      Date.parse(initialDateFormatted) < Date.now();
                    const fetchOrderAndResults = async () => {
                      try {
                        const orderData = await getAssessmentById(id);
                        setOrder(orderData);
                        setGroupedQuestions(
                          organizeBlocks(orderData.questionnaire)
                        );

                        const resultsData = await getGroupedResults(id);
                        setResults(resultsData);
                      } catch (error) {
                        console.error("Error fetching data:", error);
                        navigate("/notFound");
                      }
                    };

                    const exportPDFWithComponent = async () => {
                      await fetchOrderAndResults();
                      if (pdfExportComponent.current) {
                        setTimeout(() => {
                          pdfExportComponent.current.save();
                          let message = "PDF gerado com sucesso!";
                          let updateAlertList = addNewAlert([], {
                            message,
                            type: "success",
                          });
                          setAlerts(updateAlertList);
                        }, 500);
                      }
                    };

                    const handleExportClick = () => {
                      setFileName(item.title);
                      exportPDFWithComponent();
                      let message = "Aguarde enquanto o PDF é gerado...";
                      let updateAlertList = addNewAlert([], {
                        message,
                        type: "info",
                      });
                      setAlerts(updateAlertList);
                    };
                    return (
                      <tr
                        key={i}
                        id={"actions" + i}
                        className={styles.tableRow}
                      >
                        <td
                          style={{maxWidth: '250px'}}
                          className={
                            styles.reportRow +
                            " " +
                            styles.reportTitle +
                            " " +
                            styles.rowTitle + 
                            " " +
                            MainStyle.text_truncate
                          }
                        >
                          {item.title}
                        </td>
                        <td className={styles.reportRow}>{item.initialDate}</td>
                        <td className={styles.reportRow}>{item.finalDate}</td>
                        <td
                          className={styles.reportRow}
                          style={{ fontWeight: "600" }}
                        >
                          {item.submissions}
                        </td>
                        <td className={styles.reportRow}>
                          <span
                            className={
                              item.itemStatus == "ready"
                                ? readyToView === true
                                  ? styles.readyLabelYellow
                                  : styles.readyLabelRed
                                : styles.closedLabel
                            }
                          >
                            {item.statusLabel}
                          </span>
                        </td>
                        <td className={styles.actionsContainer}>
                          <Eye
                            size={28}
                            className={styles.showReportButton}
                            onClick={() =>
                              window.open("/report/" + id, "_blank").focus()
                            }
                          />
                          <div className={styles.helpPopupShowReport}>
                            Ver Relatório
                          </div>
                          <FilePdf
                            size={28}
                            className={styles.downloadReportButton}
                            onClick={() => {
                              handleExportClick();
                            }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              left: "-10000px",
                              top: "-10000px",
                              fontFamily: "Open Sans",
                              textAlign: "left",
                            }}
                          >
                          <DefaultReportTemplate
                                order={order}
                                questions={groupedQuestions}
                                results={results}
                                pdfExportComponent={pdfExportComponent}
                                exportPDFWithComponent={exportPDFWithComponent}
                          />
                          </div>
                          <div className={styles.helpPopupdownload}>
                            Download PDF
                          </div>
                          <DotsThree
                            className={styles.moreActionsButton}
                            size={28}
                            onClick={() => {
                              let _showMoreActions = { ...showMoreActions };
                              _showMoreActions = {};
                              if (!showMoreActions[id]) {
                                _showMoreActions[id] = true;
                              }
                              setShowMoreActions(_showMoreActions);
                            }}
                          />
                          <div className={styles.helpPopupAction}>
                            Mais ações
                          </div>
                          {showMoreActions[id] == true ? (
                            <MoreActionsReportsList
                              index={i}
                              id={item.id}
                              setShowMoreActions={setShowMoreActions}
                              showMoreActions={showMoreActions}
                              alerts={alerts}
                              setAlerts={setAlerts}
                              setResultList={setResultList}
                              setMemResultList={setMemResultList}
                              setLoading={setLoading}
                            ></MoreActionsReportsList>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Pagination
                tablePagination={tablePagination}
                slice={slice}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              ></Pagination>
            </div>
          );
        } else {
          return (
            <div className={styles.error}>
              <center>
                <Alert
                  type={"danger"}
                  message={
                    "Você não possui permissão para acessar essa página. Por favor, entre em contato com o administrador do sistema."
                  }
                />
              </center>
            </div>
          );
        }
    }
  };

  return (
    <Layout>
      <div>
        <div className={styles.pageHeader}>
          <Button
            title={"Avaliações Arquivadas"}
            className={styles.archiveButton}
            onClick={() => navigate("/archivedReports")}
          >
            <Archive size={28} className={styles.archiveIcon} />
          </Button>
          <PageTitle center={true}>Relatórios</PageTitle>
        </div>
        {renderContent()}
      </div>
    </Layout>
  );
};

export default ReportsList;

export const MoreActionsReportsList = ({
  index,
  id,
  setShowMoreActions,
  showMoreActions,
  alerts,
  setAlerts,
  setResultList,
  setMemResultList,
  setLoading,
}) => {
  const wrapperRef = useRef(null);

  const [activePopUp, setActivePopUp] = useState(true);

  const [archiveConfirmationModal, setArchiveConfirmationModal] =
    useState(false);

  //This const return if a element is alredy in view
  const [refInView, inView] = useInView();

  useOutsideAlerter(wrapperRef, () => setActivePopUp(false));

  const archiveReport = () => {
    setLoading(true);
    let _showMoreActions = { ...showMoreActions };
    _showMoreActions = {};
    setShowMoreActions(_showMoreActions);
    archiveAssessmentById(id).then((response) => {
      if (response.status == 200) {
        setArchiveConfirmationModal(false);
        let message = "Relatório arquivado com sucesso!";
        let updateAlertList = addNewAlert([], { message, type: "success" });
        setAlerts(updateAlertList);
        getAllReports(setResultList, setMemResultList, setLoading);
      } else {
        setArchiveConfirmationModal(false);
        let message = response.body.message;
        let updateAlertList = addNewAlert([], { message, type: "danger" });
        setAlerts(updateAlertList);
      }
    });
  };
  function useOutsideAlerter(ref, handler) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setActivePopUp(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, handler]);
  }

  useOutsideAlerter(wrapperRef);
  return (
    <div
      ref={wrapperRef}
      key={index}
      id={"actions" + index}
      className={styles.moreActionsPopup}
      style={{ display: activePopUp ? "block" : "none" }}
    >
      <div ref={refInView} className={styles.popupHeader}>
        Mais ações
        <X
          size={16}
          className={styles.closeMoreActions}
          onClick={() => setActivePopUp(false)}
        />
      </div>
      <ConfirmationModal
        id={"archiveReport"}
        iconComponent={<div className={styles.popupOption}>Arquivar</div>}
        title={"Deseja realmente arquivar este relatório?"}
        onClick={() => setArchiveConfirmationModal(true)}
        open={archiveConfirmationModal}
        onOk={() => archiveReport()}
        onCancel={() => {
          setArchiveConfirmationModal(false);
          let _showMoreActions = { ...showMoreActions };
          _showMoreActions = {};
          setShowMoreActions(_showMoreActions);
        }}
      />
    </div>
  );
};
