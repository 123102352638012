import Alert from "components/Alert/Alert";
import Button from "components/Button/button";
import DropdownListField from "components/DropdownListField/dropdownListFIeld";
import Field from "components/Field/field";
import Layout from "components/Layout/layout";
import PageTitle from "components/PageTitle/pageTitle";
import Pagination from "components/Pagination/pagination";
import TableControl from "components/TableControl/tableControl";
import Select from "react-select";
import { getUsers } from "components/UsersList/usersServices";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  ArrowsDownUp,
  Briefcase,
  Buildings,
  ChalkboardTeacher,
  GraduationCap,
  Notebook,
  Pencil,
  PlusCircle,
  UserList,
  UserPlus
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { addNewAlert } from "Services/alertsManagement";

import styles from "./generalLists.module.scss";
import {
  createParameterItem,
  getAllParameterItems,
  getParameterItems,
  updateParameterItem,
  grantAccessToUser,
  removeAccessOfUser
} from "./generalListsServices";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import Loading from "components/Loading/loading";

const GeneralLists = () => {
  const [currentButton, setCurrentButton] = useState("school");
  const [loading, setLoading] = useState(true);
  const [ loadingUsers, setLoadingUsers] = useState(false)
  const [isPopupOpenned, setIsPopupOpenned] = useState(false);
  const [memResultList, setMemResultList] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [tablePagination, setTablePagination] = useState([]);
  const [slice, setSlice] = useState([]);
  const [indexOfItemInSlice, setIndexOfItemInSlice] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [newItemTitle, setNewItemTitle] = useState();
  const [newType, setNewType] = useState(currentButton);
  const [hierarchy, setHierarchy] = useState();
  const [alertsPopup, setAlertsPopup] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [hierarchyAreaList, setHierarchyAreaList] = useState([]);
  const [hierarchyProgramList, setHierarchyProgramList] = useState([]);
  const [schoolsList, setSchoolsList] = useState([]);
  const [areasList, setAreasList] = useState([]);
  const [isPopupEditOpenned, setIsPopupEditOpenned] = useState(false);
  const [isPopupPermissionOpenned, setIsPopupPermissionOpenned] = useState(false);
  const [currentTitle, setCurrentTitle] = useState();
  const [currentItemId, setCurrentItemId] = useState();
  const [listIsSorted, setListIsSorted] = useState(false);
  const [changingConfirmationModal, setChangingConfirmationModal] =
    useState(false);
  const [usersList, setUsersList] = useState([])
  const [filteredUsersList, setFilteredUsersList] = useState([])
  const [opennedItem, setOpenneditem] = useState()
  const [selectedUser, setSelectedUser] = useState()
  const [accessList, setAccessList] = useState([])
  const [ascendingOrDescendingTitle, setAscendingOrDescendingTitle] = useState(null);

  const { user } = useAuthenticator((context) => [context.user]);

  const capabilities = localStorage.getItem("capabilities#" + user.username);

  const parametersList = [
    { value: "school", label: "Escolas" },
    { value: "area", label: "Áreas" },
    { value: "program", label: "Programas" },
    { value: "company", label: "Empresas" },
    { value: "field", label: "Área acadêmica" },
  ];

  const getUsersList = async () => {
    let dataUsers = await getUsers();
    let _usersList = [];
    dataUsers.map((user) => {
      const userLabel = user.first_name + " (" + user.email + ")";
      _usersList.push({ value: user.id, label: userLabel, photo: user.photo, name: user.first_name + " " + user.last_name });
    });
    setUsersList(_usersList);
  };

  const getHierarchyAreaList = async () => {
    let dataSchool = await getParameterItems("school");
    setSchoolsList(dataSchool);
    let _hierarchyAreaList = [...hierarchyAreaList];
    _hierarchyAreaList = [];
    dataSchool.map((item) => {
      if (_hierarchyAreaList.indexOf(item.title) === -1) {
        //if the the school already is not in the array, put it
        _hierarchyAreaList.push({ value: item.title, label: item.title });
      }
    });
    setHierarchyAreaList(_hierarchyAreaList);
  };

  const getHierarchyProgramList = async () => {
    let dataArea = await getParameterItems("area");
    setAreasList(dataArea);
    let _hierarchyProgramList = [...hierarchyProgramList];
    _hierarchyProgramList = [];
    dataArea.map((item) => {
      if (_hierarchyProgramList.indexOf(item.title) === -1) {
        //if the the school already is not in the array, put it
        _hierarchyProgramList.push({ value: item.title, label: item.title });
      }
    });
    setHierarchyProgramList(_hierarchyProgramList);
  };

  const setParametersLists = () => {
    setLoading(true);
    getHierarchyAreaList();
    getHierarchyProgramList();
    setLoading(false);
  };

  const handleHierarchyList = () => {
    return currentButton == "area" ? hierarchyAreaList : hierarchyProgramList;
  };

 //Sort list by specific key or restore original response
 function sortObjByKey(key, order = null) {
  let _resultList = [...resultList];

  function compare(a, b) {
    function parseDate(dateString) {
      // Assuming the date format is "DD/MM/YYYY"
      const [day, month, year] = dateString.split('/').map(Number);
      return new Date(year, month - 1, day);
    }

    function getDateValue(obj, key) {
      console.log(obj)
      return key.includes('.')
        ? key.split('.').reduce((acc, cur) => acc && acc[cur], obj)
        : obj[key];
    }

    const dateA = getDateValue(a, key);
    const dateB = getDateValue(b, key);    
    const isDateKey = key === 'initialDate' || key === 'finalDate';

    if (isDateKey) {
      // Parse dates and compare
      const parsedDateA = parseDate(dateA);
      const parsedDateB = parseDate(dateB); 

      if (order === 'ascending') return parsedDateA - parsedDateB;
      else if (order === 'descending') return parsedDateB - parsedDateA;
      else return parsedDateA - parsedDateB; // Default sort (ascending)

    } else {
      // Compare as strings or numbers
      const type =
        typeof dateA === 'string' || typeof dateB === 'string' ? 'string' : 'number';
      let _resultList;

      if (type === 'string' && ascendingOrDescendingTitle === 'A') {    
        setAscendingOrDescendingTitle('B')
        _resultList = dateA.localeCompare(dateB);
      }         
        
      else {       
        setAscendingOrDescendingTitle('A')
        _resultList = dateB.localeCompare(dateA);
      }
      return _resultList;
    }
  }

  // Check if the list was ever sorted during the session
  if (memResultList.length === 0 && listIsSorted === false) {
    // Save the original API response to memory
    setMemResultList(resultList);

    // Sort the user list
    _resultList = _resultList.sort(compare);
    setListIsSorted(true);
  } else {
    // In case the list was already sorted before
    // In case the list isn't currently sorted
    if (listIsSorted === false && memResultList.length > 0) {
      _resultList = _resultList.sort(compare);
      setListIsSorted(true);
    } else {
      // In case the list is currently sorted
      _resultList = _resultList.sort(compare);
      setListIsSorted(false);
    }
  }

  setResultList(_resultList);
  return _resultList;
}

  useEffect(() => {
    setParametersLists();
  }, [currentButton]);

  useEffect(() => {
    setLoading(true);
    getUsersList();
    getAllParameterItems(
      setResultList,
      setMemResultList,
      setLoading,
      currentButton
    );
  }, [currentButton]);

  const validationItem = () => {
    let isValid = true;
    if (newItemTitle == undefined || newItemTitle == "") {
      return (isValid = false);
    }

    if (newType == undefined || newType == "") {
      return (isValid = false);
    }

    if (currentButton == "area" || currentButton == "program") {
      if (hierarchy == undefined || hierarchy == "") {
        return (isValid = false);
      }
    }
    return isValid;
  };

  const buildItemBody = () => {
    if (currentButton == "area" || currentButton == "program") {
      let parentId = "";
      if (currentButton == "area") {
        let schoolSelected = schoolsList.filter(
          (school) => school.title == hierarchy
        );
        parentId = schoolSelected[0].pk;
      }
      if (currentButton == "program") {
        let areaSelected = areasList.filter((area) => area.title == hierarchy);
        parentId = areaSelected[0].pk;
      }
      return {
        title: newItemTitle,
        parent: parentId,
      };
    } else {
      return {
        title: newItemTitle,
      };
    }
  };

  const editItemTitle = async () => {
    const isValid = newItemTitle ? true : false;
    let updateAlertList = "";

    if (isValid == true) {
      let body = {
        title: newItemTitle,
      };

      setAlertsPopup([]);
      updateParameterItem(body, currentButton, currentItemId).then(
        (response) => {
          let messageResponse = response.message;
          if (
            messageResponse.includes("Parameter title successfully updated!")
          ) {
            getAllParameterItems(
              setResultList,
              setMemResultList,
              setLoading,
              currentButton
            );

            setIsPopupEditOpenned(false);
            setChangingConfirmationModal(false);
            setNewItemTitle("");

            let message = "Item alterado com sucesso!";
            updateAlertList = addNewAlert([], { message, type: "success" });

            setAlerts(updateAlertList);
          } else {
            let message = "Erro ao tentar alterar item!";
            updateAlertList = addNewAlert([], { message, type: "danger" });
            setAlertsPopup(updateAlertList);
          }
        }
      );
    } else {
      let message = "Preencha o campo obrigatório!";
      updateAlertList = addNewAlert([], { message, type: "danger" });
      setAlertsPopup(updateAlertList);
    }
  };


  const addNewItem = async () => {
    const isValid = await validationItem();
    let updateAlertList = "";
    setAlertsPopup([]);
    if (isValid == true) {
      let body = buildItemBody();

      createParameterItem(body, currentButton).then((response) => {
        let messageResponse = response.message;
        if (messageResponse.includes("Parâmetro criado com sucesso!")) {
          getAllParameterItems(
            setResultList,
            setMemResultList,
            setLoading,
            currentButton
          );

          setIsPopupOpenned(false);

          let message = "Item criado com sucesso!";
          updateAlertList = addNewAlert([], { message, type: "success" });

          setAlerts(updateAlertList);
        } else {
          let message = "Erro ao tentar criar!";
          updateAlertList = addNewAlert([], { message, type: "danger" });
          setAlertsPopup(updateAlertList);
        }
      });
    } else {
      let message = "Preencha os campos obrigatórios!";
      updateAlertList = addNewAlert([], { message, type: "danger" });
      setAlertsPopup(updateAlertList);
    }

    setNewItemTitle("");
    setHierarchy("");
  };

  const handleTabClass = (button) => {
    let tabsButtonClass =
      button == currentButton ? styles.tabsButtonSelected : "secondaryBlue";

    return styles.tabsButton + " " + tabsButtonClass;
  };

  function controlPopUp() {
    if (isPopupOpenned == true) {
      setAlertsPopup([]);
      setIsPopupOpenned(false);
      setNewItemTitle("");
      setHierarchy("");
    } else {
      setIsPopupOpenned(true);
    }
  }

  function controlEditPopUp() {
    if (isPopupEditOpenned == true) {
      setAlertsPopup([]);
      setIsPopupEditOpenned(false);
      setNewItemTitle("");
      setCurrentTitle("");
      setCurrentItemId("");
    } else {
      setIsPopupEditOpenned(true);
    }
  }

  function getParameterType(pk) {
    let splitPk = pk.split("#")
    return splitPk[1]
  }

  function getParameterId(pk) {
    let splitPk = pk.split("#")
    return splitPk[2]
  }
  
  // remove items from one arr to another that have the same properties
  function removeUsrFromArr(arr0, arr1) {
    // arr0 = original first array
    // arr1 = second array with the selected items
    let arr = [];

    for (let j = 0; j < arr0.length; j++) {
        let found = false;
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i].userId === arr0[j].value) {
                found = true;
                break;
            }
        }
        if (!found) {
            arr.push(arr0[j]); 
        }
    }
    return arr;
  }

  const grantUserAccess = async (parameterType, parameterId, userId) => {
    let grantAccess = await grantAccessToUser(parameterType, parameterId, userId)
    console.log(grantAccess)
    //opennedItem.accessGranted.push({userId: userId, grantedOn: "Agora"}) 
    return grantAccess
  }

  const removeUserAccess = async (parameterType, parameterId, userId) => {
    let removeAccess = await removeAccessOfUser(parameterType, parameterId, userId)
    console.log(removeAccess)
    //opennedItem.accessGranted.push({userId: userId, grantedOn: "Agora"}) 
    return removeAccess
  }

  const renderContent = () => {
    
    switch (loading) {
      case true:
        return (
          <div className={styles.loading}>
          <div className="large">Carregando informações</div>
          <center>
            <ReactLoading
              type="bubbles"
              color="#0064a2"
              height={50}
              width={175}
            />
          </center>
        </div>
        // <Loading />
        );

      case false:
        if (capabilities.includes("cadastros")) {

          return(
            <>
            <div className={styles.tabsContainer}>
          <Button
            className={handleTabClass("school")}
            onClick={() => {
              setCurrentButton("school");
            }}
          >
            <GraduationCap size={22} className={styles.addIcon} />
            <span style={{ paddingBottom: "3px" }}>Escolas</span>
          </Button>
          <Button
            className={handleTabClass("area")}
            onClick={() => {
              setCurrentButton("area");
            }}
          >
            <Briefcase size={22} className={styles.addIcon} />
            <span style={{ paddingBottom: "3px" }}>Áreas</span>
          </Button>
          <Button
            className={handleTabClass("program")}
            onClick={() => {
              setCurrentButton("program");
            }}
          >
            <ChalkboardTeacher size={22} className={styles.addIcon} />
            <span style={{ paddingBottom: "3px" }}>Programas</span>
          </Button>
          <Button
            className={handleTabClass("company")}
            onClick={() => {
              setCurrentButton("company");
            }}
          >
            <Buildings size={22} className={styles.addIcon} />
            <span style={{ paddingBottom: "3px" }}>Empresas</span>
          </Button>
          <Button
            className={handleTabClass("field")}
            onClick={() => {
              setCurrentButton("field");
            }}
          >
            <Notebook size={22} className={styles.addIcon} />
            <span style={{ paddingBottom: "3px" }}>Área Acadêmica</span>
          </Button>
        </div>
        <Button
          className={styles.tabsButtonNew + " " + "primaryOrange"}
          onClick={() => setIsPopupOpenned(true)}
        >
          <PlusCircle size={22} className={styles.addIcon} />
          <span style={{ paddingBottom: "3px" }}>Novo Item</span>
        </Button>
            <div>
            <TableControl
              resultList={resultList}
              setResultList={setResultList}
              originalListTable={memResultList}
              setTablePagination={setTablePagination}
              setSlice={setSlice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              showByPageDefault={10}
              searchPlaceholder={"Buscar Item"}
              hideBulkActions={true}
              hideFilter={true}
            ></TableControl>
            <Pagination
              tablePagination={tablePagination}
              slice={slice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></Pagination>
            {alerts.map((i, k) => {
              return <Alert message={i.message} type={i.type} />;
            })}
            <table className={styles.table}>
              <thead>
                <tr style={{ backgroundColor: "white" }}>
                  {" "}
                  <th style={{ width: "70vw", textAlign: 'center' }} className={styles.tableTitle}>
                    Título
                    <ArrowsDownUp
                      onClick={() => sortObjByKey("title")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  {(currentButton == "area" || currentButton == "program") && (
                    <th
                      style={{ width: "40vw", textAlign: 'center' }}
                      className={styles.tableTitle}
                    >
                      Hierarquia
                      <ArrowsDownUp
                        onClick={() => sortObjByKey("sk")}
                        className={styles.arrowsDownUp}
                        size={16}
                      />
                    </th>
                  )}
                  <th style={{ width: "20vw" }}>Ações</th>
                </tr>
              </thead>
              <tbody>
                {slice.map((item, i) => {
                  let id = item.id;
                  return (
                    <tr key={i} id={"actions" + i} className={styles.tableRow}>
                      <td className={styles.itemRow + " " + styles.itemTitle}>
                        {item.title}
                      </td>
                      {(currentButton == "area" ||
                        currentButton == "program") && (
                          <td className={styles.itemRow + " " + styles.itemTitle}>
                            {item.sk.replaceAll("#", " → ")}
                          </td>
                        )}
                      <td className={styles.actionsContainer}>
                        <Pencil
                          className={styles.editItemButton}
                          onClick={() => {
                            setIsPopupEditOpenned(true);
                            setCurrentTitle(item.title);
                            let splitId = item.pk.split("#");
                            setCurrentItemId(splitId[2]);
                          }}
                          size={28}
                        />
                        <UserList
                          className={styles.editItemButton}
                          onClick={() => { 
                            setLoadingUsers(true)
                            setIsPopupPermissionOpenned(true);
                            setCurrentTitle(item.title);
                            setOpenneditem(item)

                            // Check if item.accessGranted exists, if not, initialize it as an empty array
                              if (!item.hasOwnProperty('accessGranted')) {
                                item.accessGranted = [];
                            }

 
                            setAccessList(item.accessGranted)
                            let splitId = item.pk.split("#");
                            setCurrentItemId(splitId[2]); 
                            let _usersList = [...usersList]
                            setIndexOfItemInSlice(i)
                            if(item.accessGranted.length > 0) {
                              // Assuming _usersList and item.accessGranted are arrays of objects
                              let updatedUsersList = _usersList.filter(user => {
                                // Check if user ID is not present in any item.accessGranted
                                return !item.accessGranted.some(grantedUser => grantedUser.userId === user.value);
                              });

                              // Now updatedUsersList contains users from _usersList excluding those present in item.accessGranted
                              setFilteredUsersList(updatedUsersList)
                            }else {
                              setFilteredUsersList(_usersList)
                            }
                            
                            

                          }}
                          size={28}
                        />
                        <div className={styles.helpPopupEdit}>Editar Item</div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              tablePagination={tablePagination}
              slice={slice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></Pagination>
          </div>
          </>
          )

        }else{
          return (
            <div className={styles.error}>
              <center>
                <Alert
                  type={"danger"}
                  message={
                    "Você não possui permissão para acessar essa página. Por favor, entre em contato com o administrador do sistema."
                  }
                />
              </center>
            </div>
          );
        }
        break;

  }
  }

  return (
    <Layout>
      <div>
        <div className={styles.pageHeader}>
          <PageTitle style={{marginTop: '-0.5rem'}}center={true}>Cadastro de Listas</PageTitle>
        </div>
        {renderContent()}
      </div>
      {isPopupOpenned == true ? (
        <>
          <div className={styles.newItemPopUp}>
            <div className={styles.header}>Novo Item</div>
            <div className={styles.popupRow}>
              {alertsPopup.map((i, k) => {
                return <Alert message={i.message} type={i.type} />;
              })}
              <span>
                Título<span style={{ color: "red" }}>*</span>
              </span>
              <Field
                key={"newItemIitle"}
                name={"newItemIitle"}
                id={"newItemIitle"}
                value={newItemTitle}
                onChange={(event) => {
                  setNewItemTitle(event.target.value);
                }}
              ></Field>
            </div>
            <div className={styles.popupRow}>
              <span>
                Tipo<span style={{ color: "red" }}>*</span>
              </span>
              <DropdownListField
                defaultValue={currentButton}
                placeholder={"Selecione..."}
                id={"parameters"}
                listOfItems={parametersList}
                style={{ width: "100%" }}
                onChange={(event) => {
                  setCurrentButton(event);
                  setHierarchy("");
                  setNewType(event);
                  setNewItemTitle("");
                }}
              ></DropdownListField>
            </div>
            {(currentButton == "area" || currentButton == "program") && (
              <div className={styles.popupRow}>
                <span>
                  Hierarquia<span style={{ color: "red" }}>*</span>
                </span>
                <DropdownListField
                  placeholder={"Selecione..."}
                  id={"parameters"}
                  defaultValue={hierarchy}
                  value={hierarchy}
                  listOfItems={handleHierarchyList()}
                  style={{ width: "100%" }}
                  onChange={(event) => {
                    setHierarchy(event);
                  }}
                ></DropdownListField>
              </div>
            )}
            <div className={styles.popupFooter}>
              <Button
                className={styles.tabsButtonFooter + " " + "primaryOrange"}
                onClick={() => addNewItem()}
              >
                Adicionar
              </Button>
              <Button
                className={styles.tabsButtonFooter + " " + "secondaryOrange"}
                onClick={() => {
                  setAlertsPopup([]);
                  setIsPopupOpenned(false);
                  setNewItemTitle("");
                  setHierarchy("");
                }}
              >
                Cancelar
              </Button>
            </div>
          </div>
          <div className={styles.overlay} onClick={() => controlPopUp()}></div>
        </>
      ) : (
        <div className={styles.overlayOff}></div>
      )}
      {isPopupEditOpenned == true ? (
        <>
          <div className={styles.newItemPopUp}>
            <div className={styles.header}>Editar Item "{currentTitle}"</div>
            <div className={styles.popupRow}>
              {alertsPopup.map((i, k) => {
                return <Alert message={i.message} type={i.type} />;
              })}
              <span>
                Novo Título<span style={{ color: "red" }}>*</span>
              </span>
              <Field
                key={"newItemIitle"}
                name={"newItemIitle"}
                id={"newItemIitle"}
                value={newItemTitle}
                onChange={(event) => {
                  setNewItemTitle(event.target.value);
                }}
              ></Field>
            </div>
            <div className={styles.popupFooter}>
              <ConfirmationModal
                id={"renameTitle"}
                iconComponent={<Button
                  className={styles.tabsButtonFooter + " " + "primaryOrange"}
                >
                  Salvar
                </Button>}
                title={"Tem certeza que deseja renomear o item ?"}
                onClick={() => setChangingConfirmationModal(true)}
                open={changingConfirmationModal}
                onOk={() => editItemTitle()}
                onCancel={() => setChangingConfirmationModal(false)}
              />

              <Button
                className={styles.tabsButtonFooter + " " + "secondaryOrange"}
                onClick={() => {
                  setAlertsPopup([]);
                  setIsPopupEditOpenned(false);
                  setCurrentTitle("");
                  setCurrentItemId("");
                  setNewItemTitle("");
                }}
              >
                Cancelar
              </Button>
            </div>
          </div>
          <div
            className={styles.overlay}
            onClick={() => controlEditPopUp()}
          ></div>
        </>
      ) : (
        <div className={styles.overlayOff}></div>
      )}

      {isPopupPermissionOpenned == true ? (
        <>
          <div className={styles.permissionPopup}>
            <div className={styles.header}>Usuarios com permissão {currentTitle}</div>
            <div className={styles.popupRow}>
              {alertsPopup.map((i, k) => {
                return <Alert message={i.message} type={i.type} />;
              })}
              <span>
                Adicionar Usuario
              </span> 
              <Select 
                options={filteredUsersList} 
                placeholder="Selecione um usuario" 
                onChange={(event) => setSelectedUser(event)}  
              />
              <Button
                  className={styles.tabsButtonFooter + " " + "primaryOrange"}
                  style={{marginTop:15}}
                  onClick={() => {
                    let grantAccess = grantUserAccess(getParameterType(opennedItem.pk), getParameterId(opennedItem.pk), selectedUser.value)
                    console.log(grantAccess)
                    let accessGrantedList = [...accessList]
                    console.log(accessList)
                    accessGrantedList.push({grantedOn:"Agora", userId:selectedUser.value})
                    setAccessList(accessGrantedList)
                    setFilteredUsersList(removeUsrFromArr(filteredUsersList, accessGrantedList));

                    let _slice = {...slice}
                    _slice[indexOfItemInSlice].accessGranted = accessGrantedList  
                    console.log(selectedUser)
                    let updateAlertList = "";
                    let message = selectedUser.name + " adicionado(a).";
                    updateAlertList = addNewAlert([], { message, type: "success" });
                    setAlertsPopup(updateAlertList);
                    
                    }
                  }
                >
                  Adicionar
              </Button>
              {console.log(opennedItem)}
            </div>
            <div> 
            <div className={styles.accessList}> 
             <div>
              {accessList.map((item, index) => {
                // Find the corresponding user from usersList
                const user = usersList.find(user => user.value === item.userId);
                
                // If user is found, display their name
                const userName = user ? user.name : '...carregando' 
                const userPhoto = user ? user.photo : '...carregando'; 

                console.log(index + " / " + (opennedItem.accessGranted.length - 1))

                if (opennedItem.accessGranted && Array.isArray(opennedItem.accessGranted) && index === (opennedItem.accessGranted.length - 1) && loadingUsers == true) {
                  setLoadingUsers(false);
                  console.log("loaded")
                }
              
              

                return ( 
                    <div key={index} className={styles.userInformation}>
                        <div className={styles.permissionUserPhoto}>
                          <img src={userPhoto} style={{width: 50, borderRadius:50}} /> 
                        </div>
                        <div className={styles.permissionUserData}>
                          <span>{userName}</span><br/>
                          <span className={styles.accessGrantedDate}>Concedido em: {item.grantedOn}</span><br/>
                          <a className={styles.removePermission} onClick={() => {   
                            let accessGrantedList = [...accessList]
                            accessGrantedList.splice(index, 1)
                            setAccessList(accessGrantedList) 
                            console.log(index)
                            console.log(accessGrantedList)
                            removeAccessOfUser(getParameterType(opennedItem.pk), getParameterId(opennedItem.pk), item.userId) 
                            let _slice = {...slice}
                            _slice[indexOfItemInSlice].accessGranted = accessGrantedList  
                            let updateAlertList = "";
                            let message = userName + " removido(a).";
                            updateAlertList = addNewAlert([], { message, type: "danger" });
                            setAlertsPopup(updateAlertList);
                          }} style={{color:"red"}}>Remover</a>
                        </div>
                    </div>
                  
                );
            })}
            </div>
            
            </div>
            
            </div>
            <div className={styles.popupFooter}> 

              <Button
                className={styles.tabsButtonFooter + " " + "secondaryOrange"}
                onClick={() => {
                  setAlertsPopup([]);
                  setIsPopupPermissionOpenned(false);
                  setCurrentTitle("");
                  setCurrentItemId("");
                  setNewItemTitle("");
                }}
              >
                Cancelar
              </Button>
            </div>
          </div>
          <div
            className={styles.overlay}
            onClick={() => controlEditPopUp()}
          ></div>
        </>
      ) : (
        <div className={styles.overlayOff}></div>
      )}
    </Layout>
  );
};

export default GeneralLists;
